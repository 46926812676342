import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "@/data/initAxios";
import type { ReportAgentReason } from "@/types/agent";

type Props = {
  reasons: ReportAgentReason[];
  description: string;
  agentId: string;
};

const reportAgent = async (props: Props) => {
  const { data } = await restClient.post<unknown>(apiPaths.reportAgent({ agentId: props.agentId }), {
    reasons: props.reasons,
    description: props.description,
  });
  return data;
};

export const useReportAgent = () => {
  const mutation = useMutation<unknown, AxiosError<{ message: string }>, Props>({
    mutationFn: reportAgent,
  });

  return mutation;
};
