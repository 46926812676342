import { Icons } from "@/components/ui/icons";
import { ROUTES } from "@/constants/routes";
import { useNavigate } from "react-router";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import WorkspaceWhatsNew from "./components/WorkspaceWhatsNew";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { WorkspaceBaseModels } from "./components/WorkspaceBaseModels";
import { useGetWorkspaceBasicAgents } from "@/data/queries/workspace/useGetWorkspaceBasicAgents";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useState } from "react";
import MultiAgentsChatDialog from "@/components/dialogs/MultiAgentsChatDialog/MultiAgentsChatDialog";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { CustomerSpace } from "@/pages/Workspace/components/CustomerSpace/CustomerSpace";
import { MyAgentsSpace } from "@/pages/Workspace/components/MyAgentsSpace";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";
import { useGetUser } from "@/data/queries/useGetUser";

export const Workspace = () => {
  const navigate = useNavigate();
  const [isMultiAgentsChatDialogOpen, setIsMultiAgentsChatDialogOpen] = useState(false);
  const { data: basicAgents, isPending: isAgentBasicPending } = useGetWorkspaceBasicAgents();
  const { data: user } = useGetUser();

  const isScreenSm = !useTailwindMediaQuery("sm");
  const isDesktop = useTailwindMediaQuery("lg");
  const isCustomerUser = !!user?.customer;
  const handleCreateMultiAgentsChat = ({ agentIds }: { agentIds: string[] }) => {
    navigate(getNewConversationRoute(agentIds));
  };

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel defaultSize={70} minSize={30}>
        <div className="flex h-full flex-1 flex-col text-center">
          <div className="relative mb-6 flex items-center justify-between px-6 pt-4">
            <div className="flex flex-col md:flex-row">
              <h1 className="flex flex-col text-2xl font-bold leading-10 text-primary-black sm:flex-row sm:items-center">
                Workspace
              </h1>
            </div>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                {isScreenSm ? (
                  <IconButton icon={<Icons.Plus />} size="medium" />
                ) : (
                  <Button prefixIcon={<Icons.Plus />}>New</Button>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="flex flex-col gap-1 p-1.5">
                <DropdownMenuItem
                  className="flex items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
                  onClick={() => navigate(ROUTES.createAgent)}
                >
                  <Icons.Bot /> Create Agent
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="flex items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
                  onClick={() => setIsMultiAgentsChatDialogOpen(true)}
                >
                  <Icons.AddGroup /> Multi Agents Chat
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          <WorkspaceBaseModels agents={basicAgents ?? []} isLoading={isAgentBasicPending} />

          <div className="overflow-y-scroll px-6 pb-6">
            <MyAgentsSpace />
            {isCustomerUser && <CustomerSpace />}
          </div>
        </div>

        <MultiAgentsChatDialog
          isOpen={isMultiAgentsChatDialogOpen}
          setIsOpen={setIsMultiAgentsChatDialogOpen}
          onSubmit={agentIds => handleCreateMultiAgentsChat({ agentIds })}
          headerText="Select Agents to Create Group Chat"
        />
      </ResizablePanel>

      {isDesktop && (
        <>
          <ResizableHandle withHandle />
          <ResizablePanel minSize={30}>
            <WorkspaceWhatsNew />
          </ResizablePanel>
        </>
      )}
    </ResizablePanelGroup>
  );
};
