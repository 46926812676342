import { WorkspaceWhatsNewCarousel } from "./WorkspaceWhatsNewCarousel";
import { Link } from "react-router-dom";
import { ROUTES } from "@/constants/routes";

const WorkspaceWhatsNew = () => {
  return (
    <div className="flex h-full flex-col border-l-[1.5px] border-neutral-200 px-6 pt-4">
      <h2 className="mb-4 text-2xl leading-[38px] text-neutral-750">
        What's new on <span className="text-primary-400">AgentX</span>?
      </h2>

      <div className="overflow-y-scroll pb-4">
        <WorkspaceWhatsNewCarousel />

        <h2 className="my-4 text-2xl text-neutral-750">Tutorials</h2>
        <div className="flex flex-col gap-4">
          <p>
            How to deploy and publish an agent to your website?{" "}
            <Link
              className="text-primary-500 hover:underline"
              to="https://www.agentx.so/agentx-tutorial/how-to-deploy-an-ai-agent-to-your-website"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read
            </Link>
          </p>
          <p>
            How to add knowledge to my AI Agent?{" "}
            <Link
              className="text-primary-500 hover:underline"
              to="https://www.agentx.so/agentx-tutorial/how-to-create-a-knowledge-base"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read
            </Link>
          </p>
          <p>
            How to integrate AgentX with Zapier?{" "}
            <Link
              className="text-primary-500 hover:underline"
              to="https://www.agentx.so/agentx-tutorial/zapier-integration-overview"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read
            </Link>
          </p>
          <p>
            How to create a formless Lead Generation agent?{" "}
            <Link
              className="text-primary-500 hover:underline"
              to="https://www.agentx.so/post/how-to-create-a-lead-generation-ai-agent-chatbot-on-agentx---formless-and-straight-to-your-mailbox"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read
            </Link>
          </p>
          <p>
            How to create an AI group chat?{" "}
            <Link
              className="text-primary-500 hover:underline"
              to="https://www.agentx.so/post/how-to-create-a-group-chat-with-gpt-4o-and-claude-3-5-and-more"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read
            </Link>
          </p>
          <p>
            Join the waitlist for our upcoming e-commerce AI release.{" "}
            <Link
              className="text-primary-500 hover:underline"
              to="https://www.agentx.so/e-commerce"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join
            </Link>
          </p>

          <p className="font-bold text-neutral-750">
            Read more tutorials{" "}
            <Link
              className="text-primary-500 hover:underline"
              to="https://www.agentx.so/agentx-tutorials"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </Link>
          </p>
        </div>

        <h2 className="my-4 mt-10 text-2xl text-neutral-750">Need professional helps?</h2>

        <div className="flex flex-col gap-4">
          <p>
            Leave us a message{" "}
            <Link className="text-primary-500 hover:underline" to={ROUTES.help}>
              here
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceWhatsNew;
