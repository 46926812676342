import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { useGetSingleConversationDetails } from "@/data/queries/useGetSingleConversationDetails";
import { useQueries } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { MyAgentDetails } from "../Workspace/MyAgentDetails";
import { Chat } from "@/components/Chat/Chat";
import { cn } from "@/lib/utils";
import BookmarksHistoryPanel from "@/components/BookmarksHistoryPanel/BookmarksHistoryPanel";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { getSingleConversationAgentWithConfig } from "@/data/queries/useGetSingleConversationAgentWithConfig";
import { singlePrivateAgentWithConfigKeys } from "@/data/queries/useGetSinglePrivateAgentWithConfig";
import { useTrackAgentInteractionStats } from "@/hooks/useTrackAgentInteractionStats";
import AnimatedBookmarksHistoryWrapper from "@/components/BookmarksHistoryPanel/components/AnimatedBookmarksHistoryWrapper";

const Conversation = () => {
  const { isMobile } = useMediaQueriesContext();

  const [isAgentDetailsOpen, setIsAgentDetailsOpen] = useState(false);
  const [isMobileBookmarkHistoryPanelOpen, setIsMobileBookmarkHistoryPanelOpen] = useState(false);

  const { conversationId, conversationAgentIds } = useChatContext();
  const { data: conversationDetails, isPending: isPendingConversationDetails } = useGetSingleConversationDetails({
    conversationId: conversationId!,
    enabled: !!conversationId,
  });

  const agentIds = useMemo(() => conversationDetails?.bots.map(bot => bot._id) ?? [], [conversationDetails]);

  const allAgentResults = useQueries({
    queries: agentIds.map(id => ({
      queryKey: singlePrivateAgentWithConfigKeys.id(id),
      queryFn: () => getSingleConversationAgentWithConfig(id),
    })),
  });

  const [activeAgentIndex, setActiveAgentIndex] = useState(0);

  const agents = useMemo(() => allAgentResults.map(r => r.data).filter(Boolean), [allAgentResults]);

  const isLoading = allAgentResults.some(r => r.isPending) || isPendingConversationDetails;
  const isError = allAgentResults.some(r => !!r.error);

  useTrackAgentInteractionStats({ agentIds: conversationAgentIds });

  if (isMobile) {
    return isAgentDetailsOpen ? (
      <MyAgentDetails
        agents={agents}
        isLoading={isLoading}
        isAgentDetailsOpen={isAgentDetailsOpen}
        setIsAgentDetailsOpen={setIsAgentDetailsOpen}
        activeAgentIndex={activeAgentIndex}
        setActiveAgentIndex={setActiveAgentIndex}
      />
    ) : (
      <>
        <Chat
          agents={agents}
          isLoading={isLoading}
          isError={isError}
          setIsAgentDetailsOpen={setIsAgentDetailsOpen}
          onOpenMobileBookmarksHistoryPanel={() => setIsMobileBookmarkHistoryPanelOpen(true)}
          setActiveAgentIndex={setActiveAgentIndex}
        />
        <div
          className={cn("fixed right-0 top-[72px] z-40 h-full w-full", {
            "-right-full": !isMobileBookmarkHistoryPanelOpen,
          })}
        >
          <BookmarksHistoryPanel onClosePanel={() => setIsMobileBookmarkHistoryPanelOpen(false)} />
        </div>
      </>
    );
  }

  return (
    <>
      <MyAgentDetails
        agents={agents}
        isLoading={isLoading}
        activeAgentIndex={activeAgentIndex}
        setActiveAgentIndex={setActiveAgentIndex}
      />
      <Chat agents={agents} isLoading={isLoading} isError={isError} setActiveAgentIndex={setActiveAgentIndex} />
      <AnimatedBookmarksHistoryWrapper>
        <BookmarksHistoryPanel />
      </AnimatedBookmarksHistoryWrapper>
    </>
  );
};

export default Conversation;
