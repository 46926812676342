import { Icons } from "@/components/ui/icons";
import type { EmbedKnowledges } from "@/types/agent";
import { EmbedKnowledgeBox } from "@/components/agentTiles/AgentSearchDetails/components/EmbedKnowledgeBox";

interface AgentSearchDetailsEmbedKnowledgeProps {
  embedKnowledges: {
    web: EmbedKnowledges[];
    doc: EmbedKnowledges[];
    faq: EmbedKnowledges[];
  };
}

const AgentSearchDetailsEmbedKnowledge = ({ embedKnowledges }: AgentSearchDetailsEmbedKnowledgeProps) => {
  return (
    <div className="flex w-full items-stretch gap-2 [&>div]:flex-1">
      <EmbedKnowledgeBox
        knowledgeCount={embedKnowledges.doc.length}
        knowledgeTitle="Document"
        knowledgeIcon={<Icons.File className="size-4 min-w-4 text-neutral-400" />}
      />
      <EmbedKnowledgeBox
        knowledgeCount={embedKnowledges.web.length}
        knowledgeTitle="Weblink"
        knowledgeIcon={<Icons.Link2 className="size-4 min-w-4 text-neutral-400" />}
      />
      <EmbedKnowledgeBox
        knowledgeCount={embedKnowledges.faq.length}
        knowledgeTitle="FAQ"
        knowledgeIcon={<Icons.Message className="size-4 min-w-4 text-neutral-400" />}
      />
    </div>
  );
};

export default AgentSearchDetailsEmbedKnowledge;
