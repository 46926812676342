import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { useCopyNotify } from "@/hooks/useCopyNotify";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { useGetUser } from "@/data/queries/useGetUser";
import InlineChat from "@/assets/images/embedStyles/Inline.svg";
import FloatingChat from "@/assets/images/embedStyles/Floating.svg";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Input } from "@/components/ui/input";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { useState } from "react";

const chatBubbleUrl = import.meta.env.VITE_CHAT_BUBBLE_URL as string;
type EmbedChatType = "floating" | "inline";
type InlineBubbleSize = {
  width: string;
  height: string;
} | null;

export const EmbedScript = ({ onOpenPreview }: { onOpenPreview: () => void }) => {
  const { getValues } = useAgentFormContext();
  const { user } = useGetUser();
  const agentId = getValues("_id");
  const agx = agentId + (getValues("websiteEmbeddingKey") ?? user?.agxKey);

  const [embedChatType, setEmbedChatType] = useState<EmbedChatType>("floating");
  const [inlineBubbleSize, setInlineBubbleSize] = useState<InlineBubbleSize>(null);

  const chatInlineScript = `<div id="chatInlineRoot-${agentId?.slice(18, 24)}"${inlineBubbleSize?.width ? ` width="${inlineBubbleSize.width}"` : ""}${inlineBubbleSize?.height ? ` height="${inlineBubbleSize.height}"` : ""}>
<script defer src="${chatBubbleUrl}?agx=${encodeURIComponent(agx)}"></script>
</div>`;

  const chatBubbleScript = `<script defer>
document.body.appendChild(document.createElement('div')).setAttribute('id','chatBubbleRoot');
window.agx = '${agx}';
</script>
<script defer src="${chatBubbleUrl}"></script>`;

  const embedChatTypes = {
    floating: chatBubbleScript,
    inline: chatInlineScript,
  };

  const currentChatTypeEmbed = embedChatTypes[embedChatType];

  const [, copy] = useCopyNotify();

  const getInputNumberValue = (value: string) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    if (!numericValue && value) {
      return;
    }

    const nonZeroValue = numericValue.replace(/^0+/, "");

    return nonZeroValue;
  };

  return (
    <div className="grid gap-4">
      <div>
        <Label className="text-sm">Embed options</Label>
        <RadioGroup
          value={embedChatType}
          onValueChange={e => {
            const chatType = e as EmbedChatType;
            setEmbedChatType(chatType);
          }}
          defaultValue="floating"
          className="mt-2 flex items-center gap-4"
        >
          <label className="cursor-pointer text-xs font-medium">
            <img className="mb-2" src={FloatingChat} alt="floating chat widget" />
            <div className="flex items-center justify-center gap-2">
              <RadioGroupItem value="floating" /> Floating
            </div>
          </label>
          <label className="cursor-pointer text-xs font-medium">
            <img className="mb-2" src={InlineChat} alt="floating chat widget" />
            <div className="flex items-center justify-center gap-2">
              <RadioGroupItem value="inline" /> Inline
            </div>
          </label>
        </RadioGroup>
      </div>

      <Button className="" variant="secondary" onClick={onOpenPreview}>
        Preview Your Agent
      </Button>

      <div>
        {embedChatType === "inline" && (
          <div className="mb-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <Label htmlFor="inlineBubbleSize">Custom size</Label>
                <HybridTooltipPopover>
                  <p>
                    You can specify the width and height of the chat bubble (in pixels). If set to default, it will take
                    up the full size of the container.
                  </p>
                </HybridTooltipPopover>
              </div>
              <Switch
                id="inlineBubbleSize"
                checked={!!inlineBubbleSize}
                onCheckedChange={checked => {
                  if (checked) {
                    setInlineBubbleSize({ width: "600", height: "600" });
                  } else {
                    setInlineBubbleSize(null);
                  }
                }}
              />
            </div>

            <div className="mt-2 flex justify-between gap-1">
              <>
                <Input
                  label="Width"
                  value={inlineBubbleSize ? inlineBubbleSize.width : "100%"}
                  onChange={e => {
                    const width = getInputNumberValue(e.target.value);
                    if (!width) {
                      return;
                    }

                    setInlineBubbleSize(prev => {
                      if (!prev) {
                        return null;
                      }

                      return { ...prev, width };
                    });
                  }}
                  disabled={!inlineBubbleSize}
                />
                <Input
                  label="Height"
                  value={inlineBubbleSize ? inlineBubbleSize.height : "100%"}
                  onChange={e => {
                    const height = getInputNumberValue(e.target.value);
                    if (!height) {
                      return;
                    }

                    setInlineBubbleSize(prev => {
                      if (!prev) {
                        return null;
                      }

                      return { ...prev, height };
                    });
                  }}
                  disabled={!inlineBubbleSize}
                />
              </>
            </div>
          </div>
        )}

        <Label className="text-sm">Embed Code</Label>

        <p className="my-2 text-xs font-medium text-neutral-500">
          {embedChatType === "floating"
            ? "Copy the following script and paste it at the end of your HTML file within the <body> section"
            : "Copy the following script and paste it inside your selected container."}
        </p>
        <Textarea value={currentChatTypeEmbed} className="h-40 resize-none overflow-hidden bg-white text-xs" />

        <Button className="mt-2 w-full" onClick={() => copy(currentChatTypeEmbed)}>
          Copy
        </Button>
      </div>
    </div>
  );
};
