import * as React from "react";
import TextareaAutosize from "react-textarea-autosize";

import { cn } from "@/lib/utils";
import type { FieldError, UseFormRegisterReturn } from "react-hook-form";
import { isRequiredError } from "@/utils/isRequiredError";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: FieldError | string;
  id?: string;
  register?: UseFormRegisterReturn;
  formValue?: string;
  hideMaxLengthLabel?: boolean;
  containerClassName?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { className, label, error, maxLength, id, register, hideMaxLengthLabel, formValue, containerClassName, ...props },
    ref
  ) => {
    const formRequiredError = isRequiredError(error) && "This field is required";
    const formErrorMessage = typeof error === "object" && "message" in error && error.message?.toString();
    const errorMessage = typeof error === "string" && error;
    const isErrorMessage = formRequiredError || formErrorMessage || errorMessage;
    const errorClassName = error ? "border-red-500 border-2" : "";

    const [internalCurrentValue, setInternalCurrentValue] = React.useState(props.value?.toString() ?? formValue ?? "");

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInternalCurrentValue(e.target.value);
      props?.onChange?.(e);
      void register?.onChange?.(e);
    };

    return (
      <div className={cn("relative flex w-full flex-col gap-1.5", containerClassName)}>
        {label ? (
          <label htmlFor={props.name || id} className="text-sm font-medium text-slate-900 dark:text-slate-50">
            {label}
          </label>
        ) : null}
        <TextareaAutosize
          className={cn(
            `flex min-h-[80px] w-full rounded-md border border-neutral-300 bg-white px-3 py-3 text-sm text-neutral-700 ring-offset-white placeholder:text-neutral-300 focus-visible:border-primary-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary-600 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 sm:px-4`,
            className,
            errorClassName
          )}
          ref={ref}
          id={props.name || id}
          {...props}
          style={{ ...props.style, height: props.style?.height ? Number(props.style.height) : undefined }}
          {...register}
          onChange={handleChange}
          maxLength={maxLength}
          aria-invalid={!!isErrorMessage}
          aria-errormessage={`textarea-error-${props.name || id}`}
        />
        {maxLength && !hideMaxLengthLabel && (
          <div className="absolute bottom-0 right-3 text-xxs font-medium text-neutral-400">
            {internalCurrentValue?.length || 0}/{maxLength}
          </div>
        )}
        {isErrorMessage && (
          <div className="flex items-center gap-1" id={`textarea-error-${props.name || id}`}>
            {/* <Icons.Error /> */}
            <p className="py-1 text-[10px] leading-3 text-red-500">
              {errorMessage || formRequiredError || formErrorMessage}
            </p>
          </div>
        )}
      </div>
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
