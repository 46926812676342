import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogDescription,
  DialogOverlay,
} from "@/components/ui/dialog";
import UnsavedChanges from "@/assets/images/UnsavedChanges.svg";
import { Button } from "@/components/ui/button";

type DeleteGoogleDriveDialogProps = {
  isDialogOpen: boolean;
  setShowDialog: (value: boolean) => void;
  onDisconnect: () => void;
};

export const DeleteGoogleDriveDialog = (props: DeleteGoogleDriveDialogProps) => {
  const onDisconnect = () => {
    props.setShowDialog(false);
    props.onDisconnect();
  };

  return (
    <Dialog open={props.isDialogOpen}>
      <DialogOverlay onClick={() => props.setShowDialog(false)}>
        <DialogContent variant="medium" className="h-[350px]">
          <DialogHeader>Are you sure you want to disconnect Google Drive?</DialogHeader>
          <DialogDescription asChild className="flex flex-col items-center gap-2.5">
            <div>
              <img src={UnsavedChanges} alt="Folder with files" className="w-[120px]" />
              <p className="text-xl font-bold">Disconnect Google Drive</p>
              <p className="text-center text-sm text-neutral-400">
                Disconnecting Google Drive will also remove all the documents you have associated with this agent. Don't
                worry, other agents that use Google Drive will stay intact.
              </p>
            </div>
          </DialogDescription>
          <DialogFooter className="flex gap-4">
            <DialogClose asChild>
              <Button size="medium" variant="tertiary" onClick={() => props.setShowDialog(false)}>
                Cancel
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button size="medium" variant="danger" onClick={onDisconnect}>
                Disconnect
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </DialogOverlay>
    </Dialog>
  );
};
