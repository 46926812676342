import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "@/data/initAxios";
import { workspaceUsersKeys } from "@/data/queries/workspace/useGetWorkspaceUsers";
import { userKeys } from "@/data/queries/useGetUser";
import { workspaceDetailsKeys } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { toast } from "react-toastify";

const toggleWorkspaceAgentSpaceVisibility = async () => {
  const { data } = await restClient.post<unknown>(apiPaths.toggleAgentSpaceVisibility);
  return data;
};

export const useToggleWorkspaceAgentSpaceVisibility = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, AxiosError<{ message: string }>>({
    mutationFn: toggleWorkspaceAgentSpaceVisibility,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: workspaceUsersKeys.all });
      void queryClient.invalidateQueries({ queryKey: userKeys.all });
      void queryClient.invalidateQueries({ queryKey: workspaceDetailsKeys.all });
      toast.success("Agent space visibility in workspace changed successfully");
    },
  });

  return mutation;
};
