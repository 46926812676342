import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import type { Agent } from "@/types/agent";
import StatsTopCard from "../StatsTopCard";
import { useRef, useState } from "react";
import { motion } from "framer-motion";
import type { Origin } from "@/types/origin";
import { StatsSidePanelAgentPicker } from "./components/StatsSidePanelAgentPicker";
import { StatsSidePanelAgentPerformance } from "./components/StatsSidePanelAgentPerformance";
import { StatsSidePanelChannelPerformance } from "./components/StatsSidePanelChannelPerformance";
import { StatsSidePanelFooter } from "./components/StatsSidePanelFooter";

export const StatsSidePanel = ({
  onCloseClick,
  selectedAgent,
  onChangeSelectedAgent,
  messages,
  messagesTrend,
  conversations,
  conversationsTrend,
  satisfaction,
  satisfactionTrend,
  allAgents,
  channels,
}: {
  onCloseClick: () => void;
  selectedAgent: Agent;
  onChangeSelectedAgent: (agent: Agent) => void;
  messages: number;
  messagesTrend: number | null;
  conversations: number;
  conversationsTrend: number | null;
  satisfaction: number | null;
  satisfactionTrend: number | null;
  allAgents: Agent[];
  channels: {
    channel: Origin;
    value: number;
  }[];
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [footerHeight, setFooterHeight] = useState(0);

  return (
    <motion.div
      ref={contentRef}
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      className="fixed right-0 top-0 z-50 h-screen w-full bg-gradient-to-b from-primary-100 from-0% to-white to-[40%] @container/main md:min-w-[600px] md:max-w-[700px]"
    >
      <IconButton
        size="tiny"
        variant="tertiary"
        icon={<Icons.Close className="text-neutral-400" />}
        className="absolute right-3 top-3 z-50"
        onClick={onCloseClick}
      />
      <div
        className="flex h-full flex-col gap-8 px-6 pt-11"
        style={{
          paddingBottom: `${footerHeight}px`,
        }}
      >
        <div className="flex flex-wrap items-center justify-between gap-4">
          <h1 className="whitespace-nowrap text-xl font-bold text-neutral-750 @xl/main:text-2xl">How Agent performs</h1>
          <StatsSidePanelAgentPicker
            agent={selectedAgent}
            allAgents={allAgents}
            onChangeSelectedAgent={onChangeSelectedAgent}
          />
        </div>

        <div className="flex-1 overflow-y-auto pb-6">
          <div className="flex flex-col items-center gap-2 @md/main:flex-row">
            <StatsTopCard
              title="Messages"
              value={messages}
              trend={messagesTrend}
              hideTrendChart
              animatedValueClassName="text-lg md:text-lg"
            />
            <StatsTopCard
              title="Conversations"
              value={conversations}
              trend={conversationsTrend}
              hideTrendChart
              animatedValueClassName="text-lg md:text-lg"
            />
            <StatsTopCard
              title="Satisfaction"
              value={satisfaction ?? 0}
              trend={satisfactionTrend}
              hideTrendChart
              animatedValueClassName="text-lg md:text-lg"
            />
          </div>

          <StatsSidePanelAgentPerformance agent={selectedAgent} />

          <StatsSidePanelChannelPerformance channels={channels} agent={selectedAgent} />
        </div>
      </div>

      <StatsSidePanelFooter setFooterHeight={setFooterHeight} agent={selectedAgent} />
    </motion.div>
  );
};
