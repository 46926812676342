import { Button } from "@/components/ui/button";
import type { UseFormReturn } from "react-hook-form";
import type { RegisterFormTypes } from "@/types/user-register";
import { useState } from "react";
import { useRegisterUser } from "@/data/mutations/useRegisterUser";
import { pickUserAvatar } from "@/utils/pickAvatar";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { RegisterSuccess } from "@/components/RegisterForm/components/RegisterSuccess";
import { Checkbox } from "@/components/ui/checkbox";
import { APP_LINKS } from "@/constants/appLinks";

type RegisterAgeVerificationParentsApprovalProps = {
  form: UseFormReturn<RegisterFormTypes>;
};

export const RegisterAgeVerificationParentsApproval = ({ form }: RegisterAgeVerificationParentsApprovalProps) => {
  const { mutate, isPending, isSuccess } = useRegisterUser();
  const { handleSubmit, reset } = form;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [checkedParentsApproval, setCheckedParentsApproval] = useState(false);

  const submitRegister = async (formData: RegisterFormTypes) => {
    const { email, name, dateOfBirth } = formData;
    const avatar = pickUserAvatar();
    const recaptchaToken = executeRecaptcha ? await executeRecaptcha("register") : undefined;

    mutate(
      { email, name, birth: dateOfBirth, avatar, recaptchaToken },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  };

  if (isSuccess) {
    return <RegisterSuccess />;
  }

  return (
    <div className="flex w-full flex-col gap-6 xl:gap-10">
      <h2 className="text-center text-2xl font-bold leading-9 tracking-tight lg:text-[28px] xl:text-start">
        Parent's Approval Required
      </h2>
      <p className="w-full text-center text-sm font-bold leading-[170%] text-primary-black xl:text-start">
        You are aged 13-18. You must have your parent agree to our terms to use our product.
      </p>

      <div className="flex w-full flex-row gap-4">
        <Checkbox
          id="parentsApproval"
          size="md"
          checked={checkedParentsApproval}
          onCheckedChange={checked => setCheckedParentsApproval(checked)}
        />
        <p className="w-full text-sm font-bold">
          I confirm that I have shared the{" "}
          <a
            href={APP_LINKS.termsOfService}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-400 no-underline"
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            href={APP_LINKS.privacy}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-400 no-underline"
          >
            Privacy Policy
          </a>{" "}
          with my parents and have obtained their consent to sign up for this service.
        </p>
      </div>
      <form onSubmit={handleSubmit(submitRegister)}>
        <Button
          disabled={!checkedParentsApproval}
          variant="primary"
          size="medium"
          className="w-full font-bold"
          type="submit"
          loading={isPending}
        >
          Continue
        </Button>
      </form>
    </div>
  );
};
