import type { AgentPaginationProps, CommunityAgentFilters, StrippedCommunityAgent } from "./../../types/community";
import { useInfiniteQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { useGetUser } from "./useGetUser";

export const communityAgentsKeys = {
  all: ["communityAgents"] as const,
  params: (params: CommunityAgentFilters & Pick<AgentPaginationProps, "limit">) =>
    [...communityAgentsKeys.all, params] as const,
};

type Props = CommunityAgentFilters &
  AgentPaginationProps & {
    isGuestUser?: boolean;
  };

const getCommunityAgents = async (props: Props) => {
  const path = props.isGuestUser ? apiPaths.getCommunityAgentsPublic(props) : apiPaths.getCommunityAgents(props);
  const { data } = await restClient.get<StrippedCommunityAgent[]>(path);
  return data;
};

export const useGetCommunityAgents = (
  props: CommunityAgentFilters & {
    isGuestUser?: boolean;
  }
) => {
  const { isLoading: isLoadingUser } = useGetUser();

  const limit = 30;
  return useInfiniteQuery({
    queryKey: communityAgentsKeys.params({ ...props, limit }),
    queryFn: ({ pageParam }) => getCommunityAgents({ ...props, page: pageParam, limit }),
    getNextPageParam: (lastPage, _, lastPageParam) => (lastPage.length < limit ? undefined : lastPageParam + 1),
    select: data => data.pages.flat(),
    initialPageParam: 1,
    enabled: !isLoadingUser,
  });
};
