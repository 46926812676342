import { Input } from "@/components/ui/input";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { EmbedKnowledges } from "@/types/agent";
import { isValidUrlRecurring, normalizeUrl } from "@/utils/isValidUrl";
import { useState } from "react";
import type { EventFor, F1 } from "@/types/types";
import { Button } from "@/components/ui/button";

type OnlineInfoRecurringLinkProps = {
  appendWeb: F1<EmbedKnowledges | EmbedKnowledges[]>;
  webData: EmbedKnowledges[];
  disabled: boolean;
};

export const OnlineInfoRecurringLink = ({ appendWeb, webData, disabled }: OnlineInfoRecurringLinkProps) => {
  const [isRecurringLinkLoading, setIsRecurringLinkLoading] = useState(false);
  const [newRecurringLink, setNewRecurringLink] = useState("");
  const [depth, setDepth] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  const addRecurringLink = async (e: EventFor<"form", "onSubmit">) => {
    e.preventDefault();
    const urlWithHttps =
      newRecurringLink.includes("https://") || newRecurringLink.includes("http://")
        ? newRecurringLink
        : `https://${newRecurringLink}`;
    const isValid = isValidUrlRecurring(urlWithHttps);
    if (!isValid) {
      return setErrorMessage("Invalid URL");
    }
    if (webData.some(web => normalizeUrl(web.source) === normalizeUrl(urlWithHttps))) {
      return setErrorMessage("This link already exists");
    }

    try {
      setIsRecurringLinkLoading(true);
      const { data } = await restClient.post<string[]>(
        apiPaths.readUrls,
        {
          urls: [urlWithHttps],
          depth,
          rec: true,
        },
        { timeout: 300000 }
      );
      const recurringLinksObjects = data.map((link: string) => ({ source: link })) as EmbedKnowledges[];

      // Filter out the links that are already in webData
      const newLinks = recurringLinksObjects.filter(
        newLink => !webData.some(existingLink => normalizeUrl(existingLink?.source) === normalizeUrl(newLink?.source))
      );
      appendWeb(newLinks);
    } catch (error) {
      console.log(error);
      return setErrorMessage("Invalid recurring link address");
    } finally {
      setIsRecurringLinkLoading(false);
    }

    setNewRecurringLink("");
    setErrorMessage("");
  };

  const onDepthChange = (e: EventFor<"input", "onChange">) => {
    const minNumber = Number(e.target.min);
    const maxNumber = Number(e.target.max);
    const value = Number(e.target.value);
    if (value >= minNumber && value <= maxNumber) {
      setDepth(value);
    }
  };

  return (
    <form onSubmit={addRecurringLink} className="flex flex-col items-end gap-2 lg:flex-row">
      <div className="flex w-full gap-2">
        <Input
          label="Root Link address"
          value={newRecurringLink}
          name="address"
          onChange={e => setNewRecurringLink(e.target.value)}
          error={errorMessage}
          disabled={disabled}
          placeholder="https://www.agentx.so/agentx-blog"
        />
        <Input
          label="Depth"
          containerClassName="w-16"
          inputClassName="pl-4 pr-0"
          value={depth}
          name="depth"
          onChange={onDepthChange}
          type="number"
          min={1}
          max={3}
          disabled={disabled}
        />
      </div>
      <Button
        type="submit"
        disabled={!newRecurringLink.trim().length || disabled}
        loading={isRecurringLinkLoading}
        className="w-full sm:w-fit"
      >
        Add link
      </Button>
    </form>
  );
};
