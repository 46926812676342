import { useQuery } from "@tanstack/react-query";
import type { AgentNotification } from "@/types/notification";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";

export const notificationsKeys = {
  all: ["notifications"] as const,
};

const getNotifications = async () => {
  const { data } = await restClient.get<AgentNotification[]>(apiPaths.getNotifications);
  return data;
};

export const useGetNotifications = () => {
  const query = useQuery({
    queryKey: notificationsKeys.all,
    queryFn: getNotifications,
  });

  return query;
};
