import { useGetUserTopAgentStats } from "@/data/queries/stats/useGetUserTopAgentStats";
import TopAgentsSkeletons from "../loaders/TopAgentsSkeletons";
import { StatsTopAgentSatisfaction } from "./StatsTopAgentSatisfaction";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import { StatsTopAgentDataWithChart } from "./StatsTopAgentMessages";
import { StatsTopAgentSummary } from "./StatsTopAgentSummary";
import StatsDownvotedMessagesDialog from "./StatsDownvotedMessagesDialog";
import { Icons } from "@/components/ui/icons";
import { useState } from "react";
import { LinkButton } from "@/components/ui/link-button";
import { cn } from "@/lib/utils";
import { StatsBlur } from "../common/StatsBlur";
import { StatsUpgradeMessage } from "../common/StatsUpgradeMessage";
import { statsMockTopAgentData } from "../../constants/statsMockData";

const StatsTopAgents = () => {
  const { activeChannelsOrigins, isUpgradeNeeded } = useStatsContext();

  const { currentRange, startDate, endDate } = useStatsContext();

  const { data, isFetching } = useGetUserTopAgentStats({
    currentRange,
    startDate,
    endDate,
    channels: activeChannelsOrigins,
    enabled: !isUpgradeNeeded,
  });

  const dataToShow = isUpgradeNeeded ? statsMockTopAgentData : data;

  const [isDownvotedMessagesDialogOpen, setIsDownvotedMessagesDialogOpen] = useState(false);

  return (
    <>
      <div className="relative items-center gap-2.5 rounded-md border border-border-light bg-white px-5 py-6 shadow-md @container/top-agents">
        {isFetching ? (
          <TopAgentsSkeletons />
        ) : (
          <>
            {isUpgradeNeeded && (
              <StatsBlur className="rounded-md px-5 py-6">
                <h2 className="text-2xl font-bold">Top Agents</h2>
                <StatsUpgradeMessage suffixText="to see who's your top agent" />
              </StatsBlur>
            )}

            <h2
              className={cn("text-2xl font-bold", {
                "opacity-0": isUpgradeNeeded,
              })}
            >
              Top Agents
            </h2>

            <div className="relative mt-6 grid grid-cols-1 gap-2.5 @xl/top-agents:grid-cols-3 @xl/top-agents:gap-y-0 @5xl/top-agents:flex @5xl/top-agents:items-center @5xl/top-agents:justify-between">
              <StatsTopAgentSummary
                agent={dataToShow?.agent}
                messagesTrend={dataToShow?.messages.trend}
                currentRange={currentRange}
              />
              <div className="w-full self-start @xl/top-agents:col-start-2 @3xl/top-agents:max-w-52 @5xl:self-center">
                <StatsTopAgentDataWithChart
                  title="Messages"
                  value={dataToShow?.messages.total}
                  trend={dataToShow?.messages.trend}
                />
              </div>
              <div className="w-full self-end @xl/top-agents:col-start-2 @3xl/top-agents:max-w-52 @5xl:self-center">
                <StatsTopAgentDataWithChart
                  title="Views"
                  value={dataToShow?.views.total}
                  trend={dataToShow?.views.trend}
                />
              </div>

              <div className="relative flex w-full flex-col px-4 @xl/top-agents:col-start-3 @xl/top-agents:row-start-1 @xl/top-agents:max-w-52 @xl/top-agents:justify-between @xl/top-agents:px-0 @6xl/top-agents:flex-row">
                <StatsTopAgentSatisfaction satisfaction={dataToShow?.satisfaction ?? null} />

                <LinkButton
                  size="md"
                  className="hidden items-center justify-start text-sm text-primary-400 @5xl/top-agents:absolute @5xl/top-agents:left-0 @5xl/top-agents:top-full @5xl/top-agents:mt-1 @5xl/top-agents:flex @5xl/top-agents:pl-0 @7xl/top-agents:hidden"
                  onClick={() => setIsDownvotedMessagesDialogOpen(true)}
                  disabled={!dataToShow?.agent?._id}
                >
                  See downvoted chats
                  <Icons.ChevronRight className="w-6" />
                </LinkButton>
              </div>

              <LinkButton
                size="md"
                className="mt-auto flex items-center justify-start pl-4 pt-0 text-sm text-primary-400 @xl/top-agents:col-start-3 @xl/top-agents:mt-0 @xl/top-agents:self-end @xl/top-agents:px-0 @xl/top-agents:pt-2 @5xl/top-agents:hidden @7xl/top-agents:flex"
                onClick={() => setIsDownvotedMessagesDialogOpen(true)}
                disabled={!dataToShow?.agent?._id}
              >
                See downvoted chats
                <Icons.ChevronRight className="w-6" />
              </LinkButton>
            </div>
          </>
        )}
      </div>

      <StatsDownvotedMessagesDialog
        isOpen={isDownvotedMessagesDialogOpen}
        setIsOpen={setIsDownvotedMessagesDialogOpen}
        agentId={dataToShow?.agent?._id ?? ""}
        currentRange={currentRange}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
};

export default StatsTopAgents;
