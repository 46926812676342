import { Icons } from "@/components/ui/icons";
import type { EmbedKnowledges } from "@/types/agent";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { sortWebByDomainsToSeparateArrays } from "@/utils/getDomains";
import { IconButton } from "@/components/ui/icon-button";
import { Button } from "@/components/ui/button";
import { useSetConversationQueryKnowledge } from "@/components/Chat/hooks/useSetConversationQueryKnowledge";
import { AgentDetailsEmbedKnowledgeList } from "@/components/agentTiles/AgentDetails/components/AgentDetailsEmbedKnowledgeList";
import { Label } from "@/components/ui/label";

type AgentSearchDetailsEmbedKnowledgeListProps = {
  embedKnowledges: {
    web: EmbedKnowledges[];
    doc: EmbedKnowledges[];
    faq: EmbedKnowledges[];
  };
  selectedChatKnowledge: string[];
};

export const AgentSearchDetailsEmbedKnowledgeList = ({
  embedKnowledges,
  selectedChatKnowledge,
}: AgentSearchDetailsEmbedKnowledgeListProps) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { setKnowledge } = useSetConversationQueryKnowledge();

  const mergedKnowledges = [...embedKnowledges.doc, ...embedKnowledges.web, ...embedKnowledges.faq];
  const knowledgesCount = mergedKnowledges.length;

  const sortedWebData = embedKnowledges.web.length ? sortWebByDomainsToSeparateArrays(embedKnowledges.web) : [];

  const handleSimplePinChange = (checked: boolean, idOrIds: string | string[]) => {
    setKnowledge(knowledgeArray => {
      const ids = Array.isArray(idOrIds) ? idOrIds : [idOrIds];
      if (checked) {
        ids.forEach(id => {
          if (!knowledgeArray.includes(id)) {
            knowledgeArray.push(id);
          }
        });
      } else {
        knowledgeArray = knowledgeArray.filter(id => !ids.includes(id));
      }

      return knowledgeArray.length === mergedKnowledges.length ? [] : knowledgeArray;
    });
  };

  const handleClearPins = () => {
    setKnowledge([]);
  };

  const filteredEmbedKnowledges = {
    web: embedKnowledges.web.filter(knowledge => knowledge.source.toLowerCase().includes(searchQuery.toLowerCase())),
    doc: embedKnowledges.doc.filter(
      knowledge =>
        knowledge.filename.toLowerCase().includes(searchQuery.toLowerCase()) ||
        knowledge.description.toLowerCase().includes(searchQuery.toLowerCase())
    ),
    faq: embedKnowledges.faq.filter(
      knowledge =>
        knowledge.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
        knowledge.description.toLowerCase().includes(searchQuery.toLowerCase())
    ),
  };
  const filteredEmbedKnowledgesCount =
    filteredEmbedKnowledges.web.length + filteredEmbedKnowledges.doc.length + filteredEmbedKnowledges.faq.length;

  const dialogEmbedKnowledges = searchQuery ? filteredEmbedKnowledges : embedKnowledges;
  const dialogSortedWebData = searchQuery
    ? sortWebByDomainsToSeparateArrays(filteredEmbedKnowledges.web)
    : sortedWebData;

  return (
    <div className="flex size-full flex-col gap-1 text-sm font-medium">
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-1">
          <Label className="font-bold">Knowledge</Label>
          <div className="font-medium text-neutral-400">
            (pinned {selectedChatKnowledge.length}/{knowledgesCount})
          </div>
        </div>
        <Button variant="ghost" onClick={handleClearPins} className="mr-0.5 px-0 text-neutral-200">
          Clear
        </Button>
      </div>
      <div className="relative mx-1 my-4">
        <Input
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search knowledge"
          inputClassName="pl-9 sm:pl-9 placeholder:text-slate-400 text-sm lg:text-sm"
        />
        <Icons.Search className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-400" />
        {!!searchQuery && (
          <IconButton
            variant="tertiary"
            size="tiny"
            icon={<Icons.Close />}
            className="absolute right-3 top-1/2 -translate-y-1/2"
            onClick={() => setSearchQuery("")}
          />
        )}
      </div>
      <div className="size-full max-h-[min(600px,100dvh-200px)] overflow-y-auto">
        <AgentDetailsEmbedKnowledgeList
          selectedChatKnowledge={selectedChatKnowledge}
          embedKnowledges={dialogEmbedKnowledges}
          sortedWebData={dialogSortedWebData}
          handleSimplePinChange={handleSimplePinChange}
        />
      </div>
      {!filteredEmbedKnowledgesCount && <p>No results for this query</p>}
    </div>
  );
};
