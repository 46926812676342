import type { LlmList } from "@/types/config";
import { useGetAppConfig } from "@/data/queries/useGetAppConfig";
import type { EmbedKnowledges } from "@/types/agent";
import type { ChatAgent } from "@/types/conversation";
import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";
import { Icons } from "@/components/ui/icons";
import { IconButton } from "@/components/ui/icon-button";
import AgentSearchDetailsEmbedKnowledge from "@/components/agentTiles/AgentSearchDetails/components/AgentSearchDetailsEmbedKnowledge";
import { AgentSearchDetailsEmbedKnowledgeList } from "@/components/agentTiles/AgentSearchDetails/components/AgentSearchDetailsEmbedKnowledgeList";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { AgentInfo } from "@/components/agentTiles/AgentSearchDetails/components/AgentInfo";

type AgentSearchDetailsWrapperProps = {
  agent: ChatAgent;
  agents: ChatAgent[];
  isAgentDetailsOpen?: boolean;
  setIsAgentDetailsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isMyAgent: boolean | undefined;
  activeAgentIndex: number;
};

const AgentSearchDetailsWrapper = ({ agent, isMyAgent, activeAgentIndex, agents }: AgentSearchDetailsWrapperProps) => {
  const navigate = useNavigate();
  const { data: config } = useGetAppConfig();

  const getModelData = (model: LlmList["model"]) => {
    const modelConfig = config?.llmList.find(llm => llm.model === model);
    return modelConfig;
  };

  const { conversationDetails } = useChatContext();

  const isNewConversation = !conversationDetails;
  const selectedChatKnowledge = isNewConversation ? [] : conversationDetails.pins;

  const showEmbedKnowledge =
    isMyAgent &&
    (agent.embedKnowledges.web.length > 0 ||
      agent.embedKnowledges.doc.length > 0 ||
      agent.embedKnowledges.faq.length > 0);

  const onEditAgentClick = () => {
    const agentId = agents[activeAgentIndex]?._id;

    if (!agentId) {
      return;
    }
    navigate(ROUTES.editAgent(agentId));
  };

  const filteredEmbedKnowledges = (embedKnowledges: ChatAgent["embedKnowledges"]) => {
    return {
      web: embedKnowledges.web,
      doc: embedKnowledges.doc.filter((item): item is EmbedKnowledges => !("isNewFolder" in item)) as EmbedKnowledges[],
      faq: embedKnowledges.faq,
    };
  };

  return (
    <div className="flex size-full h-full flex-col items-center gap-4">
      <div className="flex size-full h-full flex-col items-center gap-4 rounded-2xl bg-neutral-50 p-4">
        <AgentInfo agent={agent} isMyAgent={isMyAgent} />
        {isMyAgent && (
          <IconButton
            onClick={onEditAgentClick}
            icon={<Icons.Edit />}
            size="medium"
            variant="tertiary"
            className="fixed right-3 top-3"
          >
            <span className="sr-only">edit this agent</span>
          </IconButton>
        )}
        <div className="flex w-full items-center gap-2 pt-1">
          <img src={getModelData(agent.llmModel)?.logo} className="size-10 rounded-md" />
          <p className="text-sm font-medium">{getModelData(agent.llmModel)?.display}</p>
        </div>
        {showEmbedKnowledge && (
          <AgentSearchDetailsEmbedKnowledge embedKnowledges={filteredEmbedKnowledges(agent.embedKnowledges)} />
        )}
      </div>

      {showEmbedKnowledge && (
        <AgentSearchDetailsEmbedKnowledgeList
          embedKnowledges={filteredEmbedKnowledges(agent.embedKnowledges)}
          selectedChatKnowledge={selectedChatKnowledge}
        />
      )}
    </div>
  );
};

export default AgentSearchDetailsWrapper;
