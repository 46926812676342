import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { AdvancedCustomizationContent } from "./AdvancedCustomizationContent";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { domElementIds } from "@/types/dom-element-ids";

export const AdvancedCustomization = () => {
  return (
    <Accordion
      id={domElementIds.ADVANCED_TOOLS}
      type="single"
      collapsible
      className="w-full border-b border-neutral-100"
    >
      <AccordionItem value="outer">
        <AccordionTrigger>
          <div className="flex gap-1 pb-2">
            <h4>Advanced Tool</h4>
            <HybridTooltipPopover heading="Customize Your Agent's Tools:">
              <p>
                Advanced Tool is a module designed to provide programmatical control over your agent's behavior. It
                allows you to add parameters and coding logic to your agent. With Advanced Tool, you can create
                customized functions, automate repetitive tasks, connect with third-party API, and streamline your
                agents' workflow.
              </p>
            </HybridTooltipPopover>
          </div>
        </AccordionTrigger>
        <p className="pb-2 text-xs font-medium text-neutral-400">
          Create customized tools on your own by writing code.{" "}
        </p>
        <AccordionContent className="flex flex-1 flex-col">
          <AdvancedCustomizationContent />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
