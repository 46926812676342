import type { PropsWithChildren } from "react";
import { useEffect } from "react";

type PopupControllerProps = {
  popupUrl: string;
  onSuccess: () => void;
  className?: string;
};

// This component will open a popup when clicked and listen for a message event from the popup
// The popup should send a message with the `popupSuccessful` key when the action is successful

const PopupController = (props: PropsWithChildren<PopupControllerProps>) => {
  const openPopup = () => {
    const popup = window.open(props.popupUrl, "PopupController", "width=500,height=600");

    // Polling to check if the popup has closed
    const popupChecker = setInterval(() => {
      if (popup!.closed) {
        clearInterval(popupChecker);
      }
    }, 500);
  };

  // Listen for the message event from the popup
  useEffect(() => {
    const handleAuthMessage = (event: MessageEvent) => {
      try {
        if (typeof event.data === "object" && event.data !== null && "popupSuccessful" in event.data) {
          props.onSuccess();
        }
      } catch (error) {
        console.error("Error processing message event:", error);
      }
    };

    window.addEventListener("message", handleAuthMessage);

    return () => {
      window.removeEventListener("message", handleAuthMessage);
    };
  }, [props]);

  return (
    <div className={props.className} onClick={openPopup}>
      {props.children}
    </div>
  );
};

export default PopupController;
