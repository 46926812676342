import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { Label } from "@/components/ui/label";
import { RadioGroup } from "@/components/ui/radio-group";
import { StyleIconSize, type FullAgent } from "@/types/agent";
import { Controller, type UseFormReturn } from "react-hook-form";
import { BubbleDisplayAvatarVariant } from "./BubbleDisplayAvatarVariant";

type BubbleDisplayProps = {
  form: UseFormReturn<FullAgent["publish"]>;
};

export const BubbleDisplay = ({ form }: BubbleDisplayProps) => {
  const { control } = form;

  const agentForm = useAgentFormContext();
  const { watch } = agentForm;
  const avatar = watch("avatar");

  return (
    <div>
      <Label className="mb-2 text-neutral-400">Bubble Display</Label>
      <Controller
        control={control}
        name="chatStyle.iconSize"
        render={({ field: { value, onChange } }) => (
          <div className="flex justify-center bg-neutral-50">
            <RadioGroup
              onValueChange={onChange}
              defaultValue={value}
              className="grid w-full max-w-max grid-cols-3 justify-center gap-4 rounded-md px-6 py-4"
            >
              <BubbleDisplayAvatarVariant
                avatar={avatar}
                size={StyleIconSize.Small}
                isSelected={value === StyleIconSize.Small}
              />
              <BubbleDisplayAvatarVariant
                avatar={avatar}
                size={StyleIconSize.Medium}
                isSelected={value === StyleIconSize.Medium}
              />
              <BubbleDisplayAvatarVariant
                avatar={avatar}
                size={StyleIconSize.Large}
                isSelected={value === StyleIconSize.Large}
              />
            </RadioGroup>
          </div>
        )}
      />
    </div>
  );
};
