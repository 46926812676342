import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { Textarea } from "@/components/ui/textarea";
import { useReportAgent } from "@/data/mutations/useReportAgent";
import { cn } from "@/lib/utils";
import { ReportAgentReasons, type Agent, type ReportAgentReason } from "@/types/agent";
import { useState, type ReactElement } from "react";

type Props = {
  agentId: Agent["_id"];
  renderButton: () => ReactElement;
};

export const ReportAgentDialog = ({ agentId, renderButton }: Props) => {
  const { mutate: mutateReportAgent, isPending, isSuccess } = useReportAgent();

  const [selectedReasons, setSelectedReasons] = useState<ReportAgentReason[]>([]);
  const [otherReason, setOtherReason] = useState("");

  const getIsReasonSelected = (reason: ReportAgentReason) => selectedReasons.includes(reason);

  const toggleSelectReason = (reason: ReportAgentReason) => {
    setSelectedReasons(prevSelectedReasons => {
      if (prevSelectedReasons.includes(reason)) {
        return prevSelectedReasons.filter(selectedReason => selectedReason !== reason);
      }
      return [...prevSelectedReasons, reason];
    });
  };

  const handleReport = () => {
    mutateReportAgent({
      agentId,
      reasons: selectedReasons,
      description: getIsReasonSelected(ReportAgentReasons.OTHER) ? otherReason : "",
    });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>{renderButton()}</DialogTrigger>
      <DialogContent className="h-fit" variant="medium">
        <DialogHeader></DialogHeader>
        <div className="px-8 py-4">
          {isSuccess ? (
            <div className="flex items-center gap-2.5 py-1">
              <Icons.Check />
              <h1 className="text-sm font-medium leading-5 text-[#384B5D]">
                You feedback was received. We will follow up on your feedback.
              </h1>
            </div>
          ) : (
            <>
              <div className="flex items-center gap-2.5 py-1">
                <Icons.Alert className="text-error" />
                <h1 className="text-base font-bold leading-5 text-neutral-600">Report Agent</h1>
              </div>
              <div className="mt-1.5 flex flex-wrap gap-1.5 py-1">
                {Object.entries(ReportAgentReasons).map(([key, label]) => {
                  const isSelected = getIsReasonSelected(label);

                  return (
                    <Button
                      key={key}
                      onClick={() => toggleSelectReason(label)}
                      variant="secondary"
                      size="tiny"
                      className={cn("px-2 py-0.5", isSelected && "bg-primary-200")}
                    >
                      {label}
                    </Button>
                  );
                })}
              </div>

              {getIsReasonSelected(ReportAgentReasons.OTHER) && (
                <div className="mt-2.5">
                  <Textarea
                    id="inMemoryKnowledge.instruction"
                    maxLength={3000}
                    value={otherReason}
                    onChange={e => setOtherReason(e.target.value)}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <DialogFooter className="p-4">
          {!isSuccess && (
            <Button variant="primary" onClick={handleReport} loading={isPending} disabled={!selectedReasons.length}>
              Report
            </Button>
          )}
          <DialogClose asChild>
            <Button variant="tertiary">{isSuccess ? "Close" : "Cancel"}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
