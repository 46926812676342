import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { GoogleCalendarTool } from "@/types/tools";
import type { Agent } from "@/types/agent";
import { agentGoogleCalendarKeys } from "../queries/useGetAgentGoogleCalendarTool";

type Props = {
  agentId: Agent["_id"];
  calendarId: string;
  calendarEmail: string;
};

const createGoogleCalendarTool = async (props: Props) => {
  const { data } = await restClient.post<GoogleCalendarTool>(apiPaths.createGoogleCalendarTool, props);
  return data;
};

export const useCreateGoogleCalendarTool = () => {
  const queryClient = useQueryClient();

  return useMutation<GoogleCalendarTool, AxiosError<{ message: string }>, Props>({
    mutationFn: createGoogleCalendarTool,
    onSuccess: (data, variables) => {
      const { agentId } = variables;
      queryClient.setQueryData<GoogleCalendarTool>(agentGoogleCalendarKeys.id(agentId), data);
    },
  });
};
