export type AgentNotification = {
  createdAt: Date;
  date: Date;
  htmlContent: null;
  read: boolean;
  referenceId: string;
  referenceType: number;
  state: string;
  subtitle: string;
  title: string;
  updatedAt: Date;
  url: null | string;
  entityId: string | null;
  entityName: string | null;
  imgSrc: string | null;
  approveAction: {
    url: string | null;
    text: string | null;
  };
  declineAction: {
    url: string | null;
    text: string | null;
  };
  user: string;
  __v: number;
  _id: string;
};

export enum NotificationReference {
  SYSTEM = 0,
  AGENT = 1,
  USER = 2,
  SUBSCRIPTION = 3,
  RELATION = 4,
}
