import type { Origin } from "@/types/origin";
import { CHANNELS_LEGEND_DATA } from "../constants/statsConstants";

export const getStatsAllowedChannels = ({
  providedChannels,
}: {
  providedChannels: { channel: Origin; value: number }[];
}) => {
  const allowedChannels = CHANNELS_LEGEND_DATA.map(item => item.channel);

  const filteredChannels = providedChannels.filter(channel => allowedChannels.includes(channel.channel));

  return filteredChannels;
};
