import { Accordion, AccordionItem } from "@/components/ui/accordion";
import { Controller } from "react-hook-form";
import { Icons } from "@/components/ui/icons";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import type { ExternalIntegrations } from "@/types/user";
import { GoogleCalendarDropdown } from "@/components/ExternalIntegrations/CalendarDropdown";

type GoogleCalendarConnectProps = {
  refetchUser: () => void;
  isEnabled: boolean;
  calendarIntegration: ExternalIntegrations["google_calendar"];
};

export const GoogleCalendarConnect = (props: GoogleCalendarConnectProps) => {
  const agentForm = useAgentFormContext();
  const agentId = agentForm.getValues("_id");

  return (
    <div className="mt-1 rounded-md border border-neutral-300 bg-neutral-50 p-4">
      <Accordion type="single" collapsible value={props.isEnabled ? "enabled" : ""} className="w-full">
        <AccordionItem value="enabled">
          <div className="flex justify-between">
            <div className="flex w-full items-center gap-2">
              <Icons.GoogleCalendar className="min-w-6 text-primary-400" />
              <p className="text-xs font-medium leading-5">Google Calendar</p>
              <Controller
                control={agentForm.control}
                name="googleCalendar.calendar"
                render={({ field: { value, onChange } }) => (
                  <GoogleCalendarDropdown
                    calendarIntegrations={props.calendarIntegration}
                    currentCalendar={value}
                    changeCalendar={onChange}
                    isCalendarEnabled={props.isEnabled}
                    agentId={agentId}
                    refetchUser={props.refetchUser}
                  />
                )}
              />
            </div>
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
