import type { ReactElement } from "react";

type EmbedKnowledgeBoxProps = {
  knowledgeCount: number;
  knowledgeTitle: string;
  knowledgeIcon: ReactElement;
};

export const EmbedKnowledgeBox = ({ knowledgeCount, knowledgeTitle, knowledgeIcon }: EmbedKnowledgeBoxProps) => {
  return (
    <div className="col-span-3 rounded-md bg-white px-2 py-1">
      <p className="text-center text-sm font-bold leading-6 text-neutral-750">{knowledgeCount}</p>
      <div className="flex items-center justify-center gap-2">
        {knowledgeIcon}
        <p className="text-xs font-medium text-neutral-400">{knowledgeTitle}</p>
      </div>
    </div>
  );
};
