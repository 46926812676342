import { z } from "zod";

const dateOfBirthSchema = z
  .date()
  .nullable()
  .superRefine((date, ctx) => {
    if (!date) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Date of birth is required",
      });
      return false;
    }

    return true;
  });

export const RegisterFormValidationSchema = ({ isSso }: { isSso: boolean }) =>
  z.object({
    dateOfBirth: dateOfBirthSchema,
    ...(!isSso && {
      name: z.string().min(2, "Name must be at least 2 characters"),
      email: z.string().email("Please use a valid email address"),
    }),
  });
