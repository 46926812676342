import { FakeSelectItem, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import type { ExternalIntegrations } from "@/types/user";
import PopupController from "./PopupController";
import { Button } from "../ui/button";
import {
  allGoogleCalendars,
  getAllGoogleCalendars,
} from "@/data/queries/externalIntegrations/useGetAllGoogleCalendars";
import { useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";

type Props = {
  isCalendarEnabled: boolean;
  currentCalendar: { calendarId: string; email: string };
  changeCalendar: (calendar: { calendarId: string; email: string }) => void;
  calendarIntegrations: ExternalIntegrations["google_calendar"];
  agentId: string;
  refetchUser: () => void;
};

const googleCalendarLoginUrl = import.meta.env.VITE_GOOGLE_CALENDAR_LOGIN_URL as string;

export const GoogleCalendarDropdown = (props: Props) => {
  const userToken = localStorage.getItem("token");

  const [calendars, setCalendars] = useState<{ id: string; summary: string }[]>([]);

  const queryClient = useQueryClient();

  const fetchCalendar = (newEmail = "") => {
    const refetch = newEmail !== "";
    const email = newEmail || props.currentCalendar.email;
    // Don't fetch calendars if there are already some
    if (calendars.length > 1 && props.isCalendarEnabled && !refetch) {
      return;
    }
    if (email === "") {
      setCalendars([{ id: "disabled-calendar", summary: "Select account" }]);
      return;
    }
    if (!props.isCalendarEnabled) {
      setCalendars([{ id: "disabled-calendar", summary: "Select calendar" }]);
    } else {
      queryClient
        .fetchQuery({
          queryKey: allGoogleCalendars.email(email),
          queryFn: () => getAllGoogleCalendars({ email }),
        })
        .then(data => {
          setCalendars(data);
          // If no calendar is set, use the first one
          if (props.currentCalendar.calendarId === "") {
            props.changeCalendar({ calendarId: data[0].id, email });
          }
        })
        .catch(() => {
          setCalendars([{ id: "disabled-calendar", summary: "Failed to fetch calendars" }]);
        });
    }
  };

  useEffect(fetchCalendar, [props.isCalendarEnabled, props.currentCalendar, props, calendars.length, queryClient]);

  const onCalendarAccountChange = (email: string) => {
    const calendar = props.calendarIntegrations!.find(calendar => calendar.email === email);
    if (calendar) {
      props.changeCalendar({ calendarId: calendar.calendarId, email: calendar.email });
      fetchCalendar(email);
    }
  };

  const onCalendarChange = (id: string) => {
    props.changeCalendar({ calendarId: id, email: props.currentCalendar.email });
  };

  // Return
  if (!props.isCalendarEnabled) {
    return <FakeSelectItem>Google Calendar integration is not enabled</FakeSelectItem>;
  }

  return (
    <div className="flex flex-1 justify-between">
      <Select onValueChange={onCalendarAccountChange} value={props.currentCalendar.email}>
        <SelectTrigger id="accountSelect" className="h-8 w-[auto]">
          <SelectValue placeholder="Select account"></SelectValue>
        </SelectTrigger>
        <SelectContent>
          {(props.calendarIntegrations ?? []).map(calendar => (
            <SelectItem key={calendar.email} value={calendar.email} className="justify-start">
              <div className="flex items-center justify-between gap-4 text-xs">{calendar.email}</div>
            </SelectItem>
          ))}
          <FakeSelectItem>
            <PopupController
              className="m-2"
              popupUrl={googleCalendarLoginUrl + "?token=" + userToken}
              onSuccess={props.refetchUser}
            >
              <Button
                variant="tertiary"
                size="small"
                className="border-primary-400 bg-transparent py-1 text-primary-400"
              >
                + Connect a new Google Calendar account
              </Button>
            </PopupController>
          </FakeSelectItem>
        </SelectContent>
      </Select>
      <Select onValueChange={onCalendarChange} value={props.currentCalendar.calendarId}>
        <SelectTrigger id="calendarSelect" className="h-8 w-[auto]">
          <SelectValue placeholder="Select calendar"></SelectValue>
        </SelectTrigger>
        <SelectContent>
          {calendars.map(calendar => (
            <SelectItem key={calendar.id} value={calendar.id} className="justify-start">
              <div className="flex items-center justify-between gap-4 text-xs">{calendar.summary}</div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
