import { Icons } from "@/components/ui/icons";
import { getReferenceTitleSource } from "@/utils/getReferenceTitleSource";
import { FileIcon } from "../../AgentForm/components/Knowledge/components/FileIcon";

import type { Reference, AgentFunction } from "@/types/trace";
import type { TitleAndSource } from "@/utils/getReferenceTitleSource";
import type { EmbedKnowledges } from "@/types/agent";

type ReferencesProps = {
  references: Reference[];
  agentFunction: AgentFunction | null;
  setDocumentInPreview?: React.Dispatch<React.SetStateAction<EmbedKnowledges | null>>;
  setDocPreviewPage?: React.Dispatch<React.SetStateAction<number | null>>;
};

const convertReferenceToEmbeddedKnowledge = ({
  filename,
  source,
  title,
  content,
}: TitleAndSource): EmbedKnowledges => ({
  _id: "",
  creator: "",
  embedIdList: [""],
  knowledgeType: "DOC",
  source: source!,
  filename: filename!,
  description: title || "",
  content: content || "",
  parentId: "",
  status: "success",
  createdAt: "",
  updatedAt: "",
});

const excludeDisplayFunctionName = ["image_generate", "image_generate_flux", "AgentX_Lead_Generation_Tool"];

const convertAgentToolToEmbeddedKnowledge = ({ name, response, args }: AgentFunction): EmbedKnowledges => ({
  _id: "",
  creator: "",
  embedIdList: [""],
  knowledgeType: "DOC",
  source: "agentFunction",
  filename: name,
  description: args || "",
  content: response || "",
  parentId: "",
  status: "success",
  createdAt: "",
  updatedAt: "",
});

const checkForPossibleDocPreview = (filename?: string): boolean => {
  if (!filename) {
    return false;
  }

  const fileFormat = filename.slice(filename.lastIndexOf("."))?.toLowerCase();
  const allowedFileFormats = [
    ".pdf",
    ".xls",
    ".xlsx",
    ".odf",
    ".ods",
    ".odt",
    ".csv",
    ".png",
    ".ppt",
    ".pptx",
    ".tiff",
    ".txt",
    ".jpg",
    ".jpeg",
    ".webp",
    ".gif",
    ".bmp",
    ".html",
    ".htm",
    ".doc",
    ".docx",
  ];
  return allowedFileFormats.includes(fileFormat);
};

export const References = ({ references, agentFunction, setDocumentInPreview, setDocPreviewPage }: ReferencesProps) => {
  if (!references.length && !agentFunction) {
    return null;
  }

  const titleSourceReferences = getReferenceTitleSource(references);
  const filteredReferences = titleSourceReferences.filter(reference => reference.title !== "FAQ" && reference.title);

  if (!filteredReferences.length && !agentFunction) {
    return null;
  }

  if (
    !filteredReferences.length &&
    agentFunction &&
    (excludeDisplayFunctionName.includes(agentFunction.name) || !agentFunction.name)
  ) {
    return null;
  }

  return (
    <ol className="my-1 ml-[50px] flex w-[90%] list-none flex-col rounded-[4px] bg-references p-4 font-medium shadow-lg">
      <div className="flex pl-1 text-xs uppercase text-primary-black">References:</div>
      {filteredReferences.map(reference => (
        <li
          key={`${reference?.content} ${reference?.filename} ${reference?.source}`}
          className="mt-[-12px] cursor-pointer pt-6"
        >
          {reference?.source && setDocumentInPreview && checkForPossibleDocPreview(reference?.filename) ? (
            <button
              onClick={() => {
                setDocumentInPreview(convertReferenceToEmbeddedKnowledge(reference));
                setDocPreviewPage && setDocPreviewPage(reference.page || null);
              }}
              className="flex cursor-pointer items-center gap-[6px] text-start no-underline decoration-1 hover:underline"
            >
              <div>
                {reference.filename ? (
                  <FileIcon fileName={reference.filename} className="h-[21px] w-[24px]" />
                ) : (
                  <Icons.Link className="h-[21px] w-[24px]" />
                )}
              </div>
              <p className="text-xs font-semibold leading-4 text-references-text">
                {reference.filename || reference.title}
              </p>
            </button>
          ) : (
            <a
              href={reference.source ? addPrefixToUrl(reference.source || "") : undefined}
              target="_blank"
              rel="noopener noreferrer"
              className="flex cursor-pointer items-center gap-[6px] text-start no-underline decoration-1 hover:underline"
            >
              <div>
                {reference.filename ? (
                  <FileIcon fileName={reference.filename} className="h-[21px] w-[24px]" />
                ) : (
                  <Icons.Link className="h-[21px] w-[24px]" />
                )}
              </div>
              <p className="text-xs font-semibold leading-4 text-references-text">
                {reference.filename || reference.title}
              </p>
            </a>
          )}
        </li>
      ))}
      {agentFunction?.name && !excludeDisplayFunctionName.includes(agentFunction.name) && (
        <li className="mt-[-12px] cursor-pointer pt-6">
          <button
            onClick={() => {
              setDocumentInPreview && setDocumentInPreview(convertAgentToolToEmbeddedKnowledge(agentFunction));
            }}
            className="flex cursor-pointer items-center gap-[6px] text-start no-underline decoration-1 hover:underline"
          >
            <Icons.Puzzle className="h-[21px] w-[24px] text-references-text" />
            <p className="text-xs font-semibold leading-4 text-references-text">{agentFunction.name}</p>
          </button>
        </li>
      )}
    </ol>
  );
};

const addPrefixToUrl = (url: string) => {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return "https://" + url; // Add "https://" prefix if not present
  }
  return url; // URL already has a prefix
};
