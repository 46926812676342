import { useEffect } from "react";
import type { Socket } from "socket.io-client";
import { io } from "socket.io-client";
import { useGetUser } from "@/data/queries/useGetUser";
import type { QueryKey } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";

type UseWebsocketProps = {
  isGuestUser: boolean;
  queryKeys: QueryKey[];
  websocketEndpoint: string;
};

export const useWebsocket = ({ isGuestUser, websocketEndpoint, queryKeys }: UseWebsocketProps) => {
  const queryClient = useQueryClient();
  const { data: user } = useGetUser({ enabled: !isGuestUser });

  useEffect(() => {
    let newSocket: Socket;

    try {
      if (!user?._id) {
        return;
      }
      const baseApiUrl = import.meta.env.VITE_BASE_API_URL as string;
      const url = new URL(baseApiUrl);
      if (!url?.origin) {
        return;
      }
      newSocket = io(url.origin);
      newSocket.emit("register", user._id);
      newSocket.on(websocketEndpoint, () => {
        queryKeys.map(queryKey => {
          void queryClient.invalidateQueries({ queryKey });
        });
      });
    } catch (e) {
      console.log(e);
    }

    return () => {
      try {
        if (newSocket?.connected) {
          newSocket?.disconnect();
        }
      } catch (e) {
        console.log(e);
      }
    };
  }, [user?._id, queryClient, queryKeys, websocketEndpoint]);
};
