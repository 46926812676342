import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import { domElementIds } from "@/types/dom-element-ids";
import type { StatsMainChartSectionTab } from "@/types/stats";
import { StatsMainChartSectionTabs } from "@/types/stats";
import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
  activeSectionTab: StatsMainChartSectionTab;
  onActiveSectionChange?: (section: StatsMainChartSectionTab) => void;
  canChangeTab?: boolean;
};

export const StatsMainChartHeader = ({
  children,
  activeSectionTab,
  onActiveSectionChange,
  canChangeTab = true,
}: Props) => {
  const titleMap = {
    [StatsMainChartSectionTabs.ENGAGEMENT_OVERVIEW]: "Engagement Overview",
    [StatsMainChartSectionTabs.AGENTS_PERFORMANCE]: "Agents Performance",
  };

  const title = <h2 className="text-left text-2xl font-bold">{titleMap[activeSectionTab]}</h2>;

  return (
    <div
      className="flex w-full flex-wrap items-center justify-between gap-4"
      id={domElementIds.STATS_MAIN_CHART_HEADER}
    >
      {canChangeTab ? (
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger className="flex items-center gap-2">
            {title}
            <Icons.ChevronRight className="w-8 rotate-90 text-neutral-400" />
          </DropdownMenuTrigger>
          <DropdownMenuContent onClick={e => e.stopPropagation()} align="end">
            <DropdownMenuItem
              className="flex items-center gap-1.5 text-xs"
              onClick={() => {
                onActiveSectionChange?.(StatsMainChartSectionTabs.AGENTS_PERFORMANCE);
              }}
            >
              <Icons.Check
                className={cn("opacity-0", {
                  "opacity-100": activeSectionTab === StatsMainChartSectionTabs.AGENTS_PERFORMANCE,
                })}
              />
              {titleMap[StatsMainChartSectionTabs.AGENTS_PERFORMANCE]}
            </DropdownMenuItem>
            <DropdownMenuItem
              className="flex items-center gap-1.5 text-xs"
              onClick={() => {
                onActiveSectionChange?.(StatsMainChartSectionTabs.ENGAGEMENT_OVERVIEW);
              }}
            >
              <Icons.Check
                className={cn("opacity-0", {
                  "opacity-100": activeSectionTab === StatsMainChartSectionTabs.ENGAGEMENT_OVERVIEW,
                })}
              />
              {titleMap[StatsMainChartSectionTabs.ENGAGEMENT_OVERVIEW]}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <>{title}</>
      )}

      {children}
    </div>
  );
};
