import { useState } from "react";
import { useDebounceValue } from "usehooks-ts";

import noAgentImg from "@/assets/images/NoAgent.svg";
import { Skeleton } from "@/components/ui/skeleton";
import { ChatHistoryDialogListItem } from "@/components/ChatHistoryDialog/components/ChatHistoryDialogListItem";
import { MessagesNumRanges } from "@/components/ChatHistoryDialog/constants/chatHistory";
import { ChatHistoryDialog } from "@/components/ChatHistoryDialog/ChatHistoryDialog";
import { ChatHistoryConversationDialog } from "@/components/ChatHistoryDialog/ChatHistoryConversationDialog";
import type { MessagesNumRange } from "@/components/ChatHistoryDialog/constants/chatHistory";

import { useGetAllUsersConversationsWithAgent } from "@/data/queries/useGetAllUsersConversationsWithAgent";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";

import { DateRangeTypes, type DateRangeType } from "@/types/datepicker";
import type { Agent } from "@/types/agent";
import type { Conversation, ConversationWithUsersDetails } from "@/types/conversation";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { useGetAgentConversationHistoryReport } from "@/data/queries/stats/useGetAgentConversationHistoryReport";
import { AgentChatHistoryDialogChannelPicker } from "./components/AgentChatHistoryDialogChannelPicker";
import type { Origin } from "@/types/origin";
import { IconButton } from "@/components/ui/icon-button";
import { Checkbox } from "@/components/ui/checkbox";
import { AgentChatHistoryDialogFooter } from "./components/AgentChatHistoryDialogFooter";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  agentId: Agent["_id"];
  agentName: Agent["name"];
  initialCurrentRange?: DateRangeType;
  initialStartDate?: Date | null;
  initialEndDate?: Date | null;
  initialChannel?: Origin;
  initialDownvotedOnly?: boolean;
};

export type ConversationWithSearchMatches = {
  conversation: ConversationWithUsersDetails;
  searchMatches: number;
};

export const AgentChatHistoryDialog = ({
  isOpen,
  onClose,
  agentId,
  agentName,
  initialCurrentRange,
  initialStartDate,
  initialEndDate,
  initialChannel,
  initialDownvotedOnly,
}: Props) => {
  const [searchValue, setSearchValue] = useState("");

  const [currentRange, setCurrentRange] = useState<DateRangeType>(initialCurrentRange ?? DateRangeTypes.Last7Days);

  const [startDate, setStartDate] = useState<Date | null>(initialStartDate ?? null);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate ?? null);

  const [currentConversation, setCurrentConversation] = useState<ConversationWithSearchMatches | null>(null);

  const [selectedMessagesNumRange, setSelectedMessagesNumRange] = useState<MessagesNumRange>(MessagesNumRanges.All);
  const [customMinNumOfMessages, setCustomMinNumOfMessages] = useState<number | null>(null);
  const [customMaxNumOfMessages, setCustomMaxNumOfMessages] = useState<number | null>(null);
  const [isOnlyDownvotedMessages, setIsOnlyDownvotedMessages] = useState(initialDownvotedOnly ?? false);

  const [debouncedSearchValue] = useDebounceValue(searchValue, 700);
  const [debouncedCustomMinNumOfMessages] = useDebounceValue(customMinNumOfMessages, 700);
  const [debouncedCustomMaxNumOfMessages] = useDebounceValue(customMaxNumOfMessages, 700);

  const [activeChannel, setActiveChannel] = useState<Origin | null>(initialChannel ?? null);

  const [isSelectToDownloadOpen, setIsSelectToDownloadOpen] = useState(false);
  const [selectedConversationsIds, setSelectedConversationsIds] = useState<string[]>([]);

  const { data, isLoading } = useGetAllUsersConversationsWithAgent({
    agentId,
    enabled: !!agentId,
    startDate,
    endDate,
    currentRange,
    minMessages: debouncedCustomMinNumOfMessages,
    maxMessages: debouncedCustomMaxNumOfMessages,
    searchValue: debouncedSearchValue,
    onlyWithDownvotes: isOnlyDownvotedMessages,
    channels: activeChannel ? [activeChannel] : undefined,
  });

  const { downloadReport, isLoadingDownloadReport } = useGetAgentConversationHistoryReport();

  const handleDownloadReport = ({ conversationIds }: { conversationIds: Conversation["_id"][] }) => {
    void downloadReport({
      agentId,
      agentName,
      currentRange,
      startDate,
      endDate,
      minMessages: debouncedCustomMinNumOfMessages,
      maxMessages: debouncedCustomMaxNumOfMessages,
      searchValue: debouncedSearchValue,
      onlyWithDownvotes: isOnlyDownvotedMessages,
      conversations: conversationIds,
    });
  };

  const loader = Array.from({ length: 5 })?.map((_, index) => (
    <div key={index} className="h-12 px-1">
      <div key={index} className="custom-skeleton-container flex justify-between lg:px-6">
        <Skeleton height={18} width={200} />
        <Skeleton height={18} width={100} />
      </div>
    </div>
  ));

  const showSearchMatches = data?.totalSearchMatches !== undefined && !!debouncedSearchValue;

  const isScreenXl = useTailwindMediaQuery("xl");

  const getIsConversationSelected = (conversationId: string) => selectedConversationsIds.includes(conversationId);
  const handleClickConversationSelect = (conversationId: string, checked: boolean) => {
    if (checked) {
      setSelectedConversationsIds(prev => [...prev, conversationId]);
    } else {
      setSelectedConversationsIds(prev => prev.filter(id => id !== conversationId));
    }
  };

  const handleLetMeSelectConversationsToDownload = () => {
    setIsSelectToDownloadOpen(prev => !prev);
    const allCurrentConversationsIds =
      data?.conversations.map(conversationItem => conversationItem.conversation._id) ?? [];
    setSelectedConversationsIds(allCurrentConversationsIds);
  };

  return currentConversation ? (
    <ChatHistoryConversationDialog
      isOpen={isOpen}
      currentConversation={currentConversation}
      onBackButtonPress={() => setCurrentConversation(null)}
      onClose={onClose}
      showSearchMatches={showSearchMatches}
      debouncedSearchValue={debouncedSearchValue}
      variant={isScreenXl ? "xl" : "large"}
      renderActionButtons={() => (
        <Button
          variant="tertiary"
          prefixIcon={<Icons.Download />}
          onClick={() => {
            handleDownloadReport({ conversationIds: [currentConversation.conversation._id] });
          }}
          disabled={isLoadingDownloadReport}
          loading={isLoadingDownloadReport}
        >
          Download
        </Button>
      )}
    />
  ) : (
    <ChatHistoryDialog
      isOpen={isOpen}
      onClose={onClose}
      searchValue={searchValue}
      onSearchValueChange={setSearchValue}
      showSearchMatches={showSearchMatches}
      totalSearchMatches={data?.totalSearchMatches ?? 0}
      debouncedSearchValue={debouncedSearchValue}
      currentRange={currentRange}
      onChangeCurrentRange={setCurrentRange}
      startDate={startDate}
      onStartDateChange={setStartDate}
      endDate={endDate}
      onEndDateChange={setEndDate}
      selectedMessagesNumRange={selectedMessagesNumRange}
      onSelectedMessagesRangeChange={setSelectedMessagesNumRange}
      isOnlyDownvotedMessages={isOnlyDownvotedMessages}
      onIsOnlyDownvotedMessagesChange={setIsOnlyDownvotedMessages}
      customMinNumOfMessages={customMinNumOfMessages}
      onCustomMinNumOfMessagesChange={setCustomMinNumOfMessages}
      customMaxNumOfMessages={customMaxNumOfMessages}
      onCustomMaxNumOfMessagesChange={setCustomMaxNumOfMessages}
      renderHeader={
        <AgentChatHistoryDialogChannelPicker
          activeChannel={activeChannel}
          onActiveChannelChange={channel => setActiveChannel(channel)}
        />
      }
      renderFooter={() => (
        <AgentChatHistoryDialogFooter
          selectedConversationsIds={selectedConversationsIds}
          onSelectConversations={setSelectedConversationsIds}
          onDownload={handleDownloadReport}
          isLoadingDownload={isLoadingDownloadReport}
        />
      )}
      renderDownloadSelect={() => (
        <IconButton
          size="medium"
          className="ml-2"
          variant="tertiary"
          icon={<Icons.Download className="size-6" />}
          onClick={handleLetMeSelectConversationsToDownload}
          disabled={isLoading}
        />
      )}
    >
      {isLoading ? (
        loader
      ) : data?.conversations.length === 0 ? (
        <div className="flex h-full flex-col items-center justify-center">
          <img src={noAgentImg} alt="No Agent Found" className="w-60" />
          <p className="mt-5 max-w-[500px] text-center text-sm font-medium text-neutral-400">
            There is no data available right now. Create your agent, and you will start seeing conversations here.
          </p>
        </div>
      ) : (
        data?.conversations?.map(conversationItem => {
          return (
            <div key={conversationItem.conversation._id} className="flex items-center gap-2">
              {isSelectToDownloadOpen && (
                <Checkbox
                  variant="circle"
                  checked={getIsConversationSelected(conversationItem.conversation._id)}
                  onCheckedChange={checked => {
                    handleClickConversationSelect(conversationItem.conversation._id, checked);
                  }}
                />
              )}
              <ChatHistoryDialogListItem
                key={conversationItem.conversation._id}
                conversationItem={conversationItem}
                conversationUsers={conversationItem.conversation.users}
                onOpen={() => setCurrentConversation(conversationItem)}
                renderHoverButtons={() => (
                  <IconButton
                    variant="tertiary"
                    className="p-0"
                    icon={<Icons.Download className="size-5" />}
                    onClick={e => {
                      e.stopPropagation();
                      handleDownloadReport({ conversationIds: [conversationItem.conversation._id] });
                    }}
                    disabled={isLoadingDownloadReport}
                  />
                )}
              />
            </div>
          );
        })
      )}
    </ChatHistoryDialog>
  );
};
