import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import type { ChatAgent } from "@/types/conversation";
import { useNavigate } from "react-router";
import { Icons } from "@/components/ui/icons";
import { Avatar } from "@/components/Avatar";
import { ChevronDown } from "lucide-react";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useAddAgentToConversation } from "@/data/mutations/useAddAgentToConversation";
import {
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import GroupConversationAgentsName from "../../../GroupCoversations/GroupConversationAgentsName";
import { useState } from "react";
import ChatTopBarAgentTile from "./components/ChatTopBarAgentTile";
import GroupConversationAvatar from "@/components/GroupCoversations/GroupConversationAvatar";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { useGetUser } from "@/data/queries/useGetUser";
import RenameConversationDialog from "./components/RenameConversationDialog";
import { useRenameConversation } from "@/data/mutations/useRenameConversation";
import { LocalStorageHelpers } from "@/utils/LocalStorageHelpers";
import MultiAgentsChatDialog from "@/components/dialogs/MultiAgentsChatDialog/MultiAgentsChatDialog";
import { IconButton } from "@/components/ui/icon-button";

type ChatTopBarInfoProps = {
  agents: ChatAgent[];
  onBackClick?: () => void;
  setIsAgentDetailsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveAgentIndex?: React.Dispatch<React.SetStateAction<number>>;
};

export const ChatTopBarInfo = ({
  agents,
  onBackClick,
  setIsAgentDetailsOpen,
  setActiveAgentIndex,
}: ChatTopBarInfoProps) => {
  const { isMobile } = useMediaQueriesContext();
  const { isPreview } = useChatContext();
  const navigate = useNavigate();
  const { mutate: addAgents, isPending: isPendingAddAgents } = useAddAgentToConversation();
  const { mutate: renameConversation, isPending: isPendingRenameConversation } = useRenameConversation();
  const { conversationId, conversationDetails } = useChatContext();

  const { user } = useGetUser();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);

  const handleGoBack = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      navigate(-1);
    }
  };

  const handleAddAgentsToConversation = ({ agentIds }: { agentIds: string[] }) => {
    const isNewConversation = !conversationId;

    if (isNewConversation) {
      navigate(getNewConversationRoute([...agents.map(agent => agent._id), ...agentIds]));
    } else {
      addAgents(
        { conversationId: conversationId ?? "", agentIds },
        {
          onSuccess: () => {
            setIsEditDialogOpen(false);

            LocalStorageHelpers.updateLocalStorageConversation({
              conversationId,
              prevAgentIds: agents.map(agent => agent._id),
              updatedAgentIds: [...agents.map(agent => agent._id), ...agentIds],
            });
          },
        }
      );
    }
  };

  const handleRenameConversation = (name: string) => {
    if (!conversationId) {
      return;
    }
    renameConversation(
      { conversationId, title: name },
      {
        onSuccess: () => {
          setIsRenameDialogOpen(false);
        },
      }
    );
  };

  const showOnlyOneAgent =
    isPreview ||
    agents.some(agent => {
      const isMyAgent = agent.creator === user?._id;
      const isBasicAgent = "basic" in agent && agent.basic;
      const canCreateGroup = isMyAgent || isBasicAgent;
      return !canCreateGroup;
    });

  const canEditConversationTitle = !isPreview && !!conversationDetails;

  const handleAgentTileClick = ({ index }: { index: number }) => {
    setIsAgentDetailsOpen?.(true);
    setActiveAgentIndex?.(index);
  };

  return (
    <>
      <div className="flex items-center gap-2.5 overflow-hidden">
        {isMobile && (
          <IconButton variant="tertiary" icon={<Icons.Arrow className="size-6" />} onClick={handleGoBack}>
            <span className="sr-only">Go back</span>
          </IconButton>
        )}

        {showOnlyOneAgent ? (
          <div
            className="flex items-center gap-2 text-primary-black"
            onClick={() => isMobile && setIsAgentDetailsOpen?.(true)}
          >
            <Avatar name={agents[0].name} src={agents[0]?.avatar} />
            <div className="grid">
              <span className="truncate text-sm font-semibold">
                {conversationDetails?.title ? conversationDetails.title : agents[0].name}
              </span>
              {isPreview && <span className="text-xs font-medium text-gray-400">Chat preview</span>}
            </div>
          </div>
        ) : (
          <div
            className="flex items-center gap-2 text-primary-black"
            onClick={() => isMobile && setIsAgentDetailsOpen?.(true)}
          >
            <GroupConversationAvatar avatars={agents.map(agent => agent?.avatar)} />
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger
                onClick={e => e.stopPropagation()}
                className="flex items-center rounded-[4px] bg-neutral-50 px-2 py-1"
              >
                {conversationDetails?.title ? (
                  <h3 className='"truncate md:text-lg" text-left text-base font-semibold text-primary-black'>
                    {conversationDetails.title}
                  </h3>
                ) : (
                  <GroupConversationAgentsName names={agents.map(agent => agent.name)} />
                )}
                <ChevronDown className="ml-1 size-4 shrink-0" />
              </DropdownMenuTrigger>
              <DropdownMenuContent onClick={e => e.stopPropagation()} align="start">
                <div className="max-h-80 overflow-y-scroll">
                  {agents.map((agent, index) => {
                    const canRemove = agents.length > 1;

                    const isMyAgent = agent.creator === user?._id;

                    const creatorName =
                      "creatorUser" in agent ? agent.creatorUser?.name : isMyAgent ? user.name : agent.creator;

                    return (
                      <ChatTopBarAgentTile
                        key={agent._id}
                        agentId={agent._id}
                        name={agent.name}
                        avatar={agent?.avatar}
                        creatorName={creatorName ?? ""}
                        canRemove={canRemove}
                        currentAgentIds={agents.map(agent => agent._id)}
                        onClick={() => handleAgentTileClick({ index })}
                      />
                    );
                  })}
                </div>

                {isMobile && canEditConversationTitle && (
                  <DropdownMenuItem
                    className="flex items-center justify-center bg-neutral-50 py-4"
                    onSelect={() => setIsRenameDialogOpen(true)}
                  >
                    <Icons.Edit className="size-5" />
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  className="flex items-center justify-center bg-neutral-50 py-4"
                  onSelect={() => setIsEditDialogOpen(true)}
                >
                  <Icons.AddGroup />
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}

        {canEditConversationTitle && !isMobile && (
          <IconButton
            variant="tertiary"
            size="tiny"
            icon={<Icons.Edit />}
            onClick={() => setIsRenameDialogOpen(true)}
          />
        )}
      </div>

      <MultiAgentsChatDialog
        isOpen={isEditDialogOpen}
        setIsOpen={setIsEditDialogOpen}
        onSubmit={agentIds => handleAddAgentsToConversation({ agentIds })}
        isPendingAddAgents={isPendingAddAgents}
        initialSelectedAgentsIds={agents.map(agent => agent._id) ?? []}
        headerText="Add Agents to Chat"
      />

      <RenameConversationDialog
        isOpen={isRenameDialogOpen}
        setIsOpen={setIsRenameDialogOpen}
        onRenameSubmit={handleRenameConversation}
        isSubmitLoading={isPendingRenameConversation}
      />
    </>
  );
};
