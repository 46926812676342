import StatsEngagementOverview from "./StatsEngagementOverview/StatsEngagementOverview";
import { useEffect, useState } from "react";
import { StatsAgentsPerformace } from "./StatsAgentsPerformace";
import type { StatsMainChartSectionTab } from "@/types/stats";
import { StatsMainChartSectionTabs } from "@/types/stats";
import { StatsBlur } from "./common/StatsBlur";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import { StatsUpgradeMessage } from "./common/StatsUpgradeMessage";
import { domElementIds } from "@/types/dom-element-ids";

export const StatsMainChartSection = () => {
  const { isUpgradeNeeded } = useStatsContext();
  const [activeSectionTab, setActiveSectionTab] = useState<StatsMainChartSectionTab>(
    StatsMainChartSectionTabs.AGENTS_PERFORMANCE
  );

  const [headerTotalOffset, setHeaderTotalOffset] = useState(0);

  useEffect(() => {
    const headerElement = document.getElementById(domElementIds.STATS_MAIN_CHART_HEADER);
    if (headerElement) {
      setHeaderTotalOffset(headerElement.offsetHeight + headerElement.offsetTop);
    }
  }, []);

  return (
    <div className="relative flex h-full min-h-[550px] flex-col items-start gap-2.5 rounded-md border border-border-light bg-white px-5 py-6 shadow-md">
      <>
        {isUpgradeNeeded && (
          <StatsBlur
            className="rounded-md"
            style={{
              top: headerTotalOffset,
              height: `calc(100% - ${headerTotalOffset}px)`,
            }}
          >
            <div className="flex h-full flex-col items-center justify-center gap-4">
              <StatsUpgradeMessage suffixText="to see how your agents perform" />
            </div>
          </StatsBlur>
        )}

        {activeSectionTab === StatsMainChartSectionTabs.ENGAGEMENT_OVERVIEW && (
          <StatsEngagementOverview activeSectionTab={activeSectionTab} onActiveSectionChange={setActiveSectionTab} />
        )}
        {activeSectionTab === StatsMainChartSectionTabs.AGENTS_PERFORMANCE && (
          <StatsAgentsPerformace activeSectionTab={activeSectionTab} onActiveSectionChange={setActiveSectionTab} />
        )}
      </>
    </div>
  );
};
