import type { AgentNotification } from "@/types/notification";

export type GroupedNotification = {
  lastUpdateTime: Date;
  notifications: AgentNotification[];
  grouped: boolean;
  read: boolean;
};

export function groupAndSortNotifications(notifications: AgentNotification[]): GroupedNotification[] {
  const withEntityId = notifications.filter(n => n.entityId);
  const withoutEntityId = notifications.filter(n => !n.entityId);

  const grouped = withEntityId.reduce(
    (acc, notif) => {
      if (!notif.entityId) {
        return acc;
      }
      if (!acc[notif.entityId]) {
        acc[notif.entityId] = [];
      }
      acc[notif.entityId].push(notif);
      return acc;
    },
    {} as Record<string, AgentNotification[]>
  );

  const groupedArray: GroupedNotification[] = Object.values(grouped).map(group => {
    const hasUnread = group.some(notif => !notif.read);

    group.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    return {
      lastUpdateTime: group[0].date,
      notifications: group,
      grouped: true,
      read: !hasUnread,
    };
  });

  const groupedArraysTimeCompareFunction = (a: GroupedNotification, b: GroupedNotification) =>
    new Date(b.lastUpdateTime).getTime() - new Date(a.lastUpdateTime).getTime();

  groupedArray.sort(groupedArraysTimeCompareFunction);

  const ungroupedArray: GroupedNotification[] = withoutEntityId.map(notif => ({
    lastUpdateTime: notif.date,
    notifications: [notif],
    grouped: false,
    read: notif.read,
  }));

  const allNotifications = [...groupedArray, ...ungroupedArray];

  allNotifications.sort(groupedArraysTimeCompareFunction);

  return allNotifications;
}
