import { EnhancedCapability } from "./components/EnhancedCapability";
import { AdvancedCustomization } from "./components/AdvancedCustomization";
import LeadGeneration from "./components/LeadGeneration";
import GoogleCalendar from "./components/GoogleCalendar";

export const Actions = () => {
  return (
    <>
      <EnhancedCapability />
      <LeadGeneration />
      <GoogleCalendar />
      <AdvancedCustomization />
    </>
  );
};
