import type { SingleAgentEngagementStats, StatsSingleAgentPerformanceDataType } from "@/types/stats";
import { SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP } from "../constants/statsConstants";

export const getSingleAgentPerformanceFilteredData = ({
  data,
  selectedDataType,
}: {
  data: SingleAgentEngagementStats | undefined;
  selectedDataType: StatsSingleAgentPerformanceDataType | null;
}) => {
  if (!data) {
    return undefined;
  }

  if (!selectedDataType) {
    return data;
  }

  return {
    agent: data.agent,
    stats: data.stats.map(period => {
      const filteredStats = Object.fromEntries(
        Object.keys(SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP).map(key => [
          key,
          key === selectedDataType ? period[key as keyof typeof period] : undefined,
        ])
      );

      return { ...period, ...filteredStats };
    }),
  };
};
