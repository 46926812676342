import { SubscriptionTiers } from "@/types/subscriptions";
import { AccountUsageBenefitList } from "./AccountUsageBenefitList";
import { AccountUsagePricingCard } from "./AccountUsagePricingCard";
import { toast } from "react-toastify";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";
import { LoadingSpinner } from "@/components/ui/loading-spinner";

export const AccountUsageFreeCard = () => {
  const { data: plans, isLoading } = useGetBillingPlans();

  if (isLoading) {
    return <LoadingSpinner className="flex size-full items-center justify-center" />;
  }

  if (!plans) {
    toast.error("Failed to retrieve billing plans information");
    return null;
  }
  const freePlan = plans.billingPlans[SubscriptionTiers.FREE];
  const planPrice = 0;
  const features = freePlan?.features_as_text.split(", ");

  return (
    <AccountUsagePricingCard title="FREE" price={planPrice}>
      <AccountUsageBenefitList features={features} />
    </AccountUsagePricingCard>
  );
};
