import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  Dialog,
  DialogTrigger,
  DialogTitle,
  DialogClose,
} from "@/components/ui/dialog";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Icons } from "@/components/ui/icons";
import { StripePaymentForm } from "../SubscriptionTierDialog/components/StripePaymentSummary/components/StripePaymentForm";
import type { OnSubmitStripePlan } from "../SubscriptionTierDialog/components/StripePaymentSummary/StripePaymentSummary";
import { domElementIds } from "@/types/dom-element-ids";
import { useUpdatePaymentMethod } from "@/data/mutations/subscriptions/useUpdatePaymentMethod";
import type { Dispatch, SetStateAction } from "react";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";
import { AddressElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useCreatePaymentMethod } from "@/data/mutations/subscriptions/useCreatePaymentMethod";
import { toast } from "react-toastify";

type EditBillingInformationDialogProps = {
  isDialogOpen: boolean;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
};

export const EditBillingInformationDialog = ({
  isDialogOpen,
  setShowDialog,
  isLoading,
}: EditBillingInformationDialogProps) => {
  const elements = useElements();
  const stripe = useStripe();
  const { mutate: updatePaymentMethod, isPending: isPaymentMethodUpdating } = useUpdatePaymentMethod();
  const { mutate: createPaymentMethod, isPending: isPaymentMethodCreating } = useCreatePaymentMethod();

  const onSubmit: OnSubmitStripePlan = async ({ tax }) => {
    const cardNumberElement = elements?.getElement(CardNumberElement);
    if (!stripe || !cardNumberElement) {
      return;
    }
    const addressElement = await elements?.getElement(AddressElement)?.getValue();
    if (!addressElement || !addressElement.complete) {
      return;
    }

    createPaymentMethod(
      { cardNumberElement, userBillingDetails: addressElement.value },
      {
        onSuccess: data =>
          updatePaymentMethod(
            { paymentMethodId: data.paymentMethodId, tax },
            { onSuccess: () => setShowDialog(false) }
          ),
        onError: error => toast.error(error.message),
      }
    );
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <Tooltip>
        <TooltipTrigger asChild>
          <DialogTrigger asChild>
            <IconButton variant="tertiary" size="tiny" icon={<Icons.Edit2 className="text-primary-400" />}>
              <span className="sr-only">Edit Credit Card info</span>
            </IconButton>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>Edit Credit Card info</TooltipContent>
      </Tooltip>
      <DialogContent className="!h-auto max-h-dvh sm:min-h-min" variant="medium">
        <DialogHeader>
          <DialogTitle>Edit Billing Information</DialogTitle>
        </DialogHeader>
        <div className="overflow-y-auto px-8 pb-5">
          <StripePaymentForm onSubmit={onSubmit} />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="tertiary">Cancel</Button>
          </DialogClose>
          <Button
            loading={isLoading || isPaymentMethodCreating || isPaymentMethodUpdating}
            type="submit"
            form={domElementIds.STRIPE_FORM}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
