import { RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils";
import { StyleIconSize } from "@/types/agent";

const getEnumKeyByValue = (enumObj: object, value: string): string | undefined => {
  return Object.keys(enumObj).find(key => enumObj[key as keyof typeof enumObj] === value);
};

const sizesMap = {
  [StyleIconSize.Small]: "size-12",
  [StyleIconSize.Medium]: "size-16",
  [StyleIconSize.Large]: "size-20",
};

type Props = {
  avatar: string;
  size: StyleIconSize;
  isSelected: boolean;
};

export const BubbleDisplayAvatarVariant = ({ avatar, size, isSelected }: Props) => {
  return (
    <label
      className={cn(
        "flex cursor-pointer flex-col items-center justify-end gap-2 rounded-md px-3 py-2 hover:bg-primary-50",
        {
          "bg-primary-50": isSelected,
        }
      )}
    >
      <div
        className={cn(
          "relative overflow-hidden rounded-[40px_40px_4px_40px] bg-[#dddddd4d] shadow-xl outline-0 backdrop-blur-xl",
          sizesMap[size]
        )}
      >
        <img src={avatar} className="absolute left-0 top-0 z-10 h-full w-full rounded-[40px_40px_4px_40px] p-1" />
        <img
          src={avatar}
          className="absolute left-0 top-0 h-full w-full rounded-[40px_40px_4px_40px] object-cover blur-sm"
        />
      </div>

      <span className="flex items-center gap-2">
        <RadioGroupItem value={size} />
        {getEnumKeyByValue(StyleIconSize, size)}
      </span>
    </label>
  );
};
