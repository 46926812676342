import { restClient } from "../../initAxios";

export const googleAccessToken = {
  all: ["googleAccessToken"],
  id: (agentId: string) => [...googleAccessToken.all, agentId],
};

export const getGoogleAccessToken = async ({ agentId }: { agentId: string }) => {
  const { data } = await restClient.get<{ accessToken: string }>(
    `/externalService/googleAccessToken?agentId=${agentId}`
  );
  return data;
};
