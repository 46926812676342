import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { useGetUser } from "@/data/queries/useGetUser";
import { Icons } from "@/components/ui/icons";
import { AGENT_X_VIDEO_URL } from "@/constants/images";

export const LoginRoutesMobile = () => {
  const token = localStorage.getItem("token");
  const { user } = useGetUser({ enabled: !!token });

  if (user) {
    return <Navigate to={ROUTES.workspace} replace />;
  }

  return (
    <div className="flex h-[100vh] items-center justify-center overflow-hidden bg-gradient-to-b from-[#B8A8E7] to-[#F1C599] p-6 md:p-12">
      <div className="flex w-[320px] flex-col items-center gap-6">
        <Icons.AgentLogo className="h-[16px] w-[100px] text-center text-neutral-750" />
        <span className="text-center text-xs font-medium text-neutral-750">
          Explore new features! Start building your AI agent with AgentX today.
        </span>
        <iframe
          src={AGENT_X_VIDEO_URL}
          allow="autoplay"
          allowFullScreen
          className="h-[180px] w-[320px] rounded-xl bg-gradient-to-t from-[#F7F5DA] to-[#EBE6F6]"
        ></iframe>
        <div className="w-full rounded-xl bg-white p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
