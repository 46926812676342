import { useQuery } from "@tanstack/react-query";
import { restClient } from "../../initAxios";
import { apiPaths } from "@/data/apiPaths";

export const allGoogleCalendars = {
  all: ["allGoogleCalendars"],
  email: (email: string) => [...allGoogleCalendars.all, email],
};

export const getAllGoogleCalendars = async ({ email }: { email: string }) => {
  const { data } = await restClient.get<{ id: string; summary: string }[]>(
    `${apiPaths.getAllGoogleCalendars}?email=${email}`
  );
  return data;
};

export const useGetAllGoogleCalendars = (email: string) => {
  const query = useQuery({
    queryKey: allGoogleCalendars.email(email),
    queryFn: () => getAllGoogleCalendars({ email }),
    staleTime: Infinity,
  });

  return query;
};
