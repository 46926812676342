import { useEffect, useState } from "react";
import { BookmarkList } from "./components/BookmarkList";
import { ChatHistory } from "./components/ChatHistory";
import { cn } from "@/lib/utils";
import { Icons } from "@/components/ui/icons";
import { Button } from "../ui/button";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import type { AgentMode } from "@/types/agent";

type Tab = {
  title: "Bookmarks" | "Chat History" | "Thread History";
  visible: boolean;
};

const generateTabs = (agentMode: AgentMode) =>
  [
    { title: "Bookmarks", visible: true },
    { title: "Chat History", visible: agentMode === "chat" },
    { title: "Thread History", visible: agentMode === "search" },
  ].filter(({ visible }) => visible) as Tab[];

type BookmarksHistoryPanelProps = {
  className?: string;
  onClosePanel?: () => void;
};

const tabComponents: { [K in Tab["title"]]: React.ComponentType<Pick<BookmarksHistoryPanelProps, "onClosePanel">> } = {
  Bookmarks: BookmarkList,
  "Chat History": ChatHistory,
  "Thread History": ChatHistory, //TODO: change after BE implementation
};

const BookmarksHistoryPanel = ({ className, onClosePanel }: BookmarksHistoryPanelProps) => {
  const { agentMode } = useChatContext();
  const [activeTab, setActiveTab] = useState<Tab["title"]>(agentMode === "chat" ? "Chat History" : "Thread History");
  const ActiveTabComponent = tabComponents[activeTab];

  useEffect(() => {
    if (activeTab === "Bookmarks") {
      return;
    }

    setActiveTab(agentMode === "chat" ? "Chat History" : "Thread History");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentMode]);

  return (
    <div
      className={cn(
        "z-50 h-full w-full bg-[#1F0E3D] lg:min-w-[20rem] lg:max-w-[20rem] min-[1150px]:max-w-[24rem] xl:min-w-[32rem] xl:max-w-[32rem]",
        className
      )}
    >
      <div className="flex items-center gap-3 px-6 pb-0 pt-4">
        <Button variant="ghost" size="custom" onClick={onClosePanel} className="lg:hidden">
          <Icons.Arrow className="size-6 text-white" />
          <span className="sr-only">go back</span>
        </Button>

        {generateTabs(agentMode).map(tab => (
          <div
            key={tab.title}
            className={cn("cursor-pointer whitespace-nowrap border-b-2 border-transparent text-xl text-white", {
              "border-white font-bold": activeTab === tab.title,
            })}
            onClick={() => setActiveTab(tab.title)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <ActiveTabComponent onClosePanel={onClosePanel} />
    </div>
  );
};

export default BookmarksHistoryPanel;
