import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { useBookmarkChatHistoryContext } from "@/contexts/BookmarkChatHistoryContext/useBookmarkChatHistoryContext";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import ChatHistoryNavBtn from "./ChatHistoryNavBtn";
import { useGetChatHistoryWithAgentsLimited } from "@/data/queries/useGetChatHistoryWithAgents";
import { NAV_HISTORY_LIMIT } from "@/constants/navHistoryLimit";
import GroupConversationAvatar from "../GroupCoversations/GroupConversationAvatar";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import type { Agent } from "@/types/agent";
import { LocalStorageHelpers } from "@/utils/LocalStorageHelpers";
import GroupConversationAgentsName from "../GroupCoversations/GroupConversationAgentsName";
import { cn } from "@/lib/utils";
import { AgentsHistoryItem } from "./AgentsHistoryItem";

export const AgentHistoryNav = ({ onCloseMobileNav }: { onCloseMobileNav?: () => void }) => {
  const navigate = useNavigate();
  const { setIsOpen: setIsBookmarkCharHistoryOpen } = useBookmarkChatHistoryContext();
  const { isMobile } = useMediaQueriesContext();

  const { data: history } = useGetChatHistoryWithAgentsLimited({ limit: NAV_HISTORY_LIMIT });

  const { pathname } = useLocation();

  const canShowChatHistoryBtn = () => {
    const isProperPath = !pathname.includes(ROUTES.conversation(""));

    return isProperPath;
  };

  const handleAgentsClick = ({ agentIds }: { agentIds: Agent["_id"][] }) => {
    const lastConversationId = LocalStorageHelpers.getAgentsLocalStorageConversation(agentIds);

    const newPath = lastConversationId ? ROUTES.conversation(lastConversationId) : getNewConversationRoute(agentIds);

    navigate(newPath);
  };

  const getAgentsState = ({
    agentsStates,
    agentsCount,
  }: {
    agentsStates: (Agent["state"] | undefined)[];
    agentsCount: number;
  }) => {
    if (agentsStates.length !== agentsCount || agentsStates.some(state => !state)) {
      return null;
    }

    const areAllStatesTheSame = new Set(agentsStates).size === 1;

    return areAllStatesTheSame ? agentsStates[0] : null;
  };

  return (
    <>
      <div className="h-[1px] w-full bg-neutral-300 lg:w-10" />
      <div className="grid grid-cols-2 place-items-center gap-x-2 gap-y-3 overflow-y-scroll lg:grid-cols-1 lg:place-items-start">
        {history?.map(historyItem => (
          <AgentsHistoryItem
            key={historyItem.agents.map(agent => agent._id).join("-")}
            onClick={() => {
              handleAgentsClick({
                agentIds: historyItem.agents.map(agent => agent._id),
              });
            }}
            className={cn("grid-cols-[40px_1fr]", {
              "pr-3 hover:rounded-full hover:bg-primary-500": !isMobile,
            })}
          >
            <GroupConversationAvatar
              avatars={historyItem.agents.map(agent => agent?.avatar)}
              className="cursor-pointer"
              agentState={getAgentsState({
                agentsStates: historyItem.agents.map(agent => agent.state),
                agentsCount: historyItem.agents.length,
              })}
            />
            <div className="hidden pl-1 text-xs text-white lg:block">
              <GroupConversationAgentsName
                as="p"
                names={historyItem.agents.map(agent => agent.name)}
                className="text-xs font-medium text-white md:text-xs"
              />
            </div>
          </AgentsHistoryItem>
        ))}
        {canShowChatHistoryBtn() && isMobile && (
          <ChatHistoryNavBtn
            setIsOpen={isOpen => {
              setIsBookmarkCharHistoryOpen(isOpen);
              onCloseMobileNav?.();
            }}
          />
        )}
      </div>
      {canShowChatHistoryBtn() && !isMobile && <ChatHistoryNavBtn setIsOpen={setIsBookmarkCharHistoryOpen} />}
    </>
  );
};
