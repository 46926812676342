import { Button } from "@/components/ui/button";
import { useAcknowledgeNotifications } from "@/data/mutations/useAcknowledgeNotifications";
import type { AgentNotification } from "@/types/notification";

type NotificationsActionsProps = {
  unreadNotifications: AgentNotification[] | undefined;
};

export const NotificationsActions = ({ unreadNotifications }: NotificationsActionsProps) => {
  const { mutate: acknowledgeNotifications } = useAcknowledgeNotifications();

  const markAllAsRead = () => {
    const unreadNotificationsIds = unreadNotifications?.map(notification => notification._id);

    if (!unreadNotificationsIds) {
      return;
    }

    return acknowledgeNotifications({ ids: unreadNotificationsIds });
  };

  return (
    <div className="flex items-center justify-between rounded-t-[20px] border-b border-b-gray-200 px-8 py-4">
      <div className="text-lg font-semibold">Notification</div>
      {!!unreadNotifications?.length && (
        <Button variant="ghost" className="test-md p-0 font-semibold text-primary-400" onClick={markAllAsRead}>
          Mark all as read
        </Button>
      )}
    </div>
  );
};
