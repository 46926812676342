import { EditBillingInformationDialog } from "@/components/dialogs/BillingDialogs/EditBillingInformationDialog";
import { Icons } from "@/components/ui/icons";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";
import type { BillingData } from "@/types/subscriptions";
import { loaderArray } from "@/utils/loaderArray";
import type { Dispatch, SetStateAction } from "react";
import { useMemo } from "react";

type BillingPaymentMethodProps = {
  billingInfo: BillingData | undefined;
  isChangePaymentDialogOpen: boolean;
  setShowPaymentDialog: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
};

export const BillingPaymentMethod = ({
  billingInfo,
  isChangePaymentDialogOpen,
  setShowPaymentDialog,
  isLoading,
}: BillingPaymentMethodProps) => {
  const { data: plans } = useGetBillingPlans();
  const paymentMethod = billingInfo?.paymentMethods?.[0];

  const taxFormatted = useMemo(() => {
    const tax = billingInfo?.tax;

    if (!tax) {
      return "-";
    }
    if (!tax?.value || !tax?.type) {
      return "-";
    }

    const taxType = plans?.taxIdList?.find(t => t?.type === tax?.type);
    if (!taxType) {
      return tax.type + " / " + tax.value;
    }
    return taxType.flag + " " + tax.value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingInfo?.tax]);

  return (
    <div>
      <div className="mb-4 mt-6 flex justify-between text-lg font-bold">Payment Method</div>
      {paymentMethod ? (
        <>
          <div className="flex items-center gap-4 rounded-md border border-neutral-300 bg-neutral-50 px-4 py-3 md:gap-8">
            <div className="flex w-full items-center gap-6 text-sm font-medium">
              <div className="flex w-full max-w-max items-center gap-2">
                <Icons.CreditCard className="size-6" />
                <span className="text-center capitalize">{paymentMethod.card.brand}</span>
              </div>
              <div className="flex w-full flex-col justify-between gap-x-4 gap-y-2 sm:flex-row sm:items-center">
                <div className="flex items-center gap-1 [&>*:nth-of-type(4n)]:mr-2">
                  {loaderArray(12).map((_, index) => (
                    <div key={`cc-dot-${index}`} className="size-0.5 rounded-full bg-neutral-500 "></div>
                  ))}
                  <span className="text-xs">{paymentMethod.card.last4}</span>
                </div>
                <div className="flex items-center gap-3 text-xs">
                  <span>EXP END</span>
                  <div>
                    <span>{paymentMethod.card.exp_month}</span>/<span>{paymentMethod.card.exp_year}</span>
                  </div>
                </div>
              </div>
            </div>
            <EditBillingInformationDialog
              isDialogOpen={isChangePaymentDialogOpen}
              setShowDialog={setShowPaymentDialog}
              isLoading={isLoading}
            />
          </div>
          <div className="mt-6 grid max-w-max grid-cols-[120px_1fr] gap-4 text-sm text-neutral-500 [&>*:nth-of-type(odd)]:text-neutral-750">
            <span>Full name:</span>
            <span>{paymentMethod.billing_details.name}</span>
            <span>Street:</span>
            <span>
              {paymentMethod.billing_details.address.line1} {paymentMethod.billing_details.address.line2 ?? ""}
            </span>
            <span>City:</span>
            <span>{paymentMethod.billing_details.address.city}</span>
            <span>State:</span>
            <span>{paymentMethod.billing_details.address.state}</span>
            <span>Postal code:</span>
            <span>{paymentMethod.billing_details.address.postal_code}</span>
            <span>Country:</span>
            <span>{paymentMethod.billing_details.address.country}</span>
            <span>Tax ID</span>
            <span>{taxFormatted}</span>
          </div>
        </>
      ) : (
        <div className="flex items-center gap-2">
          <Icons.CreditCard className="size-6" />
          <span>Credit card</span>
          <div className="ml-4 flex items-center gap-1 [&>*:nth-of-type(4n)]:mr-2">
            {loaderArray(16).map((_, index) => (
              <div key={`cc-dot-${index}`} className="size-1 rounded-full bg-neutral-500"></div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
