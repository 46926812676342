import type { Origin } from "@/types/origin";

export const getStatsChannelValue = ({
  allChannels,
  channel,
}: {
  allChannels: {
    value: number;
    channel: Origin;
  }[];
  channel: Origin;
}) => {
  return allChannels.find(channelItem => channelItem.channel === channel)?.value ?? 0;
};
