import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { Agent } from "@/types/agent";
import type { GoogleCalendarTool } from "@/types/tools";

export const agentGoogleCalendarKeys = {
  all: ["agentGoogleCalendarTool"],
  id: (id: Agent["_id"]) => [...agentGoogleCalendarKeys.all, id],
};

type Props = {
  agentId: Agent["_id"];
  enabled?: boolean;
};

const getAgentGoogleCalendar = async ({ agentId }: Props) => {
  const { data } = await restClient.get<GoogleCalendarTool>(apiPaths.getAgentGoogleCalendarTool(agentId));
  return data;
};

export const useGetAgentGoogleCalendarTool = ({ agentId, enabled = true }: Props) => {
  return useQuery({
    queryKey: agentGoogleCalendarKeys.id(agentId),
    queryFn: () => getAgentGoogleCalendar({ agentId }),
    enabled,
  });
};
