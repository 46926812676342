import { AgentState } from "@/types/agent";
import { Origins } from "@/types/origin";
import type { AgentOverviewStats, AllAgentsStat, EngagementOverviewStat, StatsDougnhnutChartData } from "@/types/stats";

export const statsMockBasicData = [
  {
    title: "Messages",
    value: 636,
    trend: 22,
  },
  {
    title: "Conversations",
    value: 55,
    trend: 8,
  },
  {
    title: "Views",
    value: 958,
    trend: 6,
  },
  {
    title: "Lead Generation",
    value: 36,
    trend: 45,
  },
];

export const statsMockTopAgentData = {
  agent: {
    _id: "1",
    creator: "1",
    isActive: true,
    agentType: "humanlike",
    name: "John Doe",
    avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/13.svg",
    contacts: [],
    blocked: [],
    blockedFrom: [],
    deleted: false,
    createdAt: "2021-09-01",
    updatedAt: "2021-09-01",
    state: AgentState.AGENT_READY,
    publishedToCommunity: true,
    externalKnowledgeIntegrations: {},
  },
  messages: {
    total: 636,
    trend: 22,
  },
  views: {
    total: 958,
    trend: 6,
  },
  satisfaction: 97,
};

export const statsMockEngagementData: EngagementOverviewStat[] = [
  {
    intervalStartDate: "2021-09-01",
    intervalEndDate: "2021-09-02",
    agents: 5,
    messages: 60,
    conversations: 7,
  },
  {
    intervalStartDate: "2021-09-02",
    intervalEndDate: "2021-09-03",
    agents: 3,
    messages: 49,
    conversations: 5,
  },
  {
    intervalStartDate: "2021-09-03",
    intervalEndDate: "2021-09-04",
    agents: 5,
    messages: 63,
    conversations: 10,
  },
  {
    intervalStartDate: "2021-09-04",
    intervalEndDate: "2021-09-05",
    agents: 5,
    messages: 64,
    conversations: 15,
  },
  {
    intervalStartDate: "2021-09-05",
    intervalEndDate: "2021-09-06",
    agents: 6,
    messages: 70,
    conversations: 18,
  },
  {
    intervalStartDate: "2021-09-06",
    intervalEndDate: "2021-09-07",
    agents: 4,
    messages: 55,
    conversations: 12,
  },
  {
    intervalStartDate: "2021-09-07",
    intervalEndDate: "2021-09-08",
    agents: 7,
    messages: 75,
    conversations: 20,
  },
  {
    intervalStartDate: "2021-09-08",
    intervalEndDate: "2021-09-09",
    agents: 6,
    messages: 72,
    conversations: 19,
  },
  {
    intervalStartDate: "2021-09-09",
    intervalEndDate: "2021-09-10",
    agents: 8,
    messages: 80,
    conversations: 22,
  },
  {
    intervalStartDate: "2021-09-10",
    intervalEndDate: "2021-09-11",
    agents: 7,
    messages: 78,
    conversations: 21,
  },
  {
    intervalStartDate: "2021-09-11",
    intervalEndDate: "2021-09-12",
    agents: 9,
    messages: 85,
    conversations: 25,
  },
  {
    intervalStartDate: "2021-09-12",
    intervalEndDate: "2021-09-13",
    agents: 8,
    messages: 82,
    conversations: 23,
  },
  {
    intervalStartDate: "2021-09-13",
    intervalEndDate: "2021-09-14",
    agents: 10,
    messages: 90,
    conversations: 27,
  },
  {
    intervalStartDate: "2021-09-14",
    intervalEndDate: "2021-09-15",
    agents: 9,
    messages: 88,
    conversations: 26,
  },
  {
    intervalStartDate: "2021-09-15",
    intervalEndDate: "2021-09-16",
    agents: 11,
    messages: 95,
    conversations: 30,
  },
];

export const statsMockAllAgentsData: AllAgentsStat[] = [
  {
    agent: {
      _id: "1",
      creator: "1",
      isActive: true,
      agentType: "humanlike",
      name: "John Doe",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/13.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-01",
      updatedAt: "2021-09-01",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 60,
    conversations: 10,
    views: 50,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "2",
      creator: "2",
      isActive: true,
      agentType: "humanlike",
      name: "Jane Smith",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/14.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-02",
      updatedAt: "2021-09-02",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 45,
    conversations: 9,
    views: 75,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "3",
      creator: "3",
      isActive: true,
      agentType: "humanlike",
      name: "Alice Johnson",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/15.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-03",
      updatedAt: "2021-09-03",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 55,
    conversations: 12,
    views: 95,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "4",
      creator: "4",
      isActive: true,
      agentType: "humanlike",
      name: "Bob Brown",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/16.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-04",
      updatedAt: "2021-09-04",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 40,
    conversations: 8,
    views: 90,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "5",
      creator: "5",
      isActive: true,
      agentType: "humanlike",
      name: "Charlie Davis",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/17.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-05",
      updatedAt: "2021-09-05",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 60,
    conversations: 13,
    views: 120,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "6",
      creator: "6",
      isActive: true,
      agentType: "humanlike",
      name: "Diana Evans",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/18.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-06",
      updatedAt: "2021-09-06",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 52,
    conversations: 11,
    views: 105,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "7",
      creator: "7",
      isActive: true,
      agentType: "humanlike",
      name: "Eve Foster",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/19.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-07",
      updatedAt: "2021-09-07",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 48,
    conversations: 10,
    views: 98,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "8",
      creator: "8",
      isActive: true,
      agentType: "humanlike",
      name: "Frank Green",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/20.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-08",
      updatedAt: "2021-09-08",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 58,
    conversations: 14,
    views: 135,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
  {
    agent: {
      _id: "9",
      creator: "9",
      isActive: true,
      agentType: "humanlike",
      name: "Grace Harris",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/21.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-09",
      updatedAt: "2021-09-09",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 62,
    conversations: 15,
    views: 155,
    likedMessages: 45,
    dislikedMessages: 2,
    likedConversations: 5,
    dislikedConversations: 0,
  },
];

export const statsMockAgentsOverviewData: AgentOverviewStats[] = [
  {
    agent: {
      _id: "1",
      creator: "1",
      isActive: true,
      agentType: "humanlike",
      name: "John Doe",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/13.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-01",
      updatedAt: "2021-09-01",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 50,
    messagesTrend: 37,
    conversations: 10,
    conversationsTrend: 24,
    satisfaction: 97,
    satisfactionTrend: 55,
    channels: [
      { channel: Origins.WEB, value: 30 },
      { channel: Origins.EMBEDDED, value: 30 },
      { channel: Origins.AGENT_SPACE, value: 30 },
      { channel: Origins.WORKSPACE, value: 30 },
      { channel: Origins.SLACK, value: 30 },
      { channel: Origins.WHATSAPP, value: 30 },
      { channel: Origins.INSTAGRAM, value: 30 },
    ],
  },
  {
    agent: {
      _id: "2",
      creator: "2",
      isActive: true,
      agentType: "humanlike",
      name: "Jane Smith",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/14.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-02",
      updatedAt: "2021-09-02",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 45,
    messagesTrend: 30,
    conversations: 9,
    conversationsTrend: 20,
    satisfaction: 95,
    satisfactionTrend: 50,
    channels: [
      { channel: Origins.WEB, value: 28 },
      { channel: Origins.EMBEDDED, value: 28 },
      { channel: Origins.AGENT_SPACE, value: 28 },
      { channel: Origins.WORKSPACE, value: 28 },
      { channel: Origins.SLACK, value: 28 },
      { channel: Origins.WHATSAPP, value: 28 },
      { channel: Origins.INSTAGRAM, value: 28 },
    ],
  },
  {
    agent: {
      _id: "3",
      creator: "3",
      isActive: true,
      agentType: "humanlike",
      name: "Alice Johnson",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/15.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-03",
      updatedAt: "2021-09-03",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 55,
    messagesTrend: 30,
    conversations: 12,
    conversationsTrend: 20,
    satisfaction: 98,
    satisfactionTrend: 50,
    channels: [
      { channel: Origins.WEB, value: 32 },
      { channel: Origins.EMBEDDED, value: 32 },
      { channel: Origins.AGENT_SPACE, value: 32 },
      { channel: Origins.WORKSPACE, value: 32 },
      { channel: Origins.SLACK, value: 32 },
      { channel: Origins.WHATSAPP, value: 32 },
      { channel: Origins.INSTAGRAM, value: 32 },
    ],
  },
  {
    agent: {
      _id: "4",
      creator: "4",
      isActive: true,
      agentType: "humanlike",
      name: "Bob Brown",
      avatar: "https://agentx-resources.s3.us-west-1.amazonaws.com/system_agent_avatars/16.svg",
      contacts: [],
      blocked: [],
      blockedFrom: [],
      deleted: false,
      createdAt: "2021-09-04",
      updatedAt: "2021-09-04",
      state: AgentState.AGENT_READY,
      publishedToCommunity: true,
      externalKnowledgeIntegrations: {},
    },
    messages: 40,
    messagesTrend: 30,
    conversations: 8,
    conversationsTrend: 20,
    satisfaction: 92,
    satisfactionTrend: 50,
    channels: [
      { channel: Origins.WEB, value: 25 },
      { channel: Origins.EMBEDDED, value: 25 },
      { channel: Origins.AGENT_SPACE, value: 25 },
      { channel: Origins.WORKSPACE, value: 25 },
      { channel: Origins.SLACK, value: 25 },
      { channel: Origins.WHATSAPP, value: 25 },
      { channel: Origins.INSTAGRAM, value: 25 },
    ],
  },
];

export const statsEmptyDoughnutChartData: StatsDougnhnutChartData = {
  datasets: [
    {
      data: [1],
      backgroundColor: ["#E0E0E0"],
    },
  ],
};
