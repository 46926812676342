import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { StatsQueryFilters } from "@/types/stats";
import { StatisticsHelpers } from "@/utils/statistics/StatisticsHelpers";
import type { Agent } from "@/types/agent";
import { useState } from "react";
import type { Conversation } from "@/types/conversation";
import type { Origin } from "@/types/origin";

type GetReportKeys = {
  agentId: Agent["_id"];
  startDateParam: string;
  endDateParam: string;
  channels: Origin[];
};

type GetReportProps = {
  agentId: Agent["_id"];
  startDate: string;
  endDate: string;
  minMessages: number | null;
  maxMessages: number | null;
  searchValue: string;
  onlyWithDownvotes?: boolean;
  conversations?: Conversation["_id"][];
};

type HandleDownloadReportProps = {
  agentId: Agent["_id"];
  agentName: Agent["name"];
  currentRange: StatsQueryFilters["currentRange"];
  startDate: StatsQueryFilters["startDate"];
  endDate: StatsQueryFilters["endDate"];
  minMessages: number | null;
  maxMessages: number | null;
  searchValue: string;
  onlyWithDownvotes?: boolean;
  conversations?: Conversation["_id"][];
};

export const conversationHistoryReport = {
  all: ["conversationHistoryReport"] as const,
  params: ({ agentId, startDateParam, endDateParam, channels }: GetReportKeys) =>
    [...conversationHistoryReport.all, agentId, startDateParam, endDateParam, channels] as const,
};

export const getConversationHistoryReport = async ({
  agentId,
  startDate,
  endDate,
  minMessages,
  maxMessages,
  searchValue,
  onlyWithDownvotes,
  conversations,
}: GetReportProps) => {
  const response = await restClient.get(apiPaths.downloadAllUsersConversationsWithAgent({ agentId, conversations }), {
    params: {
      startDate,
      endDate,
      timezone: StatisticsHelpers.getTimezone(),
      minMessages,
      maxMessages,
      search: searchValue,
      onlyWithDownvotes,
    },
    responseType: "blob",
  });
  return response;
};

export const useGetAgentConversationHistoryReport = () => {
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false);

  const downloadReport = async ({
    agentId,
    agentName,
    currentRange,
    startDate,
    endDate,
    minMessages,
    maxMessages,
    searchValue,
    onlyWithDownvotes,
    conversations,
  }: HandleDownloadReportProps) => {
    setIsLoadingDownloadReport(true);

    try {
      const startDateParam = StatisticsHelpers.getStartDateParam({ range: currentRange, startDate }) ?? "";
      const endDateParam = StatisticsHelpers.getEndDateParam({ range: currentRange, endDate }) ?? "";

      const response = await getConversationHistoryReport({
        agentId,
        startDate: startDateParam,
        endDate: endDateParam,
        minMessages,
        maxMessages,
        searchValue,
        onlyWithDownvotes,
        conversations,
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `${agentName}_chat_history.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading report", error);
    } finally {
      setIsLoadingDownloadReport(false);
    }
  };

  return {
    downloadReport,
    isLoadingDownloadReport,
  };
};
