import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { Fragment, type ReactNode } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Icons } from "./ui/icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { TooltipPortal } from "@radix-ui/react-tooltip";

type HybridTooltipPopoverProps = {
  triggerClassName?: string;
  heading?: string;
  children: ReactNode;
  triggerComponent?: ReactNode;
  asChild?: boolean;
};

export const HybridTooltipPopover = ({
  heading,
  triggerClassName,
  children,
  triggerComponent,
  asChild,
}: HybridTooltipPopoverProps) => {
  const { isTouch } = useMediaQueriesContext();

  const Components = isTouch
    ? { Wrapper: Popover, Content: PopoverContent, Trigger: PopoverTrigger, ContentWrapper: Fragment }
    : { Wrapper: Tooltip, Content: TooltipContent, Trigger: TooltipTrigger, ContentWrapper: TooltipPortal };

  return (
    <Components.Wrapper>
      <Components.Trigger className={triggerClassName || ""} asChild={asChild}>
        {triggerComponent ?? <Icons.Info className="text-primary-400" />}
      </Components.Trigger>

      <Components.ContentWrapper>
        <Components.Content>
          {heading ? <h4 className="font-semibold">{heading}</h4> : null}
          {children}
        </Components.Content>
      </Components.ContentWrapper>
    </Components.Wrapper>
  );
};
