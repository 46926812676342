import { useEffect, useState } from "react";
import { parse } from "papaparse";
import type { DocRenderer } from "react-doc-viewer";

const CSVRenderer: DocRenderer = ({ mainState }) => {
  const { currentDocument } = mainState;
  const [csvData, setCsvData] = useState<string[][] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!currentDocument?.uri) {
      setError("No CSV URI to load.");
      return;
    }

    fetch(currentDocument.uri)
      .then(res => {
        if (!res.ok) {
          throw new Error(`Network response was not OK (status: ${res.status})`);
        }
        return res.text();
      })
      .then(text => {
        const parsed = parse<string[]>(text);
        if (parsed.errors?.length) {
          setError(parsed.errors.map(err => err.message).join(", "));
        } else {
          setCsvData(parsed.data);
        }
      })
      .catch((err: Error) => {
        setError(`Failed to load CSV: ${err.message}`);
      });
  }, [currentDocument]);

  if (error) {
    return <div className="p-2 text-sm text-red-600">Error: {error}</div>;
  }

  if (!csvData) {
    return <div className="p-2 text-sm">Loading CSV...</div>;
  }

  if (csvData.length === 0) {
    return <div className="p-2 text-sm">No data in CSV.</div>;
  }

  return (
    <div className="overflow-auto">
      <table className="min-w-full border-collapse border border-primary-400 text-sm">
        <thead className="bg-primary-100">
          <tr>
            {csvData[0].map((header, idx) => (
              <th key={idx} className="border border-primary-300 p-2 font-bold">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {csvData.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex} className="hover:bg-primary-50">
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="border border-primary-300 p-2">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

CSVRenderer.fileTypes = ["csv"];
CSVRenderer.weight = 0;

export default CSVRenderer;
