import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";
import { Button } from "@/components/ui/button";

export const RegisterAgeVerificationFailed = () => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-col gap-6 xl:gap-10">
      <h2 className="text-center text-2xl font-bold leading-9 tracking-tight lg:text-[28px] xl:text-start">
        Sorry, you can’t access AgentX
      </h2>
      <p className="w-full text-center text-sm font-bold leading-[170%] text-primary-black xl:w-[280px] xl:text-start">
        Access is not granted to individuals under the age of 13.
      </p>
      <Button variant="primary" size="medium" className="w-full font-bold" onClick={() => navigate(ROUTES.login)}>
        Exit
      </Button>
    </div>
  );
};
