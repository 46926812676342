import type { AgentNotification } from "@/types/notification";
import { NotificationReference } from "@/types/notification";
import { cn } from "@/lib/utils";
import { calculateNotificationsTimeDifference } from "@/utils/calculateNotificationsTimeDifference";

type NotificationDescriptionProps = {
  notification: AgentNotification;
};

export const NotificationDescription = ({ notification }: NotificationDescriptionProps) => {
  const notificationType =
    Object.keys(NotificationReference)[
      Object.values(NotificationReference).indexOf(notification.referenceType)
    ].toLowerCase();

  const isLikeNotification = notification?.subtitle.toLowerCase().includes("like");

  return (
    <div className="flex flex-col gap-1">
      <div className={cn("flex gap-1 text-sm", !isLikeNotification && "font-semibold")}>
        {isLikeNotification && <strong>{notification.title}</strong>}
        <span className={isLikeNotification ? "lowercase" : ""}>{notification.subtitle}</span>
      </div>
      <div className="flex items-center gap-2">
        <div className="text-xs text-gray-400">{calculateNotificationsTimeDifference(notification.date)}</div>
        <div className="h-1 w-1 rounded-full bg-neutral-400 " />
        <div className="text-xs capitalize text-gray-400">{notificationType}</div>
      </div>
    </div>
  );
};
