import { useQuery } from "@tanstack/react-query";
import type { Agent } from "@/types/agent";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";

export const agentsKeys = {
  all: ["agents"] as const,
};

const getAgents = async () => {
  const { data } = await restClient.get<Agent[]>(apiPaths.getAgents);
  return data;
};

export const useGetAgents = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const query = useQuery({
    queryKey: agentsKeys.all,
    queryFn: getAgents,
    enabled,
  });

  return query;
};
