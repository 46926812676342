import type { Subscription } from "./subscriptions";

export type User = {
  _id: string;
  name: string;
  email: string;
  apiKey: string;
  agxKey: string;
  resetPwdToken: null;
  subscription: Subscription;
  roles: string;
  contacts: string[];
  blocked: string[];
  blockedFrom: string[];
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  lastName: string;
  avatar: string;
  token: string;
  provider: string;
  birth: Date;
  customer: string;
  status: UserStatus;
  externalIntegrations: ExternalIntegrations;
};

export interface ExternalIntegrations {
  google_drive?: Record<string, never>;
  gmail?: [
    {
      email: string;
    },
  ];
  google_calendar?: [
    {
      calendarId: string;
      calendarSummary: string;
      email: string;
    },
  ];
}

export enum UserStatus {
  ACTIVE = 0,
  INACTIVE = 1,
  SUSPENDED = 2,
  INVITE_PENDING = 3,
}
