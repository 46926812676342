import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";
import { useLikeAgent } from "@/data/mutations/useLikeAgent";
import AgentTileWrapper from "./components/AgentTileWrapper";
import AgentTileCreatorInfo from "./components/AgentTileCreatorInfo";
import type { User } from "@/types/user";
import { memo } from "react";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { LocalStorageHelpers } from "@/utils/LocalStorageHelpers";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";

type AgentTileProps = {
  agent: {
    _id: string;
    name: string;
    description?: string;
    avatar: string;
    creatorUser?: {
      _id: User["_id"];
      name: User["name"];
      avatar: User["avatar"];
      email: User["email"];
    };
    likedByUser?: boolean;
    likes?: number;
    publishedToCommunity: boolean;
  };
  renderOptions?: () => JSX.Element;
  hideLikesCount?: true;
};

const _AgentTile = ({ agent, renderOptions, hideLikesCount }: AgentTileProps) => {
  const { isGuestUser } = useIsGuestUser();
  const { showLoginDialog } = useLoginDialogContext();
  const navigate = useNavigate();
  const { mutate } = useLikeAgent();

  const likeAgent = () => {
    mutate({ agentId: agent._id, status: !agent.likedByUser, profileId: agent.creatorUser?._id || "" });
  };

  const onTileClick = () => {
    const lastConversationId = LocalStorageHelpers.getAgentsLocalStorageConversation([agent._id]);

    const newRoute = lastConversationId
      ? ROUTES.conversation(lastConversationId)
      : getNewConversationRoute([agent._id]);

    navigate(newRoute);
  };

  const getBottomInfo = () => (
    <AgentTileCreatorInfo
      agent={{
        creatorUser: agent.creatorUser,
        likedByUser: agent.likedByUser,
        likes: agent.likes,
      }}
      onLikeAgentClick={() => {
        if (isGuestUser) {
          showLoginDialog();
          return;
        }
        likeAgent();
      }}
      hideLikesCount={hideLikesCount}
      seeCreatorProfileDisabled={isGuestUser}
    />
  );

  return (
    <AgentTileWrapper
      onClick={onTileClick}
      agent={{
        name: agent.name,
        avatar: agent?.avatar,
        description: agent.description,
      }}
      renderBottomInfo={getBottomInfo}
    >
      {renderOptions?.()}
    </AgentTileWrapper>
  );
};

export const AgentTile = memo(_AgentTile);
