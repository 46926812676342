import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import type { F1 } from "@/types/types";
import UnsavedChanges from "@/assets/images/UnsavedChanges.svg";

type UnsavedChangesDialogProps = {
  open: boolean;
  setOpen: F1<boolean>;
  onSave: VoidFunction;
  onDiscard: VoidFunction;
  isLoading: boolean;
};

export const UnsavedChangesDialog = ({ open, setOpen, onSave, onDiscard, isLoading }: UnsavedChangesDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent variant="medium">
        <DialogHeader className="font-bold">Unsaved changes</DialogHeader>
        <DialogDescription asChild className="flex flex-col items-center justify-center gap-2.5">
          <div>
            <img src={UnsavedChanges} alt="Folder with files" className="w-[120px]" />
            <p className="text-center text-xl font-bold">
              You have unsaved changes. Are you sure you want to leave this page?
            </p>
          </div>
        </DialogDescription>
        <DialogFooter>
          <Button size="medium" variant="primary" onClick={onSave} loading={isLoading}>
            Save & continue
          </Button>
          <DialogClose asChild>
            <Button size="medium" variant="danger" onClick={onDiscard}>
              Discard changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
