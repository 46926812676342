import { useEffect, useRef, useState } from "react";
import { Popover, PopoverAnchor, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

type BarChartContainerProps = {
  children: ({ barWidth, labelInterval }: { barWidth: number; labelInterval: number }) => React.ReactNode;
  itemsLength: number;
};

export const BarChartContainer = ({ children, itemsLength }: BarChartContainerProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setParentWidth(ref.current ? ref.current.offsetWidth : 0);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const barWidth = parentWidth / itemsLength;
  const labelWidth = 80;
  const maxLabels = Math.floor(parentWidth / labelWidth);
  const labelInterval = Math.ceil(itemsLength / maxLabels);

  return (
    <div ref={ref} className="relative w-full grow">
      <div className="absolute left-0 top-0 flex h-full w-full flex-col justify-between pb-16">
        <div className="z-0 h-[1px] w-full bg-neutral-200" />
        <div className="z-0 h-[1px] w-full bg-neutral-200" />
        <div className="z-0 h-[1px] w-full bg-neutral-200" />
        <div className="z-0 h-[1px] w-full bg-neutral-200" />
        <div className="z-0 h-[1px] w-full bg-neutral-200" />
        <div className="z-0 h-[1px] w-full bg-neutral-200" />
        <div className="z-0 h-[1px] w-full bg-neutral-200" />
      </div>

      <div className="relative flex h-full justify-around overflow-x-scroll pb-16">
        {children({ barWidth, labelInterval })}
      </div>
    </div>
  );
};

type BarChartBarItemProps = {
  maxValue: number;
  data: {
    value?: number;
    color: string;
    colorTransparent: string;
  }[];
  barStyle?: React.CSSProperties;
  renderLabel: () => React.ReactNode;
  renderTooltip: () => React.ReactNode;
};

export const BarChartBarItem = ({ maxValue, data, barStyle, renderLabel, renderTooltip }: BarChartBarItemProps) => {
  return (
    <Popover>
      <PopoverTrigger className="relative" style={barStyle}>
        {data
          .filter(item => item?.value !== undefined)
          .map(item => {
            const height = (item.value! / maxValue) * 100;

            const zIndexes = Array.from({ length: data.length }, (_, i) => i + 10);

            const valuesSorted = data.map(item => (item.value! / maxValue) * 100).sort((a, b) => b - a);

            const findZIndex = (value: number) => {
              return zIndexes[valuesSorted.indexOf(value)];
            };

            return (
              <div
                key={item.color}
                className="absolute bottom-0 w-full rounded-t-full"
                style={{
                  height: `${height}%`,
                  zIndex: findZIndex(height),
                  background: `linear-gradient(180deg, ${item.color} 0%, ${item.colorTransparent}`,
                }}
              />
            );
          })}

        <PopoverAnchor />

        {renderLabel()}
      </PopoverTrigger>

      <PopoverContent className="flex flex-col gap-1 rounded-xl bg-black bg-opacity-75 px-3 py-2.5">
        {renderTooltip()}
      </PopoverContent>
    </Popover>
  );
};

type BarChartTooltipProps = {
  renderTitle: () => React.ReactNode;
  data: {
    id: string;
    value?: number;
    label: string;
    color: string;
    colorTransparent: string;
  }[];
  renderFooter?: () => React.ReactNode;
};

export const BarChartTooltip = ({ renderTitle, data, renderFooter }: BarChartTooltipProps) => {
  return (
    <>
      {renderTitle()}

      {data.map(item => {
        return (
          <div key={item.id} className="flex items-center gap-[2px] rounded-full border border-white p-1.5">
            <div
              className="h-5 w-9 rounded-full"
              style={{
                background: `linear-gradient(-90deg, ${item.color} 0%, ${item.colorTransparent}`,
              }}
            />
            <p className="text-xs font-medium text-white">{`${item.label}: ${item.value}`}</p>
          </div>
        );
      })}

      {renderFooter && renderFooter()}
    </>
  );
};
