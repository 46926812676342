import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError, AxiosResponse } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { toast } from "react-toastify";
import { customerBillingInfoKeys } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";
import type { TaxId } from "@/types/subscriptions";

export const updatePaymentMethodMutationKeys = {
  all: ["updatePaymentMethod"],
} as const;

type Body = {
  paymentMethodId: string;
  tax?: TaxId;
};
const updatePaymentMethod = async (payload: Body) => {
  const { data } = await restClient.post<unknown, AxiosResponse<unknown>, Body>(apiPaths.updatePaymentMethod, payload);
  return data;
};

export const useUpdatePaymentMethod = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError<{ message: string }>, Body>({
    mutationFn: updatePaymentMethod,
    mutationKey: updatePaymentMethodMutationKeys.all,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: customerBillingInfoKeys.all });
      toast.success("Billing information updated successfully");
    },
  });
};
