import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import type { StatsSingleAgentPerformanceDataType } from "@/types/stats";
import StatsAllDataIcon from "../common/StatsAllDataIcon";
import { SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP } from "../../constants/statsConstants";
import StatsDataTypeIcon from "../common/StatsDataTypeIcon";

type Props = {
  onChangeSelectedDataType: (value: StatsSingleAgentPerformanceDataType | null) => void;
  triggerIcon: React.ReactNode;
  triggerTitle: string;
};

const StatsSingleAgentPerformanceDataTypePicker = ({ onChangeSelectedDataType, triggerIcon, triggerTitle }: Props) => {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger className="flex items-center gap-1 rounded-lg px-3 py-1 text-sm shadow-sm">
        {triggerIcon}
        {triggerTitle}
        <Icons.ChevronRight className="h-4 w-4 rotate-90" />
      </DropdownMenuTrigger>

      <DropdownMenuContent
        onClick={e => e.stopPropagation()}
        align="end"
        className="flex max-w-52 flex-col gap-1 border p-2.5"
      >
        <DropdownMenuItem
          className="flex cursor-pointer items-center gap-1 rounded text-neutral-500"
          onClick={() => onChangeSelectedDataType(null)}
        >
          <div className="flex w-5 items-center justify-center">
            <StatsAllDataIcon />
          </div>
          All data
        </DropdownMenuItem>
        {Object.entries(SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP).map(([key, value]) => {
          return (
            <DropdownMenuItem
              key={key}
              className="flex cursor-pointer items-center gap-1 rounded text-neutral-500"
              onClick={() => onChangeSelectedDataType(key as keyof typeof SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP)}
            >
              <div className="flex w-5 items-center justify-center">
                <StatsDataTypeIcon selectedDataType={key as keyof typeof SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP} />
              </div>
              {value.label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default StatsSingleAgentPerformanceDataTypePicker;
