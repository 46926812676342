import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { useUpdatePassword } from "@/data/mutations/useUpdatePassword";
import { useForm } from "react-hook-form";
import { FormInput } from "@/components/ui/FormInput";
import { passwordRegex } from "@/constants/regexps";
import { useLoginUser } from "@/data/mutations/useLoginUser";
import { toast } from "react-toastify";
import { Button } from "@/components/ui/button";
import { APP_LINKS } from "@/constants/appLinks";

export const UpdatePassword = () => {
  const { mutate, isPending, isSuccess, error } = useUpdatePassword();
  const { mutate: loginMutate } = useLoginUser();
  const searchParams = new URLSearchParams(window.location.search);
  const resetToken = searchParams.get("token");
  const email = searchParams.get("email");
  const navigate = useNavigate();
  const form = useForm({ defaultValues: { password: "", passwordRepeat: "" } });
  const { handleSubmit, setError } = form;

  if (!resetToken) {
    return <div>No token available</div>;
  }
  if (!email) {
    return <div>No email available</div>;
  }

  const updatePassword = (formData: { password: string; passwordRepeat: string }) => {
    const { password, passwordRepeat } = formData;
    if (password !== passwordRepeat) {
      setError("passwordRepeat", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }
    mutate(
      { password, resetToken },
      {
        onSuccess: () => {
          loginMutate(
            { email, password },
            {
              onSuccess: () => {
                navigate(ROUTES.workspace, { replace: true });
                toast.success("You have been logged in successfully!");
              },
            }
          );
        },
      }
    );
  };

  if (isSuccess) {
    return (
      <div className="grid place-items-center gap-5">
        <p className="mb-10 flex items-center text-2xl font-bold lg:text-[28px]">Your password has been updated!</p>
        <Button variant="primary" loading={isPending} className="w-full" onClick={() => navigate(ROUTES.login)}>
          Back to Login
        </Button>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-6 xl:gap-10">
      <h2 className="text-center text-2xl font-bold leading-9 tracking-tight">Set up Your Password</h2>

      {email && <div className="flex items-center justify-center text-center text-xs font-bold">{email}</div>}

      <form className="space-y-4" onSubmit={handleSubmit(updatePassword)}>
        <FormInput
          formObj={form}
          placeholder="Password"
          name="password"
          inputClassName="h-[38px] text-xs"
          required
          showRevealPasswordButton
          rules={{
            required: "Password is required",
            pattern: {
              value: passwordRegex,
              message:
                "Password must be at least 8 characters long and contains at least one digit and one uppercase letter.",
            },
            minLength: {
              value: 8,
              message:
                "Password must be at least 8 characters long and contains at least one digit and one uppercase letter.",
            },
          }}
        />

        <FormInput
          formObj={form}
          placeholder="Repeat password"
          name="passwordRepeat"
          inputClassName="h-[38px] text-xs"
          showRevealPasswordButton
          required
          rules={{
            required: "Please repeat your password",
            pattern: {
              value: passwordRegex,
              message:
                "Password must be at least 8 characters long and contains at least one digit and one uppercase letter.",
            },
            minLength: {
              value: 8,
              message:
                "Password must be at least 8 characters long and contains at least one digit and one uppercase letter.",
            },
          }}
        />

        {error && (
          <div className="flex flex-col gap-2 py-4 text-center text-xs font-medium leading-5 text-red-600">
            <div>Something went wrong!</div>
            {error.response?.data.message && <div>{error.response?.data.message}</div>}
          </div>
        )}

        <Button type="submit" size="medium" loading={isPending} className="w-full">
          Update password
        </Button>
      </form>

      <p className="text-left text-xs font-medium text-neutral-400">
        By clicking “Continue”, I agree to AgentX{" "}
        <a
          href={APP_LINKS.termsOfService}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary-400 underline underline-offset-4"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href={APP_LINKS.privacy}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary-400 underline underline-offset-4"
        >
          Privacy Policy
        </a>
        . This site is protected by reCAPTCHA.
      </p>
    </div>
  );
};
