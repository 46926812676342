import { Icons } from "@/components/ui/icons";
import { LinkButton } from "@/components/ui/link-button";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import { CHANNELS_LEGEND_DATA } from "@/pages/Stats/constants/statsConstants";
import { statsEmptyDoughnutChartData } from "@/pages/Stats/constants/statsMockData";
import { getStatsAllowedChannels } from "@/pages/Stats/utils/getStatsAllowedChannels";
import { getStatsChannelPercentageValue } from "@/pages/Stats/utils/getStatsChannelPercentageValue";
import type { Agent } from "@/types/agent";
import type { Origin } from "@/types/origin";
import type { StatsDougnhnutChartData } from "@/types/stats";
import { Doughnut } from "react-chartjs-2";

type Props = {
  channels: {
    channel: Origin;
    value: number;
  }[];
  agent: Agent;
};

export const StatsSidePanelChannelPerformance = ({ channels, agent }: Props) => {
  const { setChatHistoryDialogProps } = useStatsContext();

  const allowedChannels = getStatsAllowedChannels({ providedChannels: channels });
  const isChannelsDataEmpty = allowedChannels.every(channel => channel.value === 0) || allowedChannels.length === 0;

  const chartData: StatsDougnhnutChartData = isChannelsDataEmpty
    ? statsEmptyDoughnutChartData
    : {
        datasets: [
          {
            data: allowedChannels.map(channel => channel.value),
            backgroundColor: allowedChannels.map(channel => {
              return CHANNELS_LEGEND_DATA.find(item => item.channel === channel.channel)?.color;
            }),
          },
        ],
      };

  const getPercentageValue = (channel: Origin) => {
    return getStatsChannelPercentageValue({
      channel,
      allChannels: channels,
    });
  };

  return (
    <div className="mt-6">
      <h2 className="text-left text-xl font-bold text-neutral-750">Channel performance</h2>
      <div className="mt-4 flex flex-col items-center justify-between gap-4 @xl/main:flex-row">
        <div className="flex w-full max-w-64 items-center justify-center @xl/main:justify-start">
          <Doughnut
            data={chartData}
            options={{
              maintainAspectRatio: true,
              aspectRatio: 1,
              responsive: true,
              cutout: "60%",
              layout: {
                padding: 0,
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              elements: {
                arc: {
                  borderWidth: 0,
                },
              },
            }}
            className="opacity-70 hover:opacity-100"
          />
        </div>

        <div>
          {CHANNELS_LEGEND_DATA.map(item => {
            return (
              <div
                key={item.channel}
                className="flex justify-between gap-4 border-b border-neutral-100 bg-transparent px-2 py-3 hover:bg-neutral-50"
              >
                <div className="flex items-center justify-between gap-2">
                  <div
                    className="h-2 w-2 rounded-full"
                    style={{
                      backgroundColor: item.color,
                    }}
                  />
                  <p className="text-sm font-medium leading-[22px] text-neutral-750">{item.label}</p>
                  <span className="text-sm font-medium leading-[22px] text-neutral-400">{`${getPercentageValue(item.channel)}%`}</span>
                </div>

                <LinkButton
                  size="md"
                  onClick={() => {
                    setChatHistoryDialogProps({
                      agent,
                      channel: item.channel,
                    });
                  }}
                  className="flex items-center justify-between gap-1 leading-[22px] text-primary-400"
                >
                  Conversations
                  <Icons.Arrow className="size-4 rotate-[135deg] text-primary-400" />
                </LinkButton>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
