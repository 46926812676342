import { DialogClose, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useGetCommunityCategories } from "@/data/queries/useGetCommunityCategories";
import { useEffect, useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { useGetSingleCommunityAgent } from "@/data/queries/useGetSingleCommunityAgent";
import { useUpdatePublishedCommunityAgent } from "@/data/mutations/useUpdatePublishedCommunityAgent";
import { AgentTile } from "../../../components/agentTiles/AgentTile/AgentTile";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import type { F1 } from "@/types/types";
import { Button } from "@/components/ui/button";

type UpdateAgentDetailsCommunityDialogProps = {
  agentId: string;
  setShowDialog: F1<boolean>;
};
export const UpdateAgentDetailsCommunityDialog = ({
  agentId,
  setShowDialog,
}: UpdateAgentDetailsCommunityDialogProps) => {
  const { data: categories } = useGetCommunityCategories();
  const { data: agent, isLoading } = useGetSingleCommunityAgent({ agentId });
  const { mutateAsync, isPending } = useUpdatePublishedCommunityAgent();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
  const [descriptionInput, setDescriptionInput] = useState("");

  const isPublishButtonEnabled = selectedCategoryId && descriptionInput;

  useEffect(() => {
    if (agent) {
      setSelectedCategoryId(agent?.categories[0]?._id);
      setDescriptionInput(agent.description || "");
    }
  }, [agent]);

  const updateAgent = async () => {
    await mutateAsync({
      agentId,
      categories: selectedCategoryId ? [selectedCategoryId] : [],
      description: descriptionInput,
      status: true,
    });
    setShowDialog(false);
  };

  return (
    <>
      <DialogDescription className="mt-[-10px] flex h-full flex-col-reverse gap-10 md:flex-row">
        <div className="flex h-full flex-col gap-4 pr-4 md:w-[60%]">
          <h4>Details</h4>

          <Input label="Agent name" name="agentName" disabled value={agent?.name} />

          <div className="flex flex-col gap-1.5">
            <div className="flex gap-1">
              <Label htmlFor="category">Category</Label>
              <HybridTooltipPopover heading="Set a category:">
                <p>Select the category that best describes your Agent </p>
              </HybridTooltipPopover>
            </div>
            <Select
              onValueChange={e => {
                setSelectedCategoryId(e);
              }}
              value={selectedCategoryId}
            >
              <SelectTrigger id="category" autoFocus className="w-full">
                <SelectValue placeholder="Pick a category for your Bot"></SelectValue>
              </SelectTrigger>
              <SelectContent>
                {categories?.map(category => (
                  <SelectItem key={category.title} value={category._id}>
                    {category.title}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className="flex gap-1">
              <Label htmlFor="updateAgentDescriptions">Agent Descriptions</Label>
              <HybridTooltipPopover heading="Describe Your Bot:">
                <p>
                  Provide a brief yet impactful description of your Agent, highlighting its key features and unique
                  capabilities. This is your chance to showcase its personality and functionality in just a few words
                </p>
              </HybridTooltipPopover>
            </div>
            <p className="text-xs text-neutral-400">
              The description determines the chance to be discovered. Make it detail and clear.
            </p>
            <Textarea
              id="updateAgentDescriptions"
              value={descriptionInput}
              onChange={e => setDescriptionInput(e.target.value)}
              placeholder="For example: 'Discover a world of knowledge with our Educational Bot, a dynamic AI tutor designed to make learning interactive, personalized, and accessible anytime, anywhere. This AI agent can ...'"
            />
          </div>
        </div>

        <div className="border-neutral-200 md:w-[40%] md:border-l md:p-4">
          <h4 className="w-full">Preview of Community Page</h4>
          <div className="relative mt-5 w-full flex-1 md:ml-7 md:mt-12">
            {agent && <AgentTile agent={{ ...agent, description: descriptionInput }} hideLikesCount />}
            {isLoading && <LoadingSpinner className="flex flex-1 items-center justify-center" />}
          </div>
        </div>
      </DialogDescription>

      <DialogFooter>
        <DialogClose asChild>
          <Button variant="tertiary" size="medium">
            Cancel
          </Button>
        </DialogClose>
        <Button size="medium" onClick={updateAgent} disabled={!isPublishButtonEnabled} loading={isPending}>
          Update
        </Button>
      </DialogFooter>
    </>
  );
};
