import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import type { User } from "@/types/user";

type AgentOwnerSelectProps = {
  options: User[] | undefined;
  agentCreatorId: string;
  value: string | undefined;
  onValueChange: (value: string) => void;
};

export const AgentOwnerSelect = ({ options, agentCreatorId, value, onValueChange }: AgentOwnerSelectProps) => {
  return (
    <Select onValueChange={onValueChange} value={value}>
      <SelectTrigger id="numOfMessages" className="w-full">
        <SelectValue placeholder="Select new owner"></SelectValue>
      </SelectTrigger>
      <SelectContent>
        {options?.map(option => (
          <SelectItem key={option._id} value={option._id} disabled={agentCreatorId === option._id}>
            {option.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
