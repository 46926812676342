import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { UpdateAgentDetailsCommunityDialog } from "../../../pages/Community/components/UpdateAgentDetailsCommunityDialog";
import type { CommunityAgent } from "@/types/community";
import type { F1 } from "@/types/types";

type EditPublishedAgentDialogProps = {
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
  agentId: CommunityAgent["_id"];
};

export const EditPublishedAgentDialog = ({ isDialogOpen, setShowDialog, agentId }: EditPublishedAgentDialogProps) => {
  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <DialogContent className="flex h-full flex-col">
        <DialogHeader>
          <DialogTitle>Publish community details</DialogTitle>
        </DialogHeader>
        <UpdateAgentDetailsCommunityDialog agentId={agentId || ""} setShowDialog={setShowDialog} />
      </DialogContent>
    </Dialog>
  );
};
