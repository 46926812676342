import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import { workspaceAgentsKeys } from "@/data/queries/workspace/useGetWorkspaceAgents";
import { agentsWithConfigKeys } from "@/data/queries/useGetAgentsWithConfig";
import { toast } from "react-toastify";

type Payload = {
  agentId: string;
  userId: string;
};

type Response = {
  message: string;
};
const changeAgentCreator = async (payload: Payload) => {
  const { data } = await restClient.post<Response>(apiPaths.changeAgentCreator, payload);
  return data;
};

export const useChangeAgentCreator = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError<{ message: string }>, Payload>({
    mutationFn: changeAgentCreator,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: workspaceAgentsKeys.all });
      await queryClient.invalidateQueries({ queryKey: agentsWithConfigKeys.all });
      toast.success("Agent creator changed successfully.");
    },
  });
};
