import { useParams } from "react-router-dom";
import { AgentForm } from "../../components/AgentForm/AgentForm";
import { useUpdateAgent } from "@/data/mutations/useUpdateAgent";
import { useUpdateLeadGenerationTool } from "@/data/mutations/useUpdateLeadGenerationTool";
import { useCreateLeadGenerationTool } from "@/data/mutations/useCreateLeadGenerationTool";
import { useGetSinglePrivateAgentWithConfig } from "@/data/queries/useGetSinglePrivateAgentWithConfig";
import { useGetAgentLeadGenTool } from "@/data/queries/useGetAgentLeadGenTool";
import { useGetAgentGoogleCalendarTool } from "@/data/queries/useGetAgentGoogleCalendarTool";
import { useAgentToasts } from "@/pages/EditAgent/hooks/useAgentToasts";
import { leadGenerationRequiredParams } from "@/constants/leadGenerationContants";
import { isEqual } from "lodash";
import { AgentState } from "@/types/agent";
import { useRefetchAgentOnUpdate } from "./hooks/useRefetchAgentOnUpdate";
import type { AgentFormOnSubmit } from "@/components/AgentForm/types/AgentFormTypes";
import { useCreateGoogleCalendarTool } from "@/data/mutations/useCreateGoogleCalendarTool";
import { useUpdateGoogleCalendarTool } from "@/data/mutations/useUpdateGoogleCalendarTool";

export const EditAgentPage = () => {
  const { id } = useParams<{ id?: string }>();
  const shouldFetchAgentData = !!id;

  const { mutate: updateAgent, isPending } = useUpdateAgent();
  const { mutate: createLeadGenTool, isPending: isCreatingLeadGenToolPending } = useCreateLeadGenerationTool();
  const { mutate: updateLeadGenTool, isPending: isUpdatingLeadGenToolPending } = useUpdateLeadGenerationTool();
  const { mutate: createGoogleCalendarTool } = useCreateGoogleCalendarTool();
  const { mutate: updateGoogleCalendarTool } = useUpdateGoogleCalendarTool();

  const {
    data: agentData,
    isPending: isPendingAgentWithConfig,
    isFetching: isFetchingAgentWithConfig,
    error,
  } = useGetSinglePrivateAgentWithConfig({ agentId: id || "", enabled: shouldFetchAgentData });

  const { data: leadGenToolData } = useGetAgentLeadGenTool({ agentId: id ?? "", enabled: shouldFetchAgentData });
  const { data: googleCalendarToolData } = useGetAgentGoogleCalendarTool({
    agentId: id ?? "",
    enabled: shouldFetchAgentData,
  });

  const handleUpdateAgent: AgentFormOnSubmit = ({
    formValues,
    onSetFormError,
    isLeadGenToolDirty,
    isGoogleCalendarToolDirty,
  }) => {
    if (!agentData) {
      return;
    }

    updateAgent(
      { newData: formValues, prevData: agentData },
      {
        onError: e => {
          if (e.response?.data.message === "This name is already taken. Please try with a different one") {
            onSetFormError("name", { message: "Agent with this name already exists" });
          }
        },
      }
    );

    if (isLeadGenToolDirty && formValues.leadGeneration) {
      const isLeadGenEnabled = formValues.leadGeneration.enabled;
      const leadGenPayloadParams = formValues.leadGeneration.params;

      const isExistingLeadGenTool = !!leadGenToolData;

      if (isExistingLeadGenTool) {
        const hasLeadGenToolChanged = !isEqual(formValues.leadGeneration, leadGenToolData);

        if (!hasLeadGenToolChanged) {
          return;
        }

        updateLeadGenTool({
          agentId: id ?? "",
          parameters: leadGenPayloadParams,
          disabled: !isLeadGenEnabled,
          webhook: formValues.leadGeneration.webhook,
          emailVendor: formValues.leadGeneration.emailVendor,
          disableEmail: formValues.leadGeneration.disableEmail,
        });
      } else if (formValues.leadGeneration.enabled || formValues.leadGeneration.params.length) {
        createLeadGenTool({
          agentId: id ?? "",
          parameters: leadGenPayloadParams,
          disabled: !isLeadGenEnabled,
          webhook: formValues.leadGeneration.webhook,
        });
      }
    }
    if (isGoogleCalendarToolDirty && formValues.googleCalendar && id) {
      const isGoogleCalendarEnabled = formValues.googleCalendar.enabled;

      const isExistingGoogleCalendarTool = !!googleCalendarToolData;

      if (isExistingGoogleCalendarTool) {
        const hasGoogleCalendarToolChanged = !isEqual(formValues.googleCalendar, googleCalendarToolData);

        if (!hasGoogleCalendarToolChanged) {
          return;
        }

        updateGoogleCalendarTool({
          agentId: id,
          disabled: !isGoogleCalendarEnabled,
          calendarId: formValues.googleCalendar.calendar.calendarId,
          calendarEmail: formValues.googleCalendar.calendar.email,
        });
      } else if (formValues.googleCalendar.enabled) {
        createGoogleCalendarTool({
          agentId: id,
          calendarId: formValues.googleCalendar.calendar.calendarId,
          calendarEmail: formValues.googleCalendar.calendar.email,
        });
      }
    }
  };

  const isLoading =
    isPending ||
    isCreatingLeadGenToolPending ||
    isUpdatingLeadGenToolPending ||
    agentData?.state === AgentState.AGENT_UPDATING ||
    agentData?.state === AgentState.AGENT_CREATING;

  useAgentToasts({ agentData });
  useRefetchAgentOnUpdate({ agentId: id, agentState: agentData?.state });

  return (
    <AgentForm
      key={`${agentData?._id}-${agentData?.updatedAt}-${leadGenToolData?._id}`}
      defaultFormValues={
        agentData
          ? {
              ...agentData,
              leadGeneration: {
                enabled: !!leadGenToolData && !leadGenToolData.disabled,
                params: leadGenToolData?.parameters ?? leadGenerationRequiredParams,
                webhook: leadGenToolData?.webhook ?? {
                  enabled: false,
                  url: "",
                  header: [""],
                  method: "POST",
                },
                emailVendor: leadGenToolData?.emailVendor ?? "",
                disableEmail: leadGenToolData?.disableEmail ?? false,
              },
              googleCalendar: googleCalendarToolData
                ? {
                    enabled: !googleCalendarToolData.disabled,
                    calendar: {
                      calendarId: googleCalendarToolData.calendarId,
                      email: googleCalendarToolData.calendarEmail,
                    },
                  }
                : {
                    enabled: false,
                    calendar: {
                      calendarId: "",
                      email: "",
                    },
                  },
            }
          : undefined
      }
      agentData={agentData}
      onSubmitForm={handleUpdateAgent}
      disableFormNavigation={isLoading}
      submitBtn={{
        variant: "secondary",
        text: "Save changes",
        disabled: isPending || isCreatingLeadGenToolPending || isUpdatingLeadGenToolPending,
        loading: isPending || isCreatingLeadGenToolPending || isUpdatingLeadGenToolPending,
      }}
      isChatError={!!error}
      isLoadingChat={isFetchingAgentWithConfig}
      isLoadingFormContent={isPendingAgentWithConfig && !!id}
    />
  );
};
