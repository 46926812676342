import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import type { F1 } from "@/types/types";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { useGetUser } from "@/data/queries/useGetUser";
import { Icons } from "@/components/ui/icons";
import type { SubscriptionTier, UsageExceededType } from "@/types/subscriptions";
import { SubscriptionTiers } from "@/types/subscriptions";
import { AccountUsageMessage } from "./components/AccountUsageMessage";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { AccountUsageStartupCard } from "./components/AccountUsageStartupCard";
import { AccountUsageProCard } from "./components/AccountUsageProCard";
import { AccountUsageScaleCard } from "./components/AccountUsageScaleCard";
import { Carousel, CarouselContent, CarouselDots, CarouselItem } from "@/components/ui/carousel";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import AutoHeight from "embla-carousel-auto-height";
import { AccountUsageEnterpriseCard } from "./components/AccountUsageEnterpriseCard";
import { Button } from "@/components/ui/button";
import { LinkButton } from "@/components/ui/link-button";

type AccountUsageDialogProps = {
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
  type: UsageExceededType;
};

export const AccountUsageDialog = ({ isDialogOpen, setShowDialog, type }: AccountUsageDialogProps) => {
  const { openDialog: openSubscriptionTierDialog } = useSubscriptionTierDialogContext();
  const { user } = useGetUser();
  const isMobile = !useTailwindMediaQuery("sm");

  const handleOpenSubscriptionTierDialog = () => {
    openSubscriptionTierDialog();
    setShowDialog(false);
  };

  const shouldShowCard = (cardTier: SubscriptionTier, exceededType: UsageExceededType) => {
    if (exceededType === "stats") {
      return [SubscriptionTiers.PRO, SubscriptionTiers.ENTERPRISE].includes(cardTier);
    }
    // add more types conditions if needed
    return true;
  };

  const getPricingCards = (tier: SubscriptionTier | undefined, exceededType: UsageExceededType) => {
    if (tier === SubscriptionTiers.ENTERPRISE || tier === SubscriptionTiers.PRO) {
      return <AccountUsageEnterpriseCard onCloseDialog={() => setShowDialog(false)} />;
    } else if (tier === SubscriptionTiers.FREE) {
      const allowedCards = [
        shouldShowCard(SubscriptionTiers.STARTUP, exceededType) && <AccountUsageStartupCard />,
        shouldShowCard(SubscriptionTiers.PRO, exceededType) && <AccountUsageProCard />,
        shouldShowCard(SubscriptionTiers.ENTERPRISE, exceededType) && <AccountUsageScaleCard />,
      ].filter(Boolean);

      if (allowedCards.length === 0) {
        return (
          <div className="mb-9 flex w-full items-center justify-center">
            <p className="text-center text-sm text-neutral-400">No upgrade options available for this feature.</p>
          </div>
        );
      }

      if (isMobile) {
        return (
          <Carousel
            className="h-fit"
            opts={{
              startIndex: 1,
            }}
            plugins={[AutoHeight()]}
          >
            <CarouselContent className="my-9">
              {allowedCards.map((card, index) => (
                <CarouselItem key={index} containerClassName="h-full basis-full">
                  {card}
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselDots className="-bottom-2" />
          </Carousel>
        );
      }

      return <div className="flex gap-4">{allowedCards}</div>;
    } else if (tier === SubscriptionTiers.STARTUP) {
      const allowedCards = [
        shouldShowCard(SubscriptionTiers.PRO, exceededType) && <AccountUsageProCard />,
        shouldShowCard(SubscriptionTiers.ENTERPRISE, exceededType) && <AccountUsageScaleCard />,
      ].filter(Boolean);

      if (allowedCards.length === 0) {
        return (
          <div className="mb-9 flex w-full items-center justify-center">
            <p className="text-center text-sm text-neutral-400">No upgrade options available for this feature.</p>
          </div>
        );
      }

      return <div className="flex gap-4">{allowedCards}</div>;
    }

    return (
      <div className="my-9 flex w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  };
  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <DialogContent className="h-dvh w-full overflow-y-scroll md:h-fit md:w-fit md:min-w-[560px]" variant="custom">
        <DialogHeader></DialogHeader>
        <div className="flex h-[100vh] w-full flex-1 flex-col items-center gap-2.5 px-8 py-4">
          <AccountUsageMessage
            limitType={type}
            limits={
              user
                ? {
                    message: user.subscription.messages.limit,
                    url: user.subscription.knowledge.urls.limit,
                    document: user.subscription.knowledge.docs.limit,
                    faqs: user.subscription.knowledge.faqs.limit,
                  }
                : undefined
            }
          />
          <div className="mt-4 w-full">
            {getPricingCards(user?.subscription.tier, type)}

            <div className="mt-6 flex w-full lg:mt-2">
              <LinkButton className="pl-0 text-primary-400" onClick={handleOpenSubscriptionTierDialog}>
                See plans and price details <Icons.ChevronRight />
              </LinkButton>
            </div>
          </div>
        </div>
        <DialogFooter className="static">
          <DialogClose asChild>
            <Button variant="ghost" size="medium" className="pr-0 text-neutral-400">
              Continue with current plan
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
