import { NotificationDot } from "@/components/Notifications/NotificationDot";
import { Avatar } from "@/components/Avatar";
import { AvatarFallback } from "@/components/ui/avatar";
import { Icons } from "@/components/ui/icons";

interface NotificationAvatarProps {
  isRead: boolean;
  imageSrc?: string | null;
  isPersonNotification?: boolean;
}

export const NotificationAvatar = ({ isRead, imageSrc, isPersonNotification }: NotificationAvatarProps) => {
  return (
    <>
      <div className="relative h-[40px] w-[10px] items-center p-2 text-center">
        <NotificationDot active={!isRead} className="left-0 top-0" />
      </div>
      <Avatar
        className="bg-primary-50 text-primary-400"
        src={imageSrc || undefined}
        element={
          imageSrc ? undefined : (
            <AvatarFallback className="flex size-full items-center justify-center bg-primary-50 p-[20%] text-primary-400">
              {isPersonNotification ? <Icons.User /> : <Icons.Settings />}
            </AvatarFallback>
          )
        }
      />
    </>
  );
};
