import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useAcknowledgeNotifications } from "@/data/mutations/useAcknowledgeNotifications";
import type { GroupedNotification } from "@/components/Notifications/utils/groupAndSortNotifications";
import { NotificationGroupedDescription } from "@/components/Notifications/GroupedNotification/NotificationGroupedDescription";
import { NotificationGroupedAvatars } from "@/components/Notifications/GroupedNotification/NotificationGroupedAvatars";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";

type NotificationGroupedItemProps = {
  groupedNotification: GroupedNotification;
};

export const NotificationGroupedItem = ({ groupedNotification }: NotificationGroupedItemProps) => {
  const [showReadButton, setShowReadButton] = useState(false);
  const { mutate: acknowledgeNotifications, isPending } = useAcknowledgeNotifications();
  const { isMobile } = useMediaQueriesContext();

  const isReadButtonVisible = (isMobile || showReadButton) && !groupedNotification.read;

  const markAsRead = () => {
    acknowledgeNotifications({ ids: groupedNotification.notifications.map(notification => notification._id) });
  };

  return (
    <div
      className="flex items-center justify-between gap-2 border-b border-gray-200 py-4"
      onMouseOver={() => !groupedNotification.read && setShowReadButton(true)}
      onMouseOut={() => !groupedNotification.read && setShowReadButton(false)}
    >
      <div className="flex items-center gap-2">
        <NotificationGroupedAvatars groupedNotification={groupedNotification} />
        <NotificationGroupedDescription groupedNotification={groupedNotification} />
      </div>
      {isReadButtonVisible && (
        <Button loading={isPending} onClick={markAsRead}>
          Read
        </Button>
      )}
    </div>
  );
};
