import { BillingPaymentMethod } from "./BillingPaymentMethod";
import { BillingPlan } from "./BillingPlan";
import { useGetCustomerBillingInfo } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { BillingInvoices } from "./BillingInvoices";
import { useState } from "react";

export const Billing = () => {
  const { data: billingInfo, isLoading } = useGetCustomerBillingInfo();
  const [isChangePaymentDialogOpen, setShowPaymentDialog] = useState(false);

  return (
    <div className="space-y-6 divide-y">
      <div className="text-lg font-bold">Billing & Payment</div>
      {isLoading ? (
        <LoadingSpinner className="flex justify-center pt-10" />
      ) : (
        <>
          <BillingPlan billingInfo={billingInfo} />
          <BillingPaymentMethod
            isChangePaymentDialogOpen={isChangePaymentDialogOpen}
            setShowPaymentDialog={setShowPaymentDialog}
            billingInfo={billingInfo}
          />
          <BillingInvoices invoices={billingInfo?.invoices} />
        </>
      )}
    </div>
  );
};
