const SEC_IN_MS = 1000;
const MIN_IN_MS = 60 * SEC_IN_MS;
const HOUR_IN_MS = 60 * MIN_IN_MS;
const DAY_IN_MS = 24 * HOUR_IN_MS;

export const calculateNotificationsTimeDifference = (isoDate: Date): string => {
  const targetDate = new Date(isoDate);
  const currentDate = new Date();

  const differenceInMillis = currentDate.getTime() - targetDate.getTime();

  if (Math.abs(differenceInMillis) < 1000 * 60) {
    const differenceInSeconds = Math.round(differenceInMillis / SEC_IN_MS);
    return `${differenceInSeconds}s`;
  }

  if (Math.abs(differenceInMillis) < 1000 * 60 * 60) {
    const differenceInMinutes = Math.round(differenceInMillis / MIN_IN_MS);
    return `${differenceInMinutes}m`;
  }

  if (Math.abs(differenceInMillis) < 1000 * 60 * 60 * 24) {
    const differenceInHours = Math.round(differenceInMillis / HOUR_IN_MS);
    return `${differenceInHours}h`;
  }
  const currentDateWithoutTime = new Date(currentDate);
  currentDateWithoutTime.setHours(0, 0, 0, 0);

  const targetDateWithoutTime = new Date(targetDate);
  targetDateWithoutTime.setHours(0, 0, 0, 0);

  const dayDifferenceInMillis = currentDateWithoutTime.getTime() - targetDateWithoutTime.getTime();
  const differenceInDays = Math.round(dayDifferenceInMillis / DAY_IN_MS);

  return `${differenceInDays}d`;
};
