import type { StatsSingleAgentPerformanceDataType } from "@/types/stats";
import { SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP } from "../../constants/statsConstants";

type Props = {
  selectedDataType: StatsSingleAgentPerformanceDataType | null;
};

const StatsSingleAgentPerformanceLegend = ({ selectedDataType }: Props) => {
  return (
    <div className="my-6 flex w-full flex-wrap items-center gap-x-8 gap-y-2 @container">
      {Object.keys(SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP)
        .filter(key => {
          if (!selectedDataType) {
            return true;
          }

          return key === selectedDataType;
        })
        .map(key => {
          const { color, label } =
            SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP[key as keyof typeof SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP];

          return (
            <div key={key} className="flex items-center gap-2">
              <div
                className="h-4 w-4 rounded-full"
                style={{
                  backgroundColor: color,
                }}
              />

              <p className="text-xs @3xl:text-base">{label}</p>
            </div>
          );
        })}
    </div>
  );
};

export default StatsSingleAgentPerformanceLegend;
