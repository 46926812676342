import { Avatar } from "@/components/Avatar";
import { Icons } from "@/components/ui/icons";
import type { FullAgent } from "@/types/agent";
import { useGetUser } from "@/data/queries/useGetUser";

type AgentInfoProps = {
  agent: FullAgent | null;
};

export const AgentInfo = ({ agent }: AgentInfoProps) => {
  const { user } = useGetUser();
  const isMyAgent = user?._id === agent?.creator;
  const generateAgentAuthorName = () => (isMyAgent ? "you" : agent?.company);

  return (
    <div className="flex gap-2 rounded-md bg-primary-50 px-1.5 py-3.5">
      <div className="relative">
        <Avatar size="m" src={agent?.avatar} name={agent?.name} />
      </div>
      <div className="flex flex-col gap-1 text-start">
        <h6 className="text-left font-bold leading-5 text-neutral-750 line-clamp-2-wrap">{agent?.name}</h6>
        <div className="flex w-[130px] items-center justify-between gap-1">
          {!isMyAgent && <Icons.Team className="size-4 text-primary-400" />}
          <span className="whitespace-nowrap text-xs font-medium text-gray-400">Created by</span>
          <span className="w-[70px] overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium text-gray-400">
            {generateAgentAuthorName()}
          </span>
        </div>
      </div>
    </div>
  );
};
