import { Sheet, SheetContent } from "@/components/ui/sheet";
import type { EmbedKnowledges } from "@/types/agent";
import { allowedFileFormats } from "@/utils/fileUploads";
import useResizableWidth from "../hooks/useResizableWidth";
import { Icons } from "@/components/ui/icons";
import DocViewer, { MSDocRenderer, TXTRenderer } from "react-doc-viewer";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CSVRenderer from "../utils/CSVRenderer";

type DocumentInfoPreviewProps = {
  documentInPreview: EmbedKnowledges | null;
  setDocumentInPreview: React.Dispatch<React.SetStateAction<EmbedKnowledges | null>>;
  docPreviewPage?: number | null;
  setDocPreviewPage?: React.Dispatch<React.SetStateAction<number | null>>;
};

export const DocumentInfoPreview = ({
  setDocumentInPreview,
  documentInPreview,
  docPreviewPage,
  setDocPreviewPage,
}: DocumentInfoPreviewProps) => {
  const { width, handleMouseDown } = useResizableWidth({
    initialWidth: 600,
    minWidth: 350,
    maxWidth: 1500,
  });

  const handleTogglePreviewOpen = (open: boolean) => {
    if (!open) {
      setDocumentInPreview(null);
    }
    if (!open && setDocPreviewPage) {
      setDocPreviewPage(null);
    }
  };

  const fileFormat =
    documentInPreview?.source.slice(documentInPreview?.source.lastIndexOf("."))?.toLowerCase().trim() || "";
  const isImage = allowedFileFormats.some(el => el?.mimeType.startsWith("image") && el?.extension === fileFormat);
  const fileName = documentInPreview?.name ?? documentInPreview?.filename;

  const docViewerFileFormats = [".xls", ".xlsx", ".docx", ".doc", ".txt", ".csv"];

  return (
    <Sheet modal={false} onOpenChange={handleTogglePreviewOpen} open={!!documentInPreview}>
      <SheetContent
        closeButtonIcon={<Icons.Close className="mt-1 size-4" />}
        style={{ width: `${width}px` }}
        side="right"
        className="h-full bg-white p-0 sm:max-w-none"
        onInteractOutside={e => e.preventDefault()}
        hideOverlay
      >
        <div className="relative size-full overflow-auto p-6 pl-8">
          <div className="ml-8">
            <div className="mb-2 text-base font-bold">{fileName}</div>
            {documentInPreview?.source === "agentFunction" ? (
              <div>
                <div className="my-2 text-base font-bold">Function Argument</div>
                <code>{documentInPreview?.description}</code>
              </div>
            ) : (
              <p className="text-xs font-medium">{documentInPreview?.description}</p>
            )}
          </div>

          <div className="ml-8 mt-10 h-[85%]">
            {documentInPreview?.source && fileFormat === ".pdf" ? (
              <iframe
                src={`${documentInPreview.source}${
                  docPreviewPage ? "#page=".concat((docPreviewPage + 1).toString()) : ""
                }`}
                width="100%"
                height="100%"
              />
            ) : docViewerFileFormats.includes(fileFormat) && documentInPreview?.source ? (
              <DocViewer
                pluginRenderers={[MSDocRenderer, TXTRenderer, CSVRenderer]}
                className="h-5/6 w-full"
                documents={[
                  {
                    uri: documentInPreview.source || "",
                    fileType: fileFormat.substring(1),
                  },
                ]}
                config={{
                  header: {
                    disableHeader: true,
                  },
                }}
              />
            ) : isImage && documentInPreview?.source ? (
              <img src={documentInPreview.source} alt="preview" />
            ) : (
              <div>
                {!documentInPreview?.externalId && documentInPreview?.source !== "agentFunction" && (
                  <p className="overflow-auto text-xs text-neutral-500">
                    Document preview is available for image, PDF, XLS, XLSX, DOC, DOCX, TXT, and CSV file types only.
                  </p>
                )}
                {documentInPreview?.content && (
                  <div className="my-5">
                    <div className="my-2 text-base font-bold">
                      {documentInPreview?.source === "agentFunction" ? "Function Response" : "Content Retrieved"}
                    </div>
                    <p className="overflow-auto text-xs font-medium">
                      <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          table: ({ node, ...props }) => (
                            <table {...props} className="min-w-full divide-y divide-gray-200 border-2 border-solid" />
                          ),
                          th: ({ node, ...props }) => <th {...props} className="border-2 border-solid p-2" />,
                          td: ({ node, ...props }) => <th {...props} className="border-2 border-solid p-2" />,
                        }}
                      >
                        {documentInPreview?.content}
                      </Markdown>
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className="absolute left-1.5 top-1/2 -translate-y-1/2 cursor-ew-resize p-1.5"
            onPointerDown={handleMouseDown}
          >
            <div className="h-20 w-1.5 rounded-full bg-neutral-600"></div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
