import { Icons } from "@/components/ui/icons";
import type { UseFormReturn } from "react-hook-form";
import type { RegisterFormTypes, RegisterStep } from "@/types/user-register";
import type { Dispatch, FormEvent, SetStateAction } from "react";
import { ROUTES } from "@/constants/routes";
import { useNavigate } from "react-router";
import { APP_LINKS } from "@/constants/appLinks";
import { Input } from "@/components/ui/input";
import { get } from "lodash";
import { Button } from "@/components/ui/button";

type RegisterUserAndTermsProps = {
  form: UseFormReturn<RegisterFormTypes>;
  setRegisterStep: Dispatch<SetStateAction<RegisterStep>>;
  setIsGoogleRegister: Dispatch<SetStateAction<boolean>>;
};

export const RegisterUserAndTerms = ({ form, setRegisterStep, setIsGoogleRegister }: RegisterUserAndTermsProps) => {
  const navigate = useNavigate();
  const continueRegister = async (e: FormEvent) => {
    e.preventDefault();

    await form.trigger(["name", "email"]);

    if (form.formState.errors.name || form.formState.errors.email) {
      return;
    }

    setRegisterStep("ageVerificationProcess");
  };
  const continueGoogleRegister = () => {
    setIsGoogleRegister(true);
    setRegisterStep("ageVerificationProcess");
  };

  return (
    <>
      <h2 className="mb-6 text-center text-2xl font-bold leading-9 tracking-tight xl:mb-10 xl:text-start xl:text-[44px]">
        Sign up
      </h2>

      <div className="flex w-full flex-col gap-6 xl:gap-10">
        <Button
          className="w-full font-bold"
          variant="dark"
          size="medium"
          onClick={continueGoogleRegister}
          prefixIcon={<Icons.Google />}
        >
          Continue with Google
        </Button>

        <div className="flex flex-1 items-center justify-between gap-4">
          <Icons.Line />
          Or
          <Icons.Line />
        </div>

        <form onSubmit={continueRegister} className="w-full">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2 xl:gap-4">
              <Input
                register={form.register("name")}
                error={get(form.formState.errors, "name.message")}
                placeholder="Name"
                inputClassName="h-[38px] text-xs"
              />

              <Input
                register={form.register("email")}
                error={get(form.formState.errors, "email.message")}
                placeholder="Enter email address"
                inputClassName="h-[38px] text-xs"
              />
            </div>
            <div className="flex flex-col gap-4">
              <Button type="submit" size="medium" className="w-full font-bold">
                Continue with Email
              </Button>
              <div className="text-xs font-bold leading-6">
                Already have an account?{" "}
                <span className="cursor-pointer font-bold text-primary-500" onClick={() => navigate(ROUTES.login)}>
                  Sign In
                </span>
              </div>
            </div>
          </div>
        </form>

        <p className="text-left text-xs font-medium text-neutral-400">
          By clicking “Continue”, I agree to AgentX{" "}
          <a
            href={APP_LINKS.termsOfService}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-400 underline underline-offset-4"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href={APP_LINKS.privacy}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-400 underline underline-offset-4"
          >
            Privacy Policy
          </a>
          . This site is protected by reCAPTCHA.
        </p>
      </div>
    </>
  );
};
