import _ from "lodash";
import { StatsEngagementOverviewChart } from "./StatsEngagementOverviewChart";
import { ENGAGEMENT_STATS_COLORS_MAP } from "../../constants/statsConstants";
import { StatsMainChartHeader } from "../StatsMainChartHeader";
import { StatsIntervalPicker } from "../StatsIntervalPicker";
import { useState } from "react";
import { useGetEngagementOverviewStats } from "@/data/queries/stats/useGetEngagementOverviewStats";
import { statsMockEngagementData } from "../../constants/statsMockData";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import type { StatsInterval, StatsMainChartSectionTab } from "@/types/stats";
import { StatsIntervals } from "@/types/stats";
import { StatsMainChartSkeleton } from "../loaders/EngagementOverviewSkeleton";

type Props = {
  activeSectionTab: StatsMainChartSectionTab;
  onActiveSectionChange: (section: StatsMainChartSectionTab) => void;
};

const StatsEngagementOverview = ({ activeSectionTab, onActiveSectionChange }: Props) => {
  const { currentRange, startDate, endDate, activeChannelsOrigins, isUpgradeNeeded } = useStatsContext();

  const [statsInterval, setStatsInterval] = useState<StatsInterval>(StatsIntervals.DAILY);

  const { data: engagementData, isFetching: isFetchingEngagement } = useGetEngagementOverviewStats({
    currentRange,
    startDate,
    endDate,
    channels: activeChannelsOrigins,
    interval: statsInterval,
    enabled: !isUpgradeNeeded,
  });
  const engagementDataToShow = isUpgradeNeeded ? statsMockEngagementData : engagementData;

  return (
    <>
      <StatsMainChartHeader activeSectionTab={activeSectionTab} onActiveSectionChange={onActiveSectionChange}>
        <StatsIntervalPicker
          statsInterval={statsInterval}
          onStatsIntervalChange={setStatsInterval}
          disabled={isUpgradeNeeded}
        />
      </StatsMainChartHeader>
      <div className="my-6 flex flex-wrap items-center gap-x-8 gap-y-2">
        {Object.keys(ENGAGEMENT_STATS_COLORS_MAP).map(key => (
          <div key={key} className="flex items-center gap-2">
            <div
              className="h-4 w-4 rounded-full"
              style={{
                backgroundColor: ENGAGEMENT_STATS_COLORS_MAP[key as keyof typeof ENGAGEMENT_STATS_COLORS_MAP].color,
              }}
            />

            {ENGAGEMENT_STATS_COLORS_MAP[key as keyof typeof ENGAGEMENT_STATS_COLORS_MAP].label}
          </div>
        ))}
      </div>

      {isFetchingEngagement ? <StatsMainChartSkeleton /> : <StatsEngagementOverviewChart data={engagementDataToShow} />}
    </>
  );
};

export default StatsEngagementOverview;
