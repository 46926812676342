import {
  AddressElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Label } from "@/components/ui/label";
import type { OnSubmitStripePlan } from "../StripePaymentSummary";
import { APP_LINKS } from "@/constants/appLinks";
import { useGetCustomerBillingInfo } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";
import { useCreatePaymentMethod } from "@/data/mutations/subscriptions/useCreatePaymentMethod";
import { domElementIds } from "@/types/dom-element-ids";
import { useEffect, useState } from "react";
import type { StripeElementClasses, StripeElementStyle } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";
import type { TaxId } from "@/types/subscriptions";

const googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string;
const stripeClasses: StripeElementClasses = {
  base: "w-full rounded-md border border-neutral-300 bg-white px-3 py-2 text-neutral-700 ring-offset-white",
  focus: "outline-none ring-1 ring-primary-600 ring-offset-2 border-primary-300",
  invalid: "!border-error",
};
const stripeStyles: StripeElementStyle = {
  invalid: { color: "#404040" },
  base: {
    fontSize: "16px",
    lineHeight: "1.7",
  },
};

type StripePaymentFormProps = {
  onSubmit: OnSubmitStripePlan;
};

export const StripePaymentForm = ({ onSubmit }: StripePaymentFormProps) => {
  const elements = useElements();
  const stripe = useStripe();
  const { mutate: createPaymentMethod } = useCreatePaymentMethod();
  const { data: billingData } = useGetCustomerBillingInfo();
  const { data: plans } = useGetBillingPlans();
  const [creditCardErrors, setCreditCardErrors] = useState<{
    cardNumber: string | null;
    cardExpiry: string | null;
    cardCvc: string | null;
  }>({
    cardNumber: "This field is incomplete.",
    cardExpiry: "This field is incomplete",
    cardCvc: "This field is incomplete",
  });
  const [showErrors, setShowErrors] = useState(false);
  const [taxId, setTaxId] = useState<string>();
  const [taxValue, setTaxValue] = useState<string>();

  const addressDefaultValues = billingData?.paymentMethods[0].billing_details;
  const paymentMethod = billingData?.paymentMethods?.[0];

  useEffect(() => {
    if (taxValue || taxId) {
      return;
    }

    if (billingData?.tax) {
      if (billingData.tax?.type && billingData.tax?.value) {
        setTaxId(billingData.tax.type);
        setTaxValue(billingData.tax.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingData?.tax]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    void handleFormSubmit();
  };

  const handleFormSubmit = async () => {
    setShowErrors(true);
    if (!paymentMethod) {
      const cardNumberElement = elements?.getElement(CardNumberElement);
      if (!stripe || !cardNumberElement) {
        return;
      }
      const addressElement = await elements?.getElement(AddressElement)?.getValue();
      if (!addressElement || !addressElement.complete) {
        return;
      }
      createPaymentMethod(
        { cardNumberElement, userBillingDetails: addressElement.value },
        {
          onSuccess: data =>
            onSubmit({
              paymentMethodId: data.paymentMethodId,
              customerDetails: data.customerDetails,
              tax: (taxId && taxValue && ({ type: taxId, value: taxValue } as TaxId)) || undefined,
            }) as unknown as void,
          onError: error => toast.error(error.message),
        }
      );
    } else {
      await onSubmit({
        paymentMethodId: paymentMethod.id,
        customerDetails: addressDefaultValues!,
        tax: (taxId && taxValue && ({ type: taxId, value: taxValue } as TaxId)) || undefined,
      });
    }
  };

  const handleCcInfoChange = (e: {
    // type StripeElementType
    elementType: "cardNumber" | "cardExpiry" | "cardCvc";
    empty: boolean;
    complete: boolean;
    error:
      | undefined
      | {
          type: "validation_error";
          code: string;
          message: string;
        };
  }) => {
    setCreditCardErrors(prevState => {
      return {
        ...prevState,
        [e.elementType]: e.error?.message,
      };
    });
  };

  return (
    <form id={domElementIds.STRIPE_FORM} className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <div>
        <div className="flex flex-col gap-1.5">
          <Label htmlFor="cardNumber">Payment details</Label>
          <CardNumberElement
            onChange={handleCcInfoChange}
            id="cardNumber"
            options={{
              iconStyle: "solid",
              showIcon: true,
              classes: {
                ...stripeClasses,
                base: `${stripeClasses.base} ${showErrors && creditCardErrors.cardNumber ? "!border-error" : ""}`,
              },
              style: stripeStyles,
            }}
          />
        </div>
        {showErrors && <p className="mt-1 text-xs text-error">{creditCardErrors.cardNumber}</p>}
      </div>
      <div className="flex gap-2">
        <div className="w-full">
          <CardExpiryElement
            onChange={handleCcInfoChange}
            options={{
              classes: {
                ...stripeClasses,
                base: `${stripeClasses.base} ${showErrors && creditCardErrors.cardExpiry ? "!border-error" : ""}`,
              },
              style: stripeStyles,
            }}
          />

          {showErrors && <p className="mt-1 text-xs text-error">{creditCardErrors.cardExpiry}</p>}
        </div>
        <div className="w-full">
          <CardCvcElement
            onChange={handleCcInfoChange}
            options={{
              classes: {
                ...stripeClasses,
                base: `${stripeClasses.base} ${showErrors && creditCardErrors.cardCvc ? "!border-error" : ""}`,
              },
              style: stripeStyles,
            }}
          />
          {showErrors && <p className="mt-1 text-xs text-error">{creditCardErrors.cardCvc}</p>}
        </div>
      </div>
      <AddressElement
        options={{
          mode: "billing",
          ...(addressDefaultValues
            ? {
                defaultValues: {
                  name: addressDefaultValues.name,
                  address: addressDefaultValues.address,
                },
              }
            : {}),
          autocomplete: {
            apiKey: googleMapsApiKey,
            mode: "google_maps_api",
          },
        }}
      />
      <div className="w-full">
        <Label htmlFor="taxId">Tax ID (optional)</Label>
        <div className="flex gap-2 ">
          <select
            id="taxType"
            value={taxId}
            onChange={e => setTaxId(e.target.value)}
            className="w-1/3 rounded-md border border-neutral-300 bg-white px-3 py-2 text-neutral-700 ring-offset-white focus:outline-none focus:ring-1 focus:ring-primary-600 focus:ring-offset-2"
          >
            <option key="default" value="">
              Select country
            </option>
            {plans?.taxIdList?.map(tax => (
              <option key={tax.type} value={tax.type} className="capitalize">
                {tax.country} {tax.flag}
              </option>
            ))}
          </select>
          <input
            id="taxId"
            type="text"
            value={taxValue || ""}
            disabled={!taxId}
            onChange={e => setTaxValue(e.target.value)}
            className="w-2/3 rounded-md border border-neutral-300 bg-white px-3 py-2 text-neutral-700 ring-offset-white focus:outline-none focus:ring-1 focus:ring-primary-600 focus:ring-offset-2"
          />
        </div>
      </div>

      <div className="flex flex-col gap-2 ">
        <p className="text-xs font-medium leading-5 text-neutral-500">
          By providing your card information, you allow AgentX Inc to charge your card for future payments in accordance
          with their terms.
        </p>
        <p className="text-xs font-medium leading-5 text-neutral-500">
          You can review important information from AgentX Inc on our{" "}
          <a className="font-bold" href={APP_LINKS.termsOfService} target="_blank" rel="noreferrer">
            Terms of Service
          </a>{" "}
          and{" "}
          <a className="font-bold" href={APP_LINKS.privacy} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>{" "}
          pages
        </p>
      </div>
    </form>
  );
};
