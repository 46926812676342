import { useResetPassword } from "@/data/mutations/useResetPassword";
import { useForm } from "react-hook-form";
import { emailRegex } from "@/constants/regexps";
import { FormInput } from "@/components/ui/FormInput";
import { Icons } from "@/components/ui/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";

export const ForgotPassword = () => {
  const { mutate, isSuccess, error, isPending } = useResetPassword();
  const navigate = useNavigate();
  const form = useForm({ defaultValues: { email: "" } });
  const { handleSubmit, reset } = form;

  const submitResetPassword = (formData: { email: string }) => {
    const { email } = formData;
    mutate({ email }, { onSuccess: () => reset() });
  };

  if (isSuccess) {
    return (
      <div className="flex w-72 flex-col items-start gap-10 xl:w-80">
        <div className="flex flex-row items-center gap-2">
          <IconButton icon={<Icons.Arrow />} size="medium" variant="tertiary" onClick={() => navigate(ROUTES.login)} />
          <h2 className="w-full text-start text-2xl font-bold leading-9 tracking-tight lg:text-[28px]">Email Sent</h2>
        </div>

        <Icons.Mails className="flex w-full justify-center" />
        <p className="text-start text-base font-bold">
          A verification email has been sent to you. Check spam folder if you do not see it.
        </p>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col items-start  gap-6 xl:gap-10">
      <h2 className="text-center text-2xl font-bold leading-9 tracking-tight lg:text-[28px] xl:text-start">
        Enter your email address
      </h2>

      <form className="xl-w-80 flex w-72 flex-col gap-y-2.5" onSubmit={handleSubmit(submitResetPassword)}>
        <FormInput
          formObj={form}
          name="email"
          autoComplete="email"
          inputClassName="h-[38px] text-xs"
          required
          placeholder="Email address"
          rules={{
            required: "Email is required",
            pattern: {
              value: emailRegex,
              message: "Entered value does not match email format",
            },
          }}
        />

        {error && (
          <div className="py-2 text-center text-xs font-medium leading-5 text-red-600">
            <div>{error.response?.data.message}</div>
          </div>
        )}

        <Button type="submit" size="medium" loading={isPending} className="w-full">
          Continue
        </Button>
        <Button
          variant="ghost"
          size="medium"
          loading={isPending}
          className="w-full"
          onClick={() => navigate(ROUTES.login)}
        >
          Go back
        </Button>
      </form>
    </div>
  );
};
