import { Avatar } from "@/components/Avatar";
import { Button } from "@/components/ui/button";
import { CarouselItem } from "@/components/ui/carousel";
import { Icons } from "@/components/ui/icons";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";
import { useGetAppConfig } from "@/data/queries/useGetAppConfig";
import { useGetUser } from "@/data/queries/useGetUser";
import { cn } from "@/lib/utils";
import type { FullAgent } from "@/types/agent";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { Link } from "react-router-dom";

const _ContentWrapper = ({
  children,
  isLocked,
  agentId,
}: {
  children: React.ReactNode;
  isLocked: boolean;
  agentId: FullAgent["botId"];
}) => {
  const { openDialog } = useAccountUsageDialogContext();

  const handleOpenUsageDialog = () => openDialog("llmModel");

  if (isLocked) {
    return (
      <div onClick={handleOpenUsageDialog} className="max-h-full cursor-pointer">
        {children}
      </div>
    );
  }

  return <Link to={getNewConversationRoute([agentId])}>{children}</Link>;
};

export const WorkspaceBaseModelTile = ({ agent, isLast }: { agent: FullAgent; isLast: boolean }) => {
  const { user } = useGetUser();
  const { data } = useGetAppConfig();
  const llmList = data?.llmList || [];

  const llmMinTier = llmList.find(model => model.model === agent.llmModel)?.minTier;

  const isLocked =
    llmMinTier !== undefined && user?.subscription.tier !== undefined && llmMinTier > user.subscription.tier;

  return (
    <CarouselItem
      key={agent._id}
      containerClassName="basis-auto h-fit"
      className={cn("group/card relative mx-0 w-32 min-w-32", {
        "mr-8 lg:mr-32": isLast,
      })}
    >
      <_ContentWrapper agentId={agent.botId} isLocked={isLocked}>
        <div className="flex h-[100px] rounded-md border-l-[0.75px] border-r-[0.75px] border-t-[0.75px] border-neutral-200 ">
          <Avatar
            size="custom"
            name={agent.name}
            src={agent?.avatar}
            className=" size-full overflow-hidden rounded-md "
          />
        </div>

        {isLocked && (
          <div className="absolute right-2 top-2 rounded-[4px] bg-white p-[6px]">
            <Icons.Lock className="h-4 w-4 text-primary-400 group-hover/card:text-primary-500" />
          </div>
        )}

        <div className="absolute bottom-0 left-0 h-[40%] w-full overflow-hidden rounded-b-md bg-fade-out-vertical-dark" />
        <p className="absolute bottom-1 left-2 text-left text-xs font-bold text-white line-clamp-2-wrap">
          {agent.name}
        </p>

        <div className="absolute bottom-0 left-0 hidden h-[40%] w-full justify-end overflow-hidden rounded-b-md bg-fade-out-vertical-dark p-1 group-hover/card:flex">
          <Button variant="tertiary" size="small" className="group/button gap-1 border-none px-2">
            {isLocked ? "Upgrade" : "Chat"}
            <Icons.Arrow className="h-4 w-4 rotate-180 group-hover/button:translate-x-[2px]" />
          </Button>
        </div>
      </_ContentWrapper>
    </CarouselItem>
  );
};
