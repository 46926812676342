import { cn } from "@/lib/utils";

type NotificationDotProps = { active: boolean; className?: string };

export const NotificationDot = ({ active, className }: NotificationDotProps) => {
  if (!active) {
    return null;
  }

  return (
    <div className={cn("absolute right-2 top-2 size-3 rounded-full border border-primary-50 bg-error", className)} />
  );
};
