import type { Agent } from "@/types/agent";
import { useGetSingleAgentEngagementStats } from "@/data/queries/stats/useGetSingleAgentEngagementStats";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import type { StatsInterval, StatsSingleAgentPerformanceDataType } from "@/types/stats";
import { StatsMainChartSkeleton } from "../loaders/EngagementOverviewSkeleton";
import { StatsSingleAgentPerformanceChart } from "./StatsSingleAgentPerformanceChart";
import { getSingleAgentPerformanceFilteredData } from "../../utils/getSingleAgentPerformanceFilteredData";
import StatsSingleAgentPerformanceLegend from "./StatsSingleAgentPerformanceLegend";

type Props = {
  agentId: Agent["_id"];
  interval: StatsInterval;
  selectedDataType: StatsSingleAgentPerformanceDataType | null;
};

export const StatsSingleAgentPerformance = ({ agentId, interval, selectedDataType }: Props) => {
  const { currentRange, startDate, endDate, activeChannelsOrigins } = useStatsContext();

  const { data, isFetching: isFetchingSingleAgentEngagement } = useGetSingleAgentEngagementStats({
    currentRange,
    startDate,
    endDate,
    channels: activeChannelsOrigins,
    agentId,
    interval,
  });

  const filteredData = getSingleAgentPerformanceFilteredData({
    data,
    selectedDataType,
  });

  return (
    <>
      <StatsSingleAgentPerformanceLegend selectedDataType={selectedDataType} />

      {isFetchingSingleAgentEngagement ? (
        <StatsMainChartSkeleton />
      ) : (
        <StatsSingleAgentPerformanceChart data={filteredData} />
      )}
    </>
  );
};
