import { NotificationDot } from "@/components/Notifications/NotificationDot";
import { Icons } from "@/components/ui/icons";

type NotificationsIconProps = {
  active: boolean;
};

export const NotificationsIcon = ({ active }: NotificationsIconProps) => {
  return (
    <div className="relative h-[40px] w-[40px] items-center p-2 text-center">
      <NotificationDot active={active} />
      <Icons.Bell className="size-6 cursor-pointer text-primary-black" />
    </div>
  );
};
