import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { StyleSetVariant, type FullAgent } from "@/types/agent";
import { Controller, type UseFormReturn } from "react-hook-form";
import ChatBubbleRounded from "@/assets/images/chatBubbleStyles/ChatBubbleRounded.svg";
import ChatBubbleSquare from "@/assets/images/chatBubbleStyles/ChatBubbleSquare.svg";
import ChatBubbleClassic from "@/assets/images/chatBubbleStyles/ChatBubbleClassic.svg";
import { useRef } from "react";
import { cn } from "@/lib/utils";

type StyleSetProps = {
  form: UseFormReturn<FullAgent["publish"]>;
};

export const StyleSet = ({ form }: StyleSetProps) => {
  const { control, register } = form;
  const radioRefs = useRef<HTMLButtonElement[]>([]);

  const styleSets = [
    {
      id: 1,
      name: "Rounded",
      image: ChatBubbleRounded,
      value: StyleSetVariant.Rounded,
    },
    {
      id: 2,
      name: "Square",
      image: ChatBubbleSquare,
      value: StyleSetVariant.Square,
    },
    {
      id: 3,
      name: "Classic",
      image: ChatBubbleClassic,
      value: StyleSetVariant.Classic,
    },
  ];

  const onRadioClick = (id: number, onChange: () => void) => {
    if (radioRefs.current[id]) {
      radioRefs.current[id].click();
      radioRefs.current[id].focus();
    }
    onChange();
  };

  return (
    <div>
      <Label className="text-neutral-400">Style Set</Label>
      <div className="mt-2 flex w-full flex-wrap items-center justify-center gap-4 rounded-md px-1 py-3 lg:px-6 lg:py-4">
        <Controller
          control={control}
          name="chatStyle.variant"
          render={({ field: { value, onChange } }) => {
            return (
              <RadioGroup
                {...register("chatStyle.variant")}
                defaultValue={value}
                name="style-set"
                className="grid-cols-3 "
              >
                {styleSets.map(set => (
                  <div
                    key={set.id}
                    className={cn(
                      "flex flex-1 cursor-pointer flex-col items-center rounded-md p-2 hover:bg-primary-50 lg:p-4",
                      {
                        "bg-primary-50": value === set.value,
                      }
                    )}
                    onClick={() => onRadioClick(set.id, () => onChange(set.value))}
                  >
                    <img src={set.image} />
                    <div className="mt-4 flex items-center">
                      <RadioGroupItem
                        value={set.value}
                        ref={el => {
                          if (el) {
                            radioRefs.current[set.id] = el;
                          }
                        }}
                      />
                      <p className="ml-2">{set.name}</p>
                    </div>
                  </div>
                ))}
              </RadioGroup>
            );
          }}
        />
      </div>
    </div>
  );
};
