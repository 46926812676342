import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { AgentState, type Agent } from "@/types/agent";
import AgentUpdating from "@/assets/images/AgentUpdating.svg";
import { GeneralInfo } from "./GeneralInfo/GeneralInfo";
import { OnboardingKnowledge } from "./Knowledge/OnboardingKnowledge";
import { Actions } from "./Actions/Actions";
import { Deploy } from "./Deploy/Deploy";
import { AgentFormTabs } from "../types/AgentFormTypes";
import { useAgentFormNavigationTab } from "../hooks/useAgentFormNavigationTab";
import { useEffect, useRef } from "react";
import { useAdaptiveScrollbar } from "@/hooks/useAdaptiveScrollbar";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";

const tabsMap = {
  [AgentFormTabs.GENERAL_INFO.name]: GeneralInfo,
  [AgentFormTabs.KNOWLEDGE.name]: OnboardingKnowledge,
  [AgentFormTabs.ACTIONS.name]: Actions,
  [AgentFormTabs.DEPLOY.name]: Deploy,
};

export const AgentFormContent = ({ isLoading, agentState }: { isLoading: boolean; agentState?: Agent["state"] }) => {
  const { activeTab } = useAgentFormNavigationTab();
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useMediaQueriesContext();
  const isSm = useTailwindMediaQuery("sm");
  const isLg = useTailwindMediaQuery("lg");

  useAdaptiveScrollbar(contentContainerRef, isMobile ? 0 : isSm && !isLg ? 10 : 24);

  useEffect(() => {
    if (contentContainerRef.current) {
      contentContainerRef.current.scrollTop = 0;
    }
  }, [activeTab]);

  if (isLoading) {
    return <LoadingSpinner className="flex size-full items-center justify-center pb-32" />;
  }

  if (agentState === AgentState.AGENT_UPDATING || agentState === AgentState.AGENT_CREATING) {
    const status = agentState === AgentState.AGENT_UPDATING ? "updated" : "created";
    return (
      <div className="absolute left-0 z-50 flex size-full flex-col items-center justify-center gap-2 px-4">
        <img src={AgentUpdating} alt="agent updating" />
        <p className="my-10 text-lg text-neutral-500">
          The Agent is currently being {status}...
          <LoadingSpinner className="mt-6 flex size-full items-center justify-center" />
        </p>
      </div>
    );
  }

  const Content = tabsMap[activeTab.name];

  return (
    <div
      ref={contentContainerRef}
      className="flex flex-col gap-5 sm:gap-7 sm:pr-2.5 lg:size-full lg:overflow-y-scroll lg:p-6"
    >
      <Content />
    </div>
  );
};
