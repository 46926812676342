import { Icons } from "@/components/ui/icons";
import { CustomerDropdownMenu } from "@/pages/Workspace/components/CustomerSpace/CustomerDropdownMenu";
import type { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { useGetWorkspaceUsers } from "@/data/queries/workspace/useGetWorkspaceUsers";

type CustomerSpaceDetailsProps = {
  workspaceDetails: ReturnType<typeof useGetWorkspaceDetails>["data"];
};

export const CustomerSpaceDetails = ({ workspaceDetails }: CustomerSpaceDetailsProps) => {
  const { data: members } = useGetWorkspaceUsers();

  return (
    <h4 className="sticky top-0 z-10 flex items-center gap-2 bg-fade-out-vertical-light pb-4 text-left font-bold">
      {workspaceDetails?.customer.name}
      <div className="flex gap-x-1.5 text-sm font-bold  sm:gap-x-2 sm:text-base">
        <Icons.Team className="size-5 text-primary-400 sm:size-6" />
        {members?.length || 0}
      </div>
      <CustomerDropdownMenu />
    </h4>
  );
};
