import { Accordion, AccordionItem } from "@/components/ui/accordion";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Controller } from "react-hook-form";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { GoogleCalendarConnect } from "./GoogleCalendarConnect";
import { useGetUser } from "@/data/queries/useGetUser";
import { domElementIds } from "@/types/dom-element-ids";

const GoogleCalendar = () => {
  const { user, refetch } = useGetUser();
  const { watch, control } = useAgentFormContext();

  const isGoogleCalendarEnabled = watch("googleCalendar.enabled");
  const calendarIntegration = user?.externalIntegrations?.google_calendar;

  return (
    <Accordion
      id={domElementIds.GOOGLE_CALENDAR}
      type="single"
      collapsible
      className="relative w-full border-b border-neutral-100 py-4"
      value={isGoogleCalendarEnabled ? "enabled" : ""}
      disabled={!calendarIntegration}
    >
      <AccordionItem value="enabled">
        <div className="flex justify-between">
          <div className="flex items-center gap-1">
            <h4>Google Calendar</h4>
            <HybridTooltipPopover heading="Google Calendar Integration">
              <p>
                Connect your Google Calendar account to enable and allow your agent to manage your Google Calendar
                events.
              </p>
            </HybridTooltipPopover>
          </div>

          <div className="flex items-center gap-4">
            <Controller
              control={control}
              name="googleCalendar.enabled"
              render={({ field: { value, onChange } }) => (
                <>
                  <Label htmlFor="parameterRequired">Enabled</Label>
                  <Switch id="parameterRequired" checked={value} onCheckedChange={onChange} />
                </>
              )}
            />
          </div>
        </div>
        <p className={`py-2 text-xs font-medium ${isGoogleCalendarEnabled ? "text-neutral-650" : "text-neutral-400"}`}>
          By enabling Google Calendar, the agent will be able to create, delete, and check the availabilities of your
          calendar.{" "}
        </p>
        <GoogleCalendarConnect
          refetchUser={refetch}
          isEnabled={isGoogleCalendarEnabled}
          calendarIntegration={calendarIntegration}
        />
      </AccordionItem>
    </Accordion>
  );
};

export default GoogleCalendar;
