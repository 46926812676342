import { ALL_AGENTS_STATS_LEGEND } from "../../constants/statsConstants";

const StatsAllDataIcon = () => {
  return (
    <div className="grid grid-cols-2 grid-rows-2 gap-[1.5px]">
      <div
        className="h-[7.5px] w-[7.5px] rounded-full"
        style={{
          backgroundColor: ALL_AGENTS_STATS_LEGEND.views.color,
        }}
      />
      <div
        className="h-[7.5px] w-[7.5px] rounded-full"
        style={{
          backgroundColor: ALL_AGENTS_STATS_LEGEND.messages.color,
        }}
      />
      <div
        className="h-[7.5px] w-[7.5px] rounded-full"
        style={{
          backgroundColor: ALL_AGENTS_STATS_LEGEND.conversations.color,
        }}
      />
      <div
        className="h-[7.5px] w-[7.5px] rounded-full"
        style={{
          backgroundColor: ALL_AGENTS_STATS_LEGEND.likedMessages.color,
        }}
      />
    </div>
  );
};

export default StatsAllDataIcon;
