import type { RegisterStep, RegisterFormTypes } from "@/types/user-register";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RegisterFormValidationSchema } from "@/components/RegisterForm/constants/RegisterFormValidationSchema";
import { RegisterAgeVerification } from "@/components/RegisterForm/components/RegisterAgeVerification";
import { RegisterAgeVerificationFailed } from "@/components/RegisterForm/components/RegisterAgeVerificationFailed";
import { RegisterUserAndTerms } from "@/components/RegisterForm/components/RegisterUserAndTerms";
import { RegisterAgeVerificationParentsApproval } from "@/components/RegisterForm/components/RegisterAgeVerificationParentsApproval";

export const RegisterForm = () => {
  const [registerStep, setRegisterStep] = useState<RegisterStep>("ageVerificationWait");
  const [isGoogleRegister, setIsGoogleRegister] = useState(false);

  const form = useForm<RegisterFormTypes>({
    resolver: zodResolver(RegisterFormValidationSchema({ isSso: isGoogleRegister })),
  });

  if (registerStep === "ageVerificationProcess") {
    return (
      <RegisterAgeVerification form={form} isGoogleRegister={isGoogleRegister} setRegisterStep={setRegisterStep} />
    );
  }

  if (registerStep === "ageVerificationFailed") {
    return <RegisterAgeVerificationFailed />;
  }

  if (registerStep === "ageVerificationParentsApproval") {
    return <RegisterAgeVerificationParentsApproval form={form} />;
  }

  return (
    <RegisterUserAndTerms form={form} setRegisterStep={setRegisterStep} setIsGoogleRegister={setIsGoogleRegister} />
  );
};
