import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";
import { useGetUser } from "@/data/queries/useGetUser";
import { cn } from "@/lib/utils";
import type { FullAgent } from "@/types/agent";
import { SubscriptionTiers } from "@/types/subscriptions";
import { Controller, type UseFormReturn } from "react-hook-form";

type CustomBrandingProps = {
  form: UseFormReturn<FullAgent["publish"]>;
};

export const CustomBranding = ({ form }: CustomBrandingProps) => {
  const { user } = useGetUser();
  const { openDialog } = useAccountUsageDialogContext();

  const { control, register } = form;

  if (!user?._id) {
    return null;
  }

  const isProTier = user.subscription.tier >= SubscriptionTiers.PRO;

  const handleOpenAccountUsageDialog = () => openDialog("advancedFeatures");

  return (
    <div className="ml-1">
      <Controller
        control={control}
        name="customBranding"
        render={({ field: { value, onChange } }) => (
          <div className="flex w-full flex-col gap-1.5">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <Label htmlFor="customBranding" className="text-md">
                  Customise Branding
                </Label>
                <HybridTooltipPopover>
                  <p>
                    Press switch button to activate custom branding. Type your custom message and it will be displayed
                    in the chat footer. Leave the field empty if you want to remove branding altogether.
                  </p>
                </HybridTooltipPopover>
                {!isProTier && (
                  <Button
                    onClick={handleOpenAccountUsageDialog}
                    variant="tertiary"
                    size="tiny"
                    className="mt-[2px] font-medium text-primary-400"
                  >
                    UPGRADE
                  </Button>
                )}
              </div>

              <div className="flex gap-2 text-xs font-medium sm:gap-4">
                <Label className={cn(!isProTier && "opacity-50")} htmlFor="customBrandingActive">
                  {value.isActive ? "" : "Default"}
                </Label>
                <Switch
                  disabled={!isProTier}
                  id="customBrandingActive"
                  checked={value.isActive}
                  onCheckedChange={checked => onChange({ ...value, isActive: checked })}
                />
              </div>
            </div>
            <Input
              placeholder={value.isActive ? "Your custom message" : "Powered by AGENTX"}
              disabled={!isProTier || !value.isActive}
              inputClassName={cn(!value.isActive && "text-neutral-300")}
              {...register("customBranding.value")}
            />
          </div>
        )}
      />
    </div>
  );
};
