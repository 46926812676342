import { NavLink } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import type { Agent } from "@/types/agent";

type Props = {
  setFooterHeight: React.Dispatch<React.SetStateAction<number>>;
  agent: Agent;
};

export const StatsSidePanelFooter = ({ setFooterHeight, agent }: Props) => {
  const { setChatHistoryDialogProps } = useStatsContext();

  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!footerRef.current) {
      return;
    }

    setFooterHeight(footerRef.current.clientHeight);
  }, [setFooterHeight]);

  return (
    <Carousel
      ref={footerRef}
      className="absolute bottom-0 left-0 flex h-fit w-full items-center justify-end gap-4 px-2.5 py-4 shadow-card"
      opts={{
        startIndex: 0,
        align: "start",
      }}
      slideStep={3}
    >
      <CarouselContent containerClassName="relative">
        <CarouselItem>
          <Button
            variant="secondary"
            onClick={() =>
              setChatHistoryDialogProps({
                agent,
              })
            }
          >
            Chat history
          </Button>
        </CarouselItem>

        <CarouselItem>
          <Button
            variant="secondary"
            onClick={() =>
              setChatHistoryDialogProps({
                agent,
                downvotedOnly: true,
              })
            }
          >
            Downvoted messages
          </Button>
        </CarouselItem>

        <CarouselItem className="mr-5 @md/main:mr-0">
          <NavLink to={ROUTES.editAgent(agent._id)}>
            <Button variant="secondary">Edit agent</Button>
          </NavLink>
        </CarouselItem>
      </CarouselContent>

      <div className="absolute right-0 top-1/2 h-36 w-10 -translate-y-1/2 bg-fade-out-horizontal-light @md/main:hidden"></div>
    </Carousel>
  );
};
