import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar } from "@/components/Avatar";
import type { Agent } from "@/types/agent";
import { Icons } from "@/components/ui/icons";

type Props = {
  agent: Agent;
  allAgents: Agent[];
  onChangeSelectedAgent: (agent: Agent) => void;
};

export const StatsSidePanelAgentPicker = ({ agent, allAgents, onChangeSelectedAgent }: Props) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="flex items-center gap-1 rounded-lg bg-white px-3 py-1 text-sm shadow-sm"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex items-center gap-1">
          <Avatar src={agent.avatar} size="custom" className="h-5 w-5" />
          {agent.name}
        </div>
        <Icons.ChevronRight className="h-4 w-4 rotate-90" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {allAgents?.map(agent => {
          return (
            <DropdownMenuItem
              key={agent._id}
              className="flex items-center gap-1.5"
              onClick={() => onChangeSelectedAgent(agent)}
            >
              <Avatar src={agent.avatar} size="custom" className="h-5 w-5" />
              {agent.name}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
