import { useGetUser } from "@/data/queries/useGetUser";
import type { SubscriptionTier } from "@/types/subscriptions";
import { SubscriptionPeriod, SubscriptionTiers } from "@/types/subscriptions";
import { SubscriptionTierPricingCard } from "./SubscriptionTierPricingCard";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { Carousel, CarouselContent, CarouselDots, CarouselItem } from "@/components/ui/carousel";
import { useGetBillingPlans } from "@/data/queries/subscriptions/useGetBillingPlans";
import { useGetCustomerBillingInfo } from "@/data/queries/subscriptions/useGetCustomerBillingInfo";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";

export const SubscriptionTierPricing = ({ period }: { period: SubscriptionPeriod }) => {
  const { setSelectedPlan, setActiveStep } = useSubscriptionTierDialogContext();
  const { data: plans } = useGetBillingPlans();
  const { user } = useGetUser();
  const { data: billingInfo } = useGetCustomerBillingInfo();
  const userHasPaymentMethod = user?.subscription.stripeId;

  const { isMobile } = useMediaQueriesContext();

  const currentPlanPeriod =
    billingInfo?.subscription.interval === "month" ? SubscriptionPeriod.MONTHLY : SubscriptionPeriod.ANNUALLY;
  if (!user) {
    return null;
  }

  const getPricing = (tier: SubscriptionTier): { currentPrice: string; crossedOutPrice: string } | null => {
    if (tier === SubscriptionTiers.ENTERPRISE) {
      return null;
    }
    if (tier === SubscriptionTiers.FREE) {
      return { currentPrice: "$0", crossedOutPrice: "" };
    }
    const pricing = plans?.billingPlans.find(plan => plan.tier === tier)?.price?.[period].amount || 0;

    switch (period) {
      case SubscriptionPeriod.ANNUALLY:
        return {
          currentPrice: `$${(pricing / 12).toFixed()}`,
          crossedOutPrice: `$${plans?.billingPlans.find(plan => plan.tier === tier)?.price.monthly.amount || 0}`,
        };
      case SubscriptionPeriod.MONTHLY:
        return {
          currentPrice: `$${pricing}`,
          crossedOutPrice: "",
        };
      default:
        return null;
    }
  };

  if (isMobile) {
    return (
      <Carousel
        className="h-fit"
        opts={{
          startIndex: 2,
          align: "center",
        }}
      >
        <CarouselContent className="my-9">
          {plans?.billingPlans?.map(plan => {
            const isCurrentPlan =
              (billingInfo ? currentPlanPeriod === period : true) && user.subscription.tier === plan.tier;
            return (
              <CarouselItem containerClassName="basis-3/4 xs:basis-3/5 md:basis-1/3 h-fit basis-full" key={plan.name}>
                <SubscriptionTierPricingCard
                  className="mx-auto"
                  key={plan.tier}
                  title={plan.name}
                  description={plan.description}
                  currentPrice={getPricing(plan.tier)?.currentPrice ?? ""}
                  crossedOutPrice={getPricing(plan.tier)?.crossedOutPrice ?? ""}
                  periodUnit="month"
                  btn={{
                    text: isCurrentPlan ? "Current plan" : "Select plan",
                    variant: isCurrentPlan ? "tertiary" : "primary",
                    onClick: () => {
                      setSelectedPlan({ plan, period });
                      userHasPaymentMethod
                        ? setActiveStep("changeSubscriptionTier")
                        : setActiveStep("stripePaymentSummary");
                    },
                    disabled: isCurrentPlan,
                  }}
                  isHighlighted={plan.tier === SubscriptionTiers.PRO}
                  subtitle={plan.tier === SubscriptionTiers.PRO ? "MOST POPULAR" : ""}
                />
              </CarouselItem>
            );
          })}
        </CarouselContent>

        <CarouselDots className="-bottom-2 md:hidden" />
      </Carousel>
    );
  }

  return (
    <div className="mt-8 flex flex-col items-center justify-center gap-8 lg:flex-row">
      {plans?.billingPlans?.map(plan => {
        const isCurrentPlan =
          (billingInfo ? currentPlanPeriod === period : true) && user.subscription.tier === plan.tier;
        return (
          <SubscriptionTierPricingCard
            key={plan.name}
            title={plan.name}
            description={plan.description}
            currentPrice={getPricing(plan.tier)?.currentPrice ?? ""}
            crossedOutPrice={getPricing(plan.tier)?.crossedOutPrice ?? ""}
            periodUnit="month"
            btn={{
              text: isCurrentPlan ? "Current plan" : "Select plan",
              variant: isCurrentPlan ? "tertiary" : "primary",
              onClick: () => {
                setSelectedPlan({ plan, period });
                setActiveStep("stripePaymentSummary");
              },
              disabled: isCurrentPlan,
            }}
            isHighlighted={plan.tier === SubscriptionTiers.PRO}
            subtitle={plan.tier === SubscriptionTiers.PRO ? "MOST POPULAR" : ""}
          />
        );
      })}
    </div>
  );
};
