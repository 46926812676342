import { Avatar } from "@/components/Avatar";
import AgentStatusDot from "@/components/AgentStatus/AgentStatusDot";
import { Icons } from "@/components/ui/icons";
import type { ChatAgent } from "@/types/conversation";

type AgentInfoProps = {
  agent: ChatAgent;
  isMyAgent: boolean | undefined;
};

export const AgentInfo = ({ agent, isMyAgent }: AgentInfoProps) => {
  const generateAgentAuthorName = () => (isMyAgent ? "you" : agent.company);

  return (
    <div className="flex w-full items-center justify-between gap-2">
      <div className="relative">
        <Avatar size="m" src={agent?.avatar} name={agent.name} />
        <AgentStatusDot agentState={agent.state} />
      </div>
      <div className="flex grow items-center justify-between gap-2">
        <div className="flex flex-col gap-1 text-start">
          <h6 className="text-left font-bold leading-5 text-neutral-750 line-clamp-2-wrap">{agent.name}</h6>
          <div className="flex w-[130px] items-center justify-between gap-1">
            {!isMyAgent && <Icons.Team className="size-4 text-primary-400" />}
            <span className="whitespace-nowrap text-xs font-medium text-gray-400">Created by</span>
            <span className="w-[70px] overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium text-gray-400">
              {generateAgentAuthorName()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
