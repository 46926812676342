import { differenceInMinutes, format } from "date-fns";
import { FetchNextPageTrigger } from "@/components/Chat/components/FetchNextPageTrigger";
import { MessageCloudTranslated } from "@/components/Chat/components/MessageCloudTranslated";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useHighlightSearchedText } from "@/pages/Stats/hooks/useHighlightSearchedText";
import { useGetConversationMessages } from "@/data/queries/useGetConversationMessages";
import { cn } from "@/lib/utils";
import type { ConversationWithUsersDetails } from "@/types/conversation";
import type { ReactElement } from "react";
import { useRef } from "react";
import { useAdaptiveScrollbar } from "@/hooks/useAdaptiveScrollbar";
import { ChatHistoryConversationSummary } from "./ChatHistoryConversationSummary";

type Props = {
  conversationItem: {
    conversation: ConversationWithUsersDetails;
    searchMatches: number;
  };
  currentSearchValue: string;
  showSearchMatches: boolean;
  conversationUsers: {
    _id: string;
    name: string;
    avatar: string;
  }[];
  renderSubtitle?: ({ botNames }: { botNames: string[] }) => ReactElement;
};

export const ChatHistoryConversation = ({ conversationItem, currentSearchValue, conversationUsers }: Props) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const {
    data: conversationData,
    isLoading,
    isPending,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetConversationMessages({
    conversationId: conversationItem.conversation._id,
  });

  const botNames = [
    ...new Set(
      conversationData?.messages.filter(message => "bot" in message).map(message => message?.bot?.name ?? "") ?? []
    ),
  ];

  useHighlightSearchedText({
    textToHighlight: currentSearchValue,
    enabled: conversationItem.searchMatches !== 0 && !isPending,
  });

  useAdaptiveScrollbar(scrollContainerRef, 8);

  return (
    <div className="flex size-full flex-col rounded border border-solid border-cloud-dark px-2">
      {conversationItem.conversation.summary && (
        <ChatHistoryConversationSummary text={conversationItem.conversation.summary.text} className="mx-4 mt-2.5" />
      )}

      <div ref={scrollContainerRef} className="my-2 flex flex-col-reverse overflow-auto px-2">
        {isLoading && <LoadingSpinner className="mx-auto my-5" />}
        {conversationData?.messages.map((message, index) => {
          const currentMessageTime = new Date(message.createdAt);
          const prevMessageTime = index > 0 && new Date(conversationData.messages[index + 1]?.createdAt);
          const timeDiff = prevMessageTime && differenceInMinutes(currentMessageTime, prevMessageTime);

          return (
            <div
              key={`${message.conversationId}-message-${index}`}
              className={cn("bot" in message ? "bg-neutral-100" : "")}
            >
              <MessageCloudTranslated
                message={message.text}
                isBotMessage={"bot" in message}
                key={message._id + message.text}
                messageId={message._id}
                conversationId={message.conversationId}
                trace={message.trace}
                vote={message.vote}
                messageIndex={index}
                createdAt={message.createdAt}
                isBookmark={message.isBookmark}
                agent={message.bot}
                setDocPreviewPage={undefined}
                setDocumentInPreview={undefined}
                botNames={botNames}
                isGeneratingBotResponse={message.isGenerating}
                attachments={message.attachments}
                messageUser={message.user}
                disableActions
                displayUserDetails={{
                  name: conversationUsers.find(user => user._id === message.user)?.name ?? "",
                  avatar: conversationUsers.find(user => user._id === message.user)?.avatar ?? "",
                }}
              />
              {index === conversationData?.messages.length - 1 || (timeDiff && timeDiff > 5) ? (
                <div className="my-1 flex justify-center text-xs text-gray-400">
                  {format(currentMessageTime, "dd MMM yyyy HH:mm")}
                </div>
              ) : null}
            </div>
          );
        })}
        {isFetchingNextPage && <LoadingSpinner className="mx-auto my-5" />}
        {hasNextPage && <FetchNextPageTrigger fetchNextPage={fetchNextPage} />}
      </div>
    </div>
  );
};
