import type { DateRangeType } from "@/types/datepicker";
import { DateRangeTypes } from "@/types/datepicker";
import { useCallback, useState } from "react";
import type { StatsProps } from "./StatsContext";
import { StatsContext } from "./StatsContext";
import { useLocation, useNavigate } from "react-router-dom";
import type { TypeFromConstObject } from "@/types/types";
import type { Agent } from "@/types/agent";
import { Origins, type Origin } from "@/types/origin";
import { useGetUser } from "@/data/queries/useGetUser";
import { SubscriptionTiers } from "@/types/subscriptions";

export const StatsPageChannelsTabs = {
  ALL_CHANNELS: { origin: null, description: "All channels" },
  WEB: { origin: Origins.WEB, description: "Web" },
  EMBEDDED: { origin: Origins.EMBEDDED, description: "Embedded" },
  AGENT_SPACE: { origin: Origins.AGENT_SPACE, description: "Agent Space" },
  WORKSPACE: { origin: Origins.WORKSPACE, description: "Workspace" },
  DISCORD: { origin: Origins.DISCORD, description: "Discord" },
  SLACK: { origin: Origins.SLACK, description: "Slack" },
} as const;

export type StatsPageChannelTab = TypeFromConstObject<typeof StatsPageChannelsTabs>;

export const statsChannelsTabs = Object.values(StatsPageChannelsTabs);

type StatsProviderProps = {
  children: React.ReactNode;
};

export const StatsProvider = ({ children }: StatsProviderProps) => {
  const location = useLocation() as {
    state: { tab: StatsPageChannelTab | null } | null;
    pathname: string;
  };
  const navigate = useNavigate();
  const { user } = useGetUser();

  const isUpgradeNeeded =
    user?.subscription.tier === SubscriptionTiers.FREE || user?.subscription.tier === SubscriptionTiers.STARTUP;

  const [currentRange, setCurrentRange] = useState<DateRangeType>(DateRangeTypes.Last7Days);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [selectedAgents, setSelectedAgents] = useState<Agent[]>([]);

  const [chatHistoryDialogProps, setChatHistoryDialogProps] = useState<{
    agent: Agent;
    channel?: Origin;
    downvotedOnly?: boolean;
  } | null>(null);
  const isChatHistoryDialogOpen = !!chatHistoryDialogProps;

  const activeChannelTab: StatsPageChannelTab = location.state?.tab ?? StatsPageChannelsTabs.ALL_CHANNELS;
  const activeChannelsOrigins =
    activeChannelTab.description === StatsPageChannelsTabs.ALL_CHANNELS.description ? [] : [activeChannelTab.origin];

  const switchChannelTab = useCallback(
    ({ newTab }: { newTab: StatsPageChannelTab }) => {
      navigate(location.pathname, { replace: true, state: { tab: newTab } });
    },
    [location.pathname, navigate]
  );

  const value: StatsProps = {
    currentRange,
    setCurrentRange,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedAgents,
    setSelectedAgents,
    chatHistoryDialogProps,
    setChatHistoryDialogProps,
    isChatHistoryDialogOpen,
    activeChannelsOrigins,
    activeChannelTab,
    switchChannelTab,
    isUpgradeNeeded,
  };

  return <StatsContext.Provider value={value}>{children}</StatsContext.Provider>;
};
