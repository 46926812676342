import type { SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP } from "@/pages/Stats/constants/statsConstants";
import type { DateRangeType } from "./datepicker";
import type { Origin } from "./origin";
import type { TypeFromConstObject } from "./types";
import type { Agent } from "@/types/agent";
import type { ChartData } from "chart.js";

export type BasicStats = {
  conversations: {
    total: number;
    trend: number | null;
  };
  views: {
    total: number;
    trend: number | null;
  };
  leadGeneration: {
    total: number;
    trend: number | null;
  };
  messages: {
    total: number;
    trend: number | null;
  };
};

export type TopAgentStats = {
  agent: Agent | null;
  messages: {
    total: number;
    trend: number | null;
  };
  views: {
    total: number;
    trend: number | null;
  };
  satisfaction: number | null;
};

export type AgentOverviewStats = {
  agent: Agent;
  messages: number;
  messagesTrend: number | null;
  conversations: number;
  conversationsTrend: number | null;
  satisfaction: number | null;
  satisfactionTrend: number | null;
  channels: {
    channel: Origin;
    value: number;
  }[];
};

export type Rating = {
  positiveFeedback: number;
  negativeFeedback: number;
};

export type Summary = {
  conversations_count: number;
  messages_count: number;
  rating: number;
};

export type StatsQueryFilters = {
  currentRange: DateRangeType;
  startDate: Date | null;
  endDate: Date | null;
  channels: Origin[];
  enabled?: boolean;
};

export type EngagementOverviewStat = {
  intervalStartDate: string;
  intervalEndDate: string;
  agents: number;
  messages: number;
  conversations: number;
};

export type AllAgentsStat = {
  agent: Agent;
  messages: number;
  conversations: number;
  views: number;
  likedMessages: number;
  dislikedMessages: number;
  likedConversations: number;
  dislikedConversations: number;
};

export const StatsIntervals = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
} as const;

export type StatsInterval = TypeFromConstObject<typeof StatsIntervals>;

export type SingleAgentEngagementStats = {
  agent: Agent;
  stats: {
    intervalStartDate: string;
    intervalEndDate: string;
    messages?: number;
    conversations?: number;
    views?: number;
    likedMessages?: number;
    dislikedMessages?: number;
    likedConversations?: number;
    dislikedConversations?: number;
  }[];
};

export const StatsMainChartSectionTabs = {
  ENGAGEMENT_OVERVIEW: "engagement_overview",
  AGENTS_PERFORMANCE: "agents_performance",
} as const;

export type StatsMainChartSectionTab = TypeFromConstObject<typeof StatsMainChartSectionTabs>;

export type StatsSingleAgentPerformanceDataType = keyof typeof SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP;

export type StatsDougnhnutChartData = ChartData<"doughnut", number[], unknown>;
