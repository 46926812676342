import { EditPublishedAgentDialog } from "@/components/dialogs/EditPublishedAgentDialog/EditPublishedAgentDialog";
import { UnpublishAgentDialog } from "@/components/dialogs/UnpublishAgentDialog/UnpublishAgentDialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import { ROUTES } from "@/constants/routes";
import { ShareAgentDialog } from "@/components/dialogs/ShareAgentDialog/ShareAgentDialog";
import { useState } from "react";
import { useNavigate } from "react-router";
import AgentTileOptionsButton from "./AgentTileOptionsButton";

type AgentTileEditOptionsProps = {
  agent: {
    _id: string;
    name: string;
  };
};

const AgentTileEditOptions = ({ agent }: AgentTileEditOptionsProps) => {
  const navigate = useNavigate();

  const [isEditAgentDialogOpen, setIsEditAgentDialogOpen] = useState(false);

  return (
    <>
      <DropdownMenu modal={false}>
        <AgentTileOptionsButton />
        <DropdownMenuContent onClick={e => e.stopPropagation()} align="start">
          <DropdownMenuItem
            className="flex items-center gap-1.5 text-xs"
            onClick={e => {
              e.preventDefault();
              setIsEditAgentDialogOpen(true);
            }}
          >
            <Icons.Edit />
            Edit Details
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex items-center gap-1.5 text-xs"
            onClick={e => {
              e.preventDefault();
              navigate(ROUTES.editAgent(agent._id));
            }}
          >
            <Icons.Agent />
            Edit Build Flow
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={e => e.preventDefault()}>
            <ShareAgentDialog
              agentId={agent._id}
              agentName={agent.name}
              renderButton={() => (
                <button className="flex w-full items-center gap-1.5 text-xs">
                  <Icons.Share />
                  Share
                </button>
              )}
            />
          </DropdownMenuItem>

          <DropdownMenuItem className="flex items-center gap-1.5 text-xs" onSelect={e => e.preventDefault()}>
            <UnpublishAgentDialog
              agentId={agent._id}
              renderButton={() => (
                <button className="flex w-full items-center gap-1.5 text-xs">
                  <Icons.Trash className="text-red-600" />
                  Unpublish
                </button>
              )}
            />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <EditPublishedAgentDialog
        agentId={agent._id}
        isDialogOpen={isEditAgentDialogOpen}
        setShowDialog={setIsEditAgentDialogOpen}
      />
    </>
  );
};

export default AgentTileEditOptions;
