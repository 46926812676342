import { Input } from "@/components/ui/input";
import { useRef } from "react";

type ColorPickerProps = {
  value: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const onColorPickerButtonClick = () => inputRef.current && inputRef.current.click();

  return (
    <Input placeholder="#FFFFFF" inputClassName="w-fit" containerClassName="w-fit" value={value} onChange={onChange}>
      <button type="button" className="absolute right-2 top-[7px]" onClick={onColorPickerButtonClick}>
        <input type="color" className="h-6 w-6 rounded-sm p-0" value={value} onChange={onChange} />
      </button>
    </Input>
  );
};
