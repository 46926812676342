import type { Origin } from "@/types/origin";
import { getStatsChannelValue } from "./getStatsChannelValue";
import { getStatsChannelsTotalValue } from "./getStatsChannelsTotalValue";

export const getStatsChannelPercentageValue = ({
  channel,
  allChannels,
}: {
  channel: Origin;
  allChannels: { value: number; channel: Origin }[];
}) => {
  const totalValue = getStatsChannelsTotalValue({ channels: allChannels });

  if (totalValue === 0) {
    return 0;
  }

  return ((getStatsChannelValue({ channel, allChannels }) / totalValue) * 100).toFixed(2);
};
