import { useState } from "react";
import {
  ExternalSource,
  KnowledgeStatus,
  type EmbedKnowledges,
  type FutureEmbedKnowledgeDocument,
  type FutureEmbedKnowledges,
} from "@/types/agent";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { restClient } from "@/data/initAxios";
import { apiPaths } from "@/data/apiPaths";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { DocumentInfoPreview } from "./DocumentInfoPreview";
import { ContinueWithGoogleButton } from "@/components/AgentForm/components/external/ContinueWithGoogleButton";
import { FolderAccordion } from "./FolderAccordion";
import { useFieldArray } from "react-hook-form";
import useDrivePicker from "react-google-drive-picker";
import type { CallbackDoc } from "react-google-drive-picker/dist/typeDefs";
import PopupController from "@/components/ExternalIntegrations/PopupController";
import { useQueryClient } from "@tanstack/react-query";
import { googleAccessToken, getGoogleAccessToken } from "@/data/queries/externalIntegrations/useGetGoogleAccessToken";
import { toast } from "react-toastify";
import { Icons } from "@/components/ui/icons";
import { FileIcon } from "./FileIcon";
import { Label } from "@/components/ui/label";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { KnowledgeStatusBadge } from "./KnowledgeStatusBadge";
import { Button } from "@/components/ui/button";
import { IconButton } from "@/components/ui/icon-button";
import { DeleteGoogleDriveDialog } from "./DeleteGoogleDriveDialog";
const SUPPORTED_MIMETYPES = [
  "application/vnd.google-apps.document",
  "application/vnd.google-apps.spreadsheet",
  "application/vnd.google-apps.presentation",
  "text/csv",
  "text/plain",
  "image/jpeg",
  "image/png",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
];

const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID as string;
const googleApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string;
const googleLoginUrl = import.meta.env.VITE_GOOGLE_LOGIN_URL as string;

export const DocumentInfoGoogleDrive = () => {
  const form = useAgentFormContext();
  const [googleDriveActivated, setGoogleDriveActivated] = useState(false); // This state is used for when the user has just registered the google drive integration

  const queryClient = useQueryClient();

  const userToken = localStorage.getItem("token");

  const [openPicker] = useDrivePicker();

  const [documentInPreview, setDocumentInPreview] = useState<EmbedKnowledges | null>(null);
  const [accordionValueOpen, setAccordionValueOpen] = useState("");
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const { control } = form;
  const agentId = form.getValues("_id");
  const externalKnowledgeIntegrations = form.getValues("externalKnowledgeIntegrations");
  const { fields: documentsData, remove: removeDocument } = useFieldArray({
    control,
    name: "embedKnowledges.doc",
  });

  const { fields: externalHelpersData, remove: removeHelper } = useFieldArray({
    control,
    name: "embedKnowledges.externalHelpers",
  });

  // Filter documentsData to include only documents with external_source "GOOGLE_DRIVE"
  const googleDriveDocuments = documentsData.filter(
    doc =>
      (doc as EmbedKnowledges)?.externalSource &&
      (doc as EmbedKnowledges)?.externalSource === ExternalSource.GOOGLE_DRIVE
  );
  const failedKnowledgeBanner = () => {
    const failedWebDataCount = googleDriveDocuments?.filter(x => x.status === KnowledgeStatus.FAILED).length;
    if (failedWebDataCount) {
      return (
        <>
          <span className="my-2 flex items-center justify-between rounded-md border border-red-500 bg-red-50 px-2 py-3 text-xs font-medium text-neutral-800">
            <div className="flex items-center">
              <Icons.Error className="ml-2" />
              <span className="pl-2">
                {failedWebDataCount === 1
                  ? `${failedWebDataCount} file was not embedded properly, please refresh or delete the source`
                  : `${failedWebDataCount} files were not embedded properly, please refresh or delete the sources`}
              </span>
            </div>
          </span>
        </>
      );
    }
  };

  const handleDisconnect = () => {
    const { google_drive, ...newKnowledgeIntegrations } = externalKnowledgeIntegrations!;
    const docsWithoutGDrive = documentsData.filter(doc => !googleDriveDocuments.some(gDoc => doc.id === gDoc.id));
    form.setValue("externalKnowledgeIntegrations", newKnowledgeIntegrations, { shouldDirty: true });
    form.setValue("embedKnowledges.doc", docsWithoutGDrive, { shouldDirty: true });
    const allFolderHelpersIndexes = externalHelpersData.map((_, i) => i);
    removeHelper(allFolderHelpersIndexes);
  };

  const handleOpenPicker = async () => {
    try {
      const accessToken = await queryClient.fetchQuery({
        queryKey: googleAccessToken.id(agentId),
        queryFn: () => getGoogleAccessToken({ agentId }),
      });

      openPicker({
        clientId: googleClientId,
        developerKey: googleApiKey,
        viewId: "DOCS",
        viewMimeTypes: SUPPORTED_MIMETYPES.join(","),
        setParentFolder: "root",
        setSelectFolderEnabled: true,
        setIncludeFolders: true,
        customScopes: [
          "https://www.googleapis.com/auth/userinfo.email",
          "https://www.googleapis.com/auth/userinfo.profile",
          "openid",
          "https://www.googleapis.com/auth/drive.metadata.readonly",
          "https://www.googleapis.com/auth/drive.readonly",
        ],
        token: accessToken.accessToken,
        supportDrives: true,
        multiselect: true,
        // customViews: customViewsArray, // custom view
        callbackFunction: async data => {
          if (data.action === "picked") {
            const duplicateDocs = data?.docs?.filter(doc =>
              googleDriveDocuments.some(
                existingDoc => existingDoc?.externalId === doc.id && existingDoc?.filename === doc.name
              )
            );
            if (duplicateDocs.length > 0) {
              const duplicateNames = duplicateDocs.map(doc => doc.name).join(", ");
              toast.error(`${duplicateNames} already exists in your documents`);
              //remove the duplicates from the list docs
              data.docs = data.docs.filter(doc => !duplicateDocs.some(duplicateDoc => duplicateDoc.id === doc.id));
            }
            const duplicateFolders = data?.docs?.filter(doc =>
              googleDriveDocuments.some(existingDoc => existingDoc?.parentId === doc.id)
            );
            if (duplicateFolders.length > 0) {
              const duplicateFolderNames = duplicateFolders.map(doc => doc.name).join(", ");
              toast.error(`${duplicateFolderNames} folder already exists in your documents`);
              //remove the duplicates from the list docs
              data.docs = data.docs.filter(
                doc => !duplicateFolders.some(duplicateFolder => duplicateFolder.id === doc.id)
              );
            }
            await addResources(data.docs);
          }
        },
      });
    } catch (e) {
      console.log(e);
      toast.error("Error opening Google Drive");
    }
  };

  const removeFolder = (folderId: string) => {
    const folderIndexInExternalHelpers = externalHelpersData.findIndex(doc => doc.externalId === folderId);
    if (folderIndexInExternalHelpers === -1) {
      removeUnpersistedFolder(folderId);
      return;
    }

    // It isn't a new folder, so we need to remove all the documents that are in the folder
    const docsIndex = googleDriveDocuments.reduce((acc, doc, index) => {
      if ("externalSource" in doc && doc.externalSource === ExternalSource.GOOGLE_DRIVE && doc.parentId === folderId) {
        return [...acc, index];
      }
      return acc;
    }, [] as number[]);
    removeHelper(folderIndexInExternalHelpers);
    removeDocument(docsIndex);
  };

  const removeUnpersistedFolder = (folderId: string) => {
    const folderIndex = googleDriveDocuments.findIndex(doc => doc.externalId === folderId);
    removeDocument(folderIndex);
    return;
  };

  const removeFile = (externalId: string) => {
    const fileIndex = googleDriveDocuments.findIndex(doc => doc.externalId === externalId);
    removeDocument(fileIndex);
    const updatedDocuments = googleDriveDocuments.filter(doc => doc.externalId !== externalId);
    form.setValue("embedKnowledges.doc", updatedDocuments, {
      shouldDirty: true,
    });
  };

  const filesByFolderId = googleDriveDocuments.reduce(
    (acc, doc) => {
      if ("externalSource" in doc && doc.externalSource === ExternalSource.GOOGLE_DRIVE) {
        const parentDocs = acc[doc.parentId!] ?? [];
        acc[doc.parentId!] = [
          ...parentDocs,
          {
            ...doc,
            name: doc.filename,
            filename: doc.source,
            id: doc.externalId,
            folderId: doc?.parentId,
          } as EmbedKnowledges,
        ];
      }
      return acc;
    },
    {} as Record<string, EmbedKnowledges[]>
  );

  // Properly format the data for the FolderAccordion component
  const newFoldersWithChildrenFilter = googleDriveDocuments.filter(
    doc => "isNewFolder" in doc && doc.isNewFolder
  ) as FutureEmbedKnowledges[];

  const newFoldersWithChildren = newFoldersWithChildrenFilter?.map(folder => ({
    externalId: folder.externalId,
    folderId: folder.folderId,
    name: folder.name,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
    children: folder?.children.map((child: any) => ({
      ...child,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      name: child?.name as string,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      filename: child.source as string,
    })) as unknown as EmbedKnowledges[],
  }));

  const knownFoldersWithChildrenFilter = externalHelpersData.filter(doc => !("isNewFolder" in doc));
  const knownFoldersWithChildren = knownFoldersWithChildrenFilter.map(helper => ({
    externalId: helper.externalId,
    folderId: helper?.folderId,
    name: helper.name,
    children: filesByFolderId[helper.externalId] ?? [],
  }));
  const filesWithoutKnownFolders = googleDriveDocuments.filter(
    doc =>
      !("isNewFolder" in doc) &&
      !knownFoldersWithChildren.some(folder => "parentId" in doc && folder.externalId === doc.parentId)
  );

  const addResources = async (docs: CallbackDoc[]) => {
    let updatedDocumentData: (EmbedKnowledges | FutureEmbedKnowledges | FutureEmbedKnowledgeDocument)[] = [
      ...googleDriveDocuments,
    ];

    if (!docs) {
      return;
    }
    if (docs?.length === 0) {
      return;
    }
    for (const doc of docs) {
      const { id, mimeType } = doc;
      // Check if the document is a folder
      if (mimeType === "application/vnd.google-apps.folder") {
        const folderExists = foldersWithChildren.some(folder => folder.externalId === id);
        if (folderExists) {
          continue;
        }

        const { data } = await restClient.post<FutureEmbedKnowledges>(apiPaths.readDriveId, { resourceId: id });
        const folderData = {
          isNewFolder: true,
          ...data,
          externalId: id,
          id,
          folderId: id,
          externalSource: ExternalSource.GOOGLE_DRIVE,
        };

        // Don't add files that are already in the list
        const uniqueDocumentData = (updatedDocumentData as EmbedKnowledges[]).filter(doc => doc.parentId !== id);

        updatedDocumentData = [...uniqueDocumentData, folderData];
      } else {
        updatedDocumentData = [
          ...updatedDocumentData,
          {
            externalId: id,
            filename: doc.name,
            name: doc.name,
            mimeType: doc.mimeType,
            externalSource: ExternalSource.GOOGLE_DRIVE,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
            parentId: (doc as any).parentId as string, // parentId DOES exist in the CallbackDoc type, but the library doesn't have it in the types
          },
        ];
      }
    }

    form.setValue("embedKnowledges.doc", updatedDocumentData, {
      shouldDirty: true,
    });
    setAccordionValueOpen("online-info");
  };

  const foldersWithChildren = [...newFoldersWithChildren, ...knownFoldersWithChildren];

  return (
    <>
      {externalKnowledgeIntegrations?.google_drive || googleDriveActivated ? (
        <>
          <Label>Select a file or folder</Label>
          <div className="flex gap-2">
            <Button type="submit" className="w-full sm:w-fit" onClick={handleOpenPicker}>
              Open Google Drive
            </Button>
            <Button variant="danger" size="tiny" className="sm:w-fit" onClick={() => setRemoveDialogOpen(true)}>
              Disconnect Google Drive
            </Button>
          </div>

          <Accordion
            type="single"
            collapsible
            className="rounded-md border border-slate-200"
            value={accordionValueOpen}
            onValueChange={setAccordionValueOpen}
          >
            <AccordionItem value="online-info">
              <AccordionTrigger className="px-4 text-xs font-medium text-neutral-800">
                <div className="flex h-4 min-w-[16px] items-center justify-center rounded-full bg-primary-500 px-1 text-[10px] font-medium leading-none text-white">
                  {googleDriveDocuments.length}
                </div>
                <div className="ml-2 mr-auto">List of added files</div>
              </AccordionTrigger>

              <AccordionContent className="flex flex-col bg-neutral-50 p-2">
                {failedKnowledgeBanner()}
                <FolderAccordion
                  folders={foldersWithChildren}
                  removeFolder={removeFolder}
                  setDocumentInPreview={setDocumentInPreview}
                />
                {filesWithoutKnownFolders?.map(file => (
                  <div
                    key={file.externalId}
                    className="my-1 flex w-full items-center justify-between gap-2 rounded-md border border-slate-200 bg-white pb-1 pr-3 pt-1 hover:bg-primary-50"
                  >
                    <div className="flex items-center gap-1">
                      <div className="ml-4 min-w-5">
                        <FileIcon fileName={(file as EmbedKnowledges).filename} />
                      </div>
                      <div className="ml-2 flex flex-col gap-1 py-2">
                        {file?.status == KnowledgeStatus.SUCCESS ? (
                          (file as EmbedKnowledges)?.source ? (
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <p
                                  onClick={() => setDocumentInPreview(file as EmbedKnowledges)}
                                  className="break-all pr-2 text-xs font-bold text-neutral-800 hover:text-neutral-500 hover:underline"
                                >
                                  {file.name || (file as EmbedKnowledges).filename}
                                </p>
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="mb-2 flex justify-between gap-5">
                                  <span className="text-base font-bold">AI Summary</span>
                                  <button
                                    onClick={() => setDocumentInPreview(file as EmbedKnowledges)}
                                    className="text-sm font-medium text-primary-200"
                                  >
                                    (Click to preview)
                                  </button>
                                </div>
                                <p className="line-clamp-2 text-xs font-medium">
                                  {(file as EmbedKnowledges).description || "Unable to generate summary for this file."}
                                </p>
                              </TooltipContent>
                            </Tooltip>
                          ) : (
                            <p className="break-all pr-2 text-xs font-bold text-neutral-400">
                              (NEW) {file.name || (file as EmbedKnowledges).filename}
                            </p>
                          )
                        ) : (
                          <p className="break-all pr-2 text-xs font-bold text-neutral-400">
                            {file.name || (file as EmbedKnowledges).filename}
                          </p>
                        )}
                      </div>
                      {file?.status && <KnowledgeStatusBadge status={file.status} />}
                    </div>

                    <IconButton
                      type="button"
                      variant="tertiary"
                      size="tiny"
                      icon={<Icons.Trash className="text-neutral-400" />}
                      onClick={() => removeFile(file.externalId!)}
                    />
                  </div>
                ))}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
          <DocumentInfoPreview setDocumentInPreview={setDocumentInPreview} documentInPreview={documentInPreview} />
          <DeleteGoogleDriveDialog
            isDialogOpen={removeDialogOpen}
            setShowDialog={setRemoveDialogOpen}
            onDisconnect={handleDisconnect}
          />
        </>
      ) : (
        <div>
          <p>This agent isn't connected to Google Drive. Use the button below to authenticate:</p>
          <PopupController
            popupUrl={googleLoginUrl + "?token=" + userToken + "&agentId=" + agentId}
            onSuccess={() => setGoogleDriveActivated(true)}
          >
            <ContinueWithGoogleButton />
          </PopupController>
        </div>
      )}
    </>
  );
};
