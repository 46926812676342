import { Avatar } from "@/components/Avatar";
import { useGetAgents } from "@/data/queries/useGetAgents";
import { cn } from "@/lib/utils";
import type { Agent } from "@/types/agent";
import { useEffect, useState } from "react";
import { StatsMainChartHeader } from "./StatsMainChartHeader";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icons } from "@/components/ui/icons";
import { LinkButton } from "@/components/ui/link-button";
import { StatsAllAgentsPerformance } from "./StatsAllAgentsPerformance/StatsAllAgentsPerformance";
import { StatsSingleAgentPerformance } from "./StatsSingleAgentPerformance/StatsSingleAgentPerformance";
import { StatsIntervals } from "@/types/stats";
import type { StatsSingleAgentPerformanceDataType, StatsInterval, StatsMainChartSectionTab } from "@/types/stats";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import { ALL_AGENTS_STATS_LEGEND } from "../constants/statsConstants";
import StatsAllDataIcon from "./common/StatsAllDataIcon";
import { StatsIntervalPicker } from "./StatsIntervalPicker";
import { isAgentInsideArray } from "@/lib/isAgentInsideArray";
import StatsDataTypeIcon from "./common/StatsDataTypeIcon";
import StatsSingleAgentPerformanceDataTypePicker from "./StatsSingleAgentPerformance/StatsSingleAgentPerformanceDataTypePicker";

type Props = {
  activeSectionTab: StatsMainChartSectionTab;
  onActiveSectionChange: (section: StatsMainChartSectionTab) => void;
};

export const StatsAgentsPerformace = ({ activeSectionTab, onActiveSectionChange }: Props) => {
  const { selectedAgents, setSelectedAgents, isUpgradeNeeded } = useStatsContext();
  const [selectedDataType, setSelectedDataType] = useState<StatsSingleAgentPerformanceDataType | null>(null);
  const [interval, setInterval] = useState<StatsInterval>(StatsIntervals.DAILY);

  const { data: allAgents } = useGetAgents({
    enabled: !isUpgradeNeeded,
  });

  const getIsAgentSelected = (agentId: Agent["_id"]) => isAgentInsideArray(agentId, selectedAgents);
  const toggleAgentSelection = (agentId: Agent["_id"]) => {
    setSelectedAgents(prevSelectedAgents =>
      prevSelectedAgents.some(agent => agent._id === agentId)
        ? prevSelectedAgents.filter(selectedAgent => selectedAgent._id !== agentId)
        : [...prevSelectedAgents, allAgents!.find(agent => agent._id === agentId)!]
    );
  };

  useEffect(() => {
    if (allAgents) {
      setSelectedAgents(allAgents);
    }
  }, [allAgents, setSelectedAgents]);

  const areAllAgentsSelected = allAgents?.length === selectedAgents.length;
  const isNoAgentSelected = selectedAgents.length === 0;
  const isSingleAgentSelected = selectedAgents.length === 1;

  const handleSelectAllAgents = () => {
    setSelectedAgents(allAgents ?? []);
  };
  const handleUnselectAllAgents = () => {
    setSelectedAgents([]);
  };

  const getAgentsDropdownTitle = () => {
    if (isNoAgentSelected) {
      return "Select Agents";
    }

    if (areAllAgentsSelected) {
      return "All Agents";
    }

    if (selectedAgents.length === 1) {
      return (
        <div className="flex items-center gap-1">
          <Avatar src={selectedAgents[0].avatar} size="custom" className={cn("h-5 w-5")} />
          {selectedAgents[0].name}
        </div>
      );
    }

    const maxItemsToShow = 4;
    const exceededNumber = selectedAgents.length - maxItemsToShow;
    const isExceeded = exceededNumber > 0;

    return (
      <div className="flex items-center gap-1">
        {selectedAgents.slice(0, 4).map(agent => (
          <Avatar key={agent._id} src={agent.avatar} size="custom" className={cn("h-5 w-5")} />
        ))}
        {isExceeded && <p className="text-sm font-medium">{`+${exceededNumber}`}</p>}
      </div>
    );
  };

  const getDataTypeDropdownIcon = () => {
    if (!selectedDataType) {
      return <StatsAllDataIcon />;
    }

    return <StatsDataTypeIcon selectedDataType={selectedDataType} />;
  };

  const getDataTypeDropdownTitle = () => {
    if (!selectedDataType) {
      return "All data";
    }

    return ALL_AGENTS_STATS_LEGEND[selectedDataType].label;
  };

  return (
    <>
      <StatsMainChartHeader activeSectionTab={activeSectionTab} onActiveSectionChange={onActiveSectionChange}>
        <div className="flex items-center gap-2">
          {isSingleAgentSelected && (
            <StatsSingleAgentPerformanceDataTypePicker
              onChangeSelectedDataType={setSelectedDataType}
              triggerIcon={getDataTypeDropdownIcon()}
              triggerTitle={getDataTypeDropdownTitle()}
            />
          )}

          <DropdownMenu modal={false}>
            <DropdownMenuTrigger
              disabled={isUpgradeNeeded}
              className="flex items-center gap-1 rounded-lg px-3 py-1 text-sm shadow-sm"
            >
              {getAgentsDropdownTitle()}
              <Icons.ChevronRight className="h-4 w-4 rotate-90" />
            </DropdownMenuTrigger>
            <DropdownMenuContent
              onClick={e => e.stopPropagation()}
              align="end"
              className="flex max-w-52 flex-col items-end gap-1 border border-primary-400 p-2.5"
            >
              <div className="mb-1 flex flex-wrap gap-1">
                {allAgents?.map(agent => {
                  const isSelected = getIsAgentSelected(agent._id);

                  return (
                    <DropdownMenuItem
                      key={agent._id}
                      className={cn(
                        "flex cursor-pointer items-center gap-1 rounded-[4px] border border-neutral-200 bg-neutral-100 px-2 py-[2px] text-neutral-500",
                        {
                          "border-primary-400 bg-primary-100 text-neutral-600": isSelected,
                        }
                      )}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        toggleAgentSelection(agent._id);
                      }}
                    >
                      <Avatar
                        src={agent.avatar}
                        size="custom"
                        className={cn("h-5 w-5", {
                          "grayscale filter": !isSelected,
                        })}
                      />
                      {agent.name}
                    </DropdownMenuItem>
                  );
                })}
              </div>

              {isNoAgentSelected ? (
                <LinkButton onClick={handleSelectAllAgents}>Select all</LinkButton>
              ) : (
                <LinkButton onClick={handleUnselectAllAgents}>Unselect all</LinkButton>
              )}
            </DropdownMenuContent>
          </DropdownMenu>

          {isSingleAgentSelected && (
            <StatsIntervalPicker statsInterval={interval} onStatsIntervalChange={setInterval} />
          )}
        </div>
      </StatsMainChartHeader>

      {isSingleAgentSelected ? (
        <StatsSingleAgentPerformance
          agentId={selectedAgents[0]._id}
          interval={interval}
          selectedDataType={selectedDataType}
        />
      ) : (
        <StatsAllAgentsPerformance />
      )}
    </>
  );
};
