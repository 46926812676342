import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import type { FullAgent } from "@/types/agent";
import type { F1 } from "@/types/types";
import { Button } from "@/components/ui/button";
import { useGetWorkspaceUsers } from "@/data/queries/workspace/useGetWorkspaceUsers";
import { useState } from "react";
import { useChangeAgentCreator } from "@/data/mutations/workspace/useChangeAgentCreator";
import { AgentInfo } from "@/components/dialogs/TransferOwnershipAgentDialog/AgentInfo";
import { AgentOwnerSelect } from "@/components/dialogs/TransferOwnershipAgentDialog/AgentOwnerSelect";

type TransferOwnershipAgentDialogProps = {
  agent: FullAgent | null;
  setAgent: F1<FullAgent | null>;
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
};

export const TransferOwnershipAgentDialog = ({
  agent,
  setAgent,
  isDialogOpen,
  setShowDialog,
}: TransferOwnershipAgentDialogProps) => {
  const { data: members } = useGetWorkspaceUsers();
  const { mutate: changeAgentCreator, isPending } = useChangeAgentCreator();
  const [selectedMember, setSelectedMember] = useState<string | undefined>();

  const onChangeAgentCreator = () => {
    if (!agent || !selectedMember) {
      return;
    }
    changeAgentCreator(
      { agentId: agent._id, userId: selectedMember },
      {
        onSuccess: () => {
          setShowDialog(false);
          setAgent(null);
          setSelectedMember(undefined);
        },
      }
    );
  };

  const toggleDialog = (open: boolean) => {
    setShowDialog(open);
    setAgent(null);
    setSelectedMember(undefined);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={toggleDialog}>
      <DialogContent variant="medium" className="h-[350px]">
        <DialogHeader />
        <div className="flex w-full flex-col items-start gap-6 px-8 py-4">
          <div className="flex flex-col gap-2.5">
            <div className="text-xl font-bold">Transfer Agent Ownership</div>
            <div className="text-sm font-medium text-neutral-400">
              Select a new owner to transfer ownership of the following Agent
            </div>
          </div>
          <div className="grid w-full grid-cols-2 gap-4">
            <AgentInfo agent={agent} />
            <div className="flex flex-col gap-1">
              <div className="text-sm font-medium">New Owner</div>
              <AgentOwnerSelect
                options={members}
                agentCreatorId={agent?.creator || ""}
                value={selectedMember}
                onValueChange={value => setSelectedMember(value)}
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={onChangeAgentCreator} loading={isPending}>
            Confirm
          </Button>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
