import { useGetChatHistoryWithAgents } from "@/data/queries/useGetChatHistoryWithAgents";
import { useMemo, useState } from "react";
import { AgentConversations } from "./AgentConversations";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { DeleteChatDialog } from "@/pages/Community/components/DeleteChatDialog";
import BookmarkChatHistoryWrapper from "./BookmarkChatHistoryWrapper";
import BookmarkHistoryAgentTileWrapper from "./BookmarkHistoryAgentTileWrapper";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useGetUser } from "@/data/queries/useGetUser";

interface ChatHistoryProps {
  onClosePanel?: () => void;
}

export const ChatHistory = ({ onClosePanel }: ChatHistoryProps) => {
  const { user } = useGetUser();

  const [searchInput, setSearchInput] = useState("");
  const [isDeleteChatDialogOpen, setIsDeleteChatDialogOpen] = useState(false);
  const [conversationIdToDelete, setConversationIdToDelete] = useState<string | null>(null);
  const { data, isLoading } = useGetChatHistoryWithAgents({
    enabled: !!user,
  });
  const { conversationAgentIds } = useChatContext();

  const filteredAgents = useMemo(
    () =>
      data?.filter(({ agents }) =>
        agents.some(agent => agent?.name?.toLocaleLowerCase().includes(searchInput.toLowerCase()))
      ),
    [data, searchInput]
  );

  if (isLoading) {
    return <LoadingSpinner className="flex items-center justify-center pt-12" />;
  }

  return (
    <BookmarkChatHistoryWrapper
      searchInput={searchInput}
      onSearchInputChange={setSearchInput}
      inputPlaceholder="Search Agents"
    >
      <div className="flex flex-col gap-6">
        {filteredAgents?.map(({ conversationsCount, agents }) => (
          <BookmarkHistoryAgentTileWrapper
            key={agents.map(agent => agent._id).join("-")}
            agents={agents}
            threadsCount={conversationsCount}
            label="history"
            defaultItemIds={conversationAgentIds}
          >
            <AgentConversations
              agentIds={agents.map(agent => agent._id)}
              setIsDeleteChatDialogOpen={setIsDeleteChatDialogOpen}
              setConversationIdToDelete={setConversationIdToDelete}
              onClosePanel={onClosePanel}
            />
          </BookmarkHistoryAgentTileWrapper>
        ))}
      </div>
      <DeleteChatDialog
        isDialogOpen={isDeleteChatDialogOpen}
        setShowDialog={setIsDeleteChatDialogOpen}
        conversationIdToDelete={conversationIdToDelete}
        setConversationIdToDelete={setConversationIdToDelete}
      />
    </BookmarkChatHistoryWrapper>
  );
};
