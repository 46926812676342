import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
export const WhatsNew = () => {
  return (
    <div className="flex h-full flex-col gap-3 px-6 py-4 lg:ml-auto lg:w-1/3 lg:border-l lg:border-neutral-200">
      <h4 className="pb-2 text-2xl font-bold">FAQs</h4>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="howItWorks" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">What is AI Agent and what does AgentX do?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            AI Agent is an autonomous system powered by large language model, complemented by several key components,
            such as goal driven task planning, short-term and long-term memory, and capable to use tools. AgentX is a
            platform that offers all the necessary tool sets for users to build their AI agents.
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="howItWorks" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">Do I need coding skills to build an AI Agent?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            Not required. AgentX offers a no-code solution, allowing users to build and tune their AI agents using
            natural language without any programming skill. However, we offer advanced tool for users who are
            comfortable with coding to achieve more customized results.
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="howItWorks" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">What model can I use to build an AI agent?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            AgentX provides multi-model mix match building experience. You can choose LLM from different vendor as you
            like, such as OpenAI GPT3.5, GPT4, Google Gemini pro, and Anthropic Claude. You can also empower your AI
            Agent with Stable Diffusion XL image generation engine. We work hard to integrate more powerful models for
            you to pick. Feel free to send us any suggestions at contact@agentx.so
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="howToEmbed" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">How do I embed my AI Agent into my website?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            Embedding your AI Agent into your website is quick and easy. Simply copy the provided script snippet from
            our step-by-step tutorial and paste it into your site's HTML code. To learn more, check out our blog post:
            <br />
            <a
              href="https://www.agentx.so/agentx-tutorial/how-to-deploy-an-ai-agent-to-your-website"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              How to Deploy an AI Agent to Your Website
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="whatsappBusiness" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">How do I deploy an AI Agent to my WhatsApp Business account?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            Deploying your AI Agent to a WhatsApp Business account is straightforward. Our tutorial walks you step by
            step through the setup process. Check it out here:
            <br />
            <a
              href="https://www.agentx.so/agentx-tutorial/how-to-deploy-ai-agents-to-whatsapp-business-account"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              How to Deploy AI Agents to a WhatsApp Business Account
            </a>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="singleField" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">How do I make an AI Agent that only talks about my specific business topic?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            Your AI Agent will only use the knowledge you provide. By supplying topic-specific documents, FAQs, or data
            relevant to your industry or specialty, you ensure it focuses solely on that field. The more targeted your
            content and prompts, the more specialized your agent will be.
            <br />
            <br />
            For guidance on implementing guardrails using LLM prompting, check out:
            <br />
            <a
              href="https://www.agentx.so/agentx-tutorial/how-to-guardrail-ai-agent-with-llm-prompting"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              How to Guardrail an AI Agent with LLM Prompting
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="pdfToJson" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">
              How do I create an AI Agent that extracts data from my PDF and turns it into JSON?
            </div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            To build an AI Agent capable of parsing data from PDFs and converting it into JSON, check out our
            step-by-step tutorial:
            <br />
            <a
              href="https://www.agentx.so/post/How-to-create-an-AI-agent-that-extracts-data-from-my-PDF-and-turn-it-into-JSON"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              How to Create an AI Agent That Extracts Data from My PDF and Turns It into JSON
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="ragEmployeeTraining" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">
              How can I empower my employee training with Retrieval-Augmented Generation (RAG)?
            </div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            Learn how Retrieval-Augmented Generation (RAG) can transform employee training by providing relevant,
            on-demand knowledge. For more details and a step-by-step guide, check out our blog post:
            <br />
            <a
              href="https://www.agentx.so/post/Empowering-Employee-Training-with-RAG"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              Empowering Employee Training with RAG
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="midjourneyDiscord" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">How do I create my Midjourney Discord server without coding, for free?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            If you’re looking to set up a Midjourney Discord server at no cost and without coding, check out our
            comprehensive guide:
            <br />
            <a
              href="https://www.agentx.so/post/how-to-create-your-midjourney-discord-server-no-code-for-free"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              How to Create Your Midjourney Discord Server No-Code for Free
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="zapierWebhook" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">How do I generate leads and send them to a Zapier Webhook?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            If you want to capture leads and automatically send them to a Zapier Webhook, follow our step-by-step
            tutorial to get set up:
            <br />
            <a
              href="https://www.agentx.so/agentx-tutorial/how-to-generate-lead-and-send-to-zapier-webhook"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              How to Generate Leads and Send Them to a Zapier Webhook
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="multilingualVoice" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">
              How do I build a powerful AI Agent that understands multiple languages with voice input?
            </div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            If you want to create an AI Agent that accepts voice inputs and supports multiple languages, check out:
            <br />
            <a
              href="https://www.agentx.so/post/multilingual-voice-input-build-a-powerful-ai-agent-that-understands-multiple-languages"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              Multilingual Voice Input: Build a Powerful AI Agent That Understands Multiple Languages
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="slackAgent" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">How do I create AI Agent chatbots for Slack?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            To set up AI Agent chatbots in your Slack workspace, follow our in-depth tutorial:
            <br />
            <a
              href="https://www.agentx.so/post/How-To-Create-AI-Agent-Chatbots-For-Slack"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              How to Create AI Agent Chatbots for Slack
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="engagingChatbot" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">What are the best tips to make my AI Agent chatbot more engaging and human?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            Discover 6 practical tips to enhance your AI Agent chatbot’s engagement, making it feel more human. Read our
            blog post for actionable insights:
            <br />
            <a
              href="https://www.agentx.so/post/6-Best-Tips-To-Make-Your-AI-Agent-Chatbot-More-Engaging-And-More-Human"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              6 Best Tips To Make Your AI Agent Chatbot More Engaging And More Human
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="imageDisplay" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">How do I enable image display in chats for my AI Agent?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            Easily configure your AI Agent to display images in the chat window by following our step-by-step guide:
            <br />
            <a
              href="https://www.agentx.so/post/easy-steps-to-enable-image-display-in-chats-for-your-ai-agent-a-setup-guide"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              Easy Steps to Enable Image Display in Chats for Your AI Agent: A Setup Guide
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="multipleLLMs" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">How do I test multiple LLMs or AI Agents at the same time?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            Comparing multiple Large Language Models or AI Agents side by side is an effective way to evaluate
            performance and features. Check out our blog post for a comprehensive guide:
            <br />
            <a
              href="https://www.agentx.so/post/How-To-Test-Multiple-LLMs-or-AI-Agents-At-The-Same-Time"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              How to Test Multiple LLMs or AI Agents at the Same Time
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="single" collapsible className="rounded-md border border-slate-200">
        <AccordionItem value="zapierIntegration" className="rounded-md bg-neutral-50">
          <AccordionTrigger className="p-3 text-base font-bold">
            <div className="text-left">How can I integrate AgentX with Zapier?</div>
          </AccordionTrigger>
          <AccordionContent className="p-3 pt-1 text-xs leading-5">
            With AgentX’s Zapier integration, you can automate workflows by triggering and connecting actions across
            multiple platforms. To get started, follow our step-by-step guide:
            <br />
            <a
              href="https://www.agentx.so/agentx-tutorial/zapier-integration-overview"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              Zapier Integration Overview
            </a>
            .
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
