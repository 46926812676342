import type { PropsWithChildren } from "react";
import { useGetUser } from "@/data/queries/useGetUser";
import { useWebsocket } from "@/hooks/useWebsocket";
import { notificationsKeys } from "@/data/queries/useGetNotifications";

export const WebsocketContextProvider = ({ children }: PropsWithChildren) => {
  const token = localStorage.getItem("token");
  const { user } = useGetUser({ enabled: !!token });

  useWebsocket({
    isGuestUser: !user,
    websocketEndpoint: "notification",
    queryKeys: [notificationsKeys.all],
  });

  return <>{children}</>;
};
