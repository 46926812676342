import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { conversationMessagesKeys } from "@/data/queries/useGetConversationMessages";
import type { AudioData, ConversationMessagesResponse, Message } from "@/types/conversation";
import type { InfiniteData } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { memo, useEffect, useRef, useState } from "react";

type VoiceMessagePlaceholderProps = {
  messageId: Message["_id"];
  conversationId: Message["conversationId"];
  audio: AudioData;
};
const _VoiceMessage = ({ audio, messageId, conversationId }: VoiceMessagePlaceholderProps) => {
  const queryClient = useQueryClient();
  const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(null);
  const [_, setReRenderTime] = useState<Date>();
  const animationIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const autoplayRef = useRef<boolean>(false);

  const durationInSeconds = audio.duration / 1000;
  const flooredDuration = Math.floor(durationInSeconds);

  useEffect(() => {
    if (audio.blob || audio.url) {
      const url = audio.url ?? URL.createObjectURL(audio.blob!);
      const newAudioElement = new Audio(url);
      setAudioElement(newAudioElement);
      if (autoplayRef.current) {
        void newAudioElement.play();
        autoplayRef.current = false;
      }
      const handleTimeEnd = () => {
        cancelAnimation();
        setTimeout(() => {
          newAudioElement.currentTime = 0;
          setReRenderTime(new Date());
        }, 500);
      };

      const handleStartPlaying = () => {
        animationIntervalRef.current = setInterval(() => {
          requestAnimationFrame(() => {
            setReRenderTime(new Date());
          });
        }, 1000 / 30);
      };

      const cancelAnimation = () => {
        setReRenderTime(new Date());
        if (animationIntervalRef.current) {
          clearInterval(animationIntervalRef.current);
        }
      };

      newAudioElement.addEventListener("ended", handleTimeEnd);
      newAudioElement.addEventListener("play", handleStartPlaying);
      newAudioElement.addEventListener("pause", cancelAnimation);

      return () => {
        newAudioElement.removeEventListener("ended", handleTimeEnd);
        newAudioElement.removeEventListener("play", handleStartPlaying);
        newAudioElement.removeEventListener("pause", cancelAnimation);
        cancelAnimation();
        audio.blob && URL.revokeObjectURL(url);
      };
    }
  }, [audio]);

  const handlePlay = () => {
    if (audioElement) {
      if (!audioElement.src) {
        console.error("Audio source is missing");
        return;
      }

      void audioElement.play().catch(error => {
        console.warn("Playback failed. User interaction may be required:", error);
      });
      return;
    }
    handleConvertBase64ToBlob();
  };

  const handlePause = () => {
    audioElement?.pause();
  };

  const renderDuration = () => {
    if (durationInSeconds >= 60) {
      return "1:00";
    }
    if (audioElement) {
      const { currentTime } = audioElement;
      const duration =
        audioElement.paused && !currentTime ? flooredDuration : flooredDuration - Math.floor(currentTime);

      return "0:" + duration.toString().padStart(2, "0");
    }

    return "0:" + flooredDuration.toString().padStart(2, "0");
  };

  const updateMessageInCache = (audio: AudioData) => {
    queryClient.setQueryData<InfiniteData<ConversationMessagesResponse>>(
      conversationMessagesKeys.id({ conversationId }, true),
      prev => {
        if (!prev) {
          return prev;
        }

        const newPages = prev.pages.map(page => {
          const messageIndex = page.messages.findIndex(message => message._id === messageId);

          if (messageIndex !== -1) {
            const newMessages = [...page.messages];
            newMessages[messageIndex] = { ...newMessages[messageIndex], audio };

            return { ...page, messages: newMessages };
          }

          return page;
        });

        return { ...prev, pages: newPages };
      }
    );
  };
  const handleConvertBase64ToBlob = () => {
    if (audio.base64) {
      const uInt8Array = Uint8Array.from(atob(audio.base64), c => c.charCodeAt(0));
      const audioBlob = new Blob([uInt8Array], { type: "audio/webm; codecs=opus" });
      updateMessageInCache({ ...audio, blob: audioBlob });
      autoplayRef.current = true;
    }
  };

  return (
    <div className="flex w-fit items-center gap-2 rounded-md border border-neutral-200 p-3">
      {audioElement && !audioElement.paused && audioElement.currentTime ? (
        <IconButton
          size="custom"
          variant="tertiary"
          icon={<Icons.PauseClip className="text-primary-black" />}
          onClick={handlePause}
        >
          <span className="sr-only">Pause voice message</span>
        </IconButton>
      ) : (
        <IconButton
          size="custom"
          variant="tertiary"
          onClick={handlePlay}
          icon={<Icons.PlayClip className="text-primary-black" />}
        >
          <span className="sr-only">Play voice message</span>
        </IconButton>
      )}
      <div className="relative">
        <Icons.VoiceMessageBars className="text-neutral-300" />
        {!!audioElement && (
          <div
            style={{ width: `${(audioElement.currentTime / durationInSeconds) * 100}%` }}
            className="absolute left-0 top-0 max-w-full overflow-x-hidden"
          >
            <Icons.VoiceMessageBars className="text-neutral-600" />
          </div>
        )}
      </div>
      <span className="text-xs font-medium">{renderDuration()}</span>
    </div>
  );
};

export const VoiceMessage = memo(_VoiceMessage);
