import { useGetUser } from "@/data/queries/useGetUser";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { Icons } from "@/components/ui/icons";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import { LoginRoutesMobile } from "@/components/Routes/LoginRoutesMobile";
import { AGENT_X_VIDEO_URL } from "@/constants/images";

export const LoginRoutesDesktop = () => {
  const token = localStorage.getItem("token");
  const { user } = useGetUser({ enabled: !!token });
  const isScreenXl = useTailwindMediaQuery("xl");

  if (user) {
    return <Navigate to={ROUTES.workspace} replace />;
  }

  if (!isScreenXl) {
    return <LoginRoutesMobile />;
  }

  return (
    <div className="flex size-full h-dvh w-dvw max-w-none flex-1 overflow-hidden">
      <div className="flex h-full w-[60%] items-center justify-center bg-gradient-to-b from-[#BDA7FF] from-0% via-[#C3AFFF] via-5% to-[#EDD3B9] to-90% 2xl:w-[70%]">
        <div className="flex flex-col items-center gap-10">
          <Icons.AgentLogo className="text-gr max-h-[32px] w-[200px] text-neutral-750" />
          <iframe
            src={AGENT_X_VIDEO_URL}
            allow="autoplay"
            allowFullScreen
            className="h-[365.63px] w-[650px] rounded-xl bg-gradient-to-t from-[#F7F5DA] to-[#EBE6F6]"
          ></iframe>
          <span className="font-medium text-neutral-750">
            Explore new features! Start building your AI agent with AgentX today.
          </span>
        </div>
      </div>
      <div className="flex h-full w-[40%] flex-col items-start gap-44 bg-gradient-to-b from-primary-50 to-[#FDF8F4] p-12 2xl:w-[30%]">
        <div className="flex h-full w-80 flex-col items-start justify-center">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
