import { useLoginUser } from "@/data/mutations/useLoginUser";
import { LoginForm } from "@/components/LoginForm/LoginForm";
import { ROUTES } from "@/constants/routes";
import { useNavigate } from "react-router";

export const Login = () => {
  const navigate = useNavigate();

  const { mutate, isPending, error } = useLoginUser();

  const handleSubmitForm = ({
    email,
    password,
    recaptchaToken,
  }: {
    email: string;
    password: string;
    recaptchaToken?: string | undefined;
  }) => {
    mutate(
      { email, password, recaptchaToken },
      {
        onSuccess: () => {
          const redirectRoute = ROUTES.workspace;
          navigate(redirectRoute, { replace: true });
        },
      }
    );
  };

  return (
    <>
      <h2 className="mb-6 text-center text-2xl font-bold leading-9 tracking-tight xl:mb-10 xl:text-start xl:text-[44px]">
        Log in
      </h2>

      <LoginForm isPendingSubmit={isPending} submitError={error} onSubmitForm={handleSubmitForm} />
    </>
  );
};
