import { Icons } from "@/components/ui/icons";
import { ROUTES } from "@/constants/routes";
import { useNavigate } from "react-router";
import { IconButton } from "@/components/ui/icon-button";

export const RegisterSuccess = () => {
  const navigate = useNavigate();

  (window as Window)?.dataLayer?.push({
    event: "registration_completed",
  });

  return (
    <div className="flex w-72 flex-col items-start gap-10 xl:w-80">
      <div className="flex flex-row items-center gap-2">
        <IconButton icon={<Icons.Arrow />} size="medium" variant="tertiary" onClick={() => navigate(ROUTES.login)} />
        <h2 className="w-full text-start text-2xl font-bold leading-9 tracking-tight lg:text-[28px]">Email Sent</h2>
      </div>

      <Icons.Mails className="flex w-full justify-center" />
      <p className="text-start text-base font-bold">
        A verification email has been sent to you. Check spam folder if you do not see it.
      </p>
    </div>
  );
};
