import { SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP } from "../../constants/statsConstants";
import type { StatsSingleAgentPerformanceDataType } from "@/types/stats";

const StatsDataTypeIcon = ({ selectedDataType }: { selectedDataType: StatsSingleAgentPerformanceDataType }) => {
  return (
    <div
      className="h-[13px] w-[13px] rounded-full"
      style={{
        backgroundColor: SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP[selectedDataType].color,
      }}
    />
  );
};

export default StatsDataTypeIcon;
