import type { AgentNotification } from "@/types/notification";
import { NotificationAvatar } from "@/components/Notifications/UngroupedNotification/NotificationAvatar";
import { NotificationDescription } from "@/components/Notifications/UngroupedNotification/NotificationDescription";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useAcknowledgeNotifications } from "@/data/mutations/useAcknowledgeNotifications";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";

type NotificationItemProps = {
  notification: AgentNotification;
};

export const NotificationItem = ({ notification }: NotificationItemProps) => {
  const [showReadButton, setShowReadButton] = useState(false);
  const { mutate: acknowledgeNotifications, isPending } = useAcknowledgeNotifications();
  const { isMobile } = useMediaQueriesContext();

  const isReadButtonVisible = (isMobile || showReadButton) && !notification.read;

  const markAsRead = () => {
    acknowledgeNotifications({ ids: [notification._id] });
  };

  return (
    <div
      className="flex items-center justify-between gap-2 border-b border-gray-200 py-4"
      onMouseOver={() => !notification.read && setShowReadButton(true)}
      onMouseOut={() => !notification.read && setShowReadButton(false)}
    >
      <div className="flex items-center gap-2">
        <NotificationAvatar
          isRead={notification.read}
          imageSrc={notification?.imgSrc}
          isPersonNotification={!!notification?.entityId}
        />
        <NotificationDescription notification={notification} />
      </div>
      {isReadButtonVisible && (
        <Button loading={isPending} onClick={markAsRead}>
          Read
        </Button>
      )}
    </div>
  );
};
