import { ROUTES } from "@/constants/routes";
import { useNavigate } from "react-router";
import { Controller, get, type UseFormReturn } from "react-hook-form";
import { useRegisterUser } from "@/data/mutations/useRegisterUser";
import type { RegisterFormTypes, RegisterStep } from "@/types/user-register";
import { RegisterSuccess } from "@/components/RegisterForm/components/RegisterSuccess";
import { pickUserAvatar } from "@/utils/pickAvatar";
import { externalLogin } from "@/utils/externalLogin";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Datepicker from "@/components/Datepicker/Datepicker";
import { subYears } from "date-fns";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { IconButton } from "@/components/ui/icon-button";
import type { Dispatch, SetStateAction } from "react";

type RegisterAgeVerificationProps = {
  form: UseFormReturn<RegisterFormTypes>;
  isGoogleRegister: boolean;
  setRegisterStep: Dispatch<SetStateAction<RegisterStep>>;
};

export const RegisterAgeVerification = ({ form, isGoogleRegister, setRegisterStep }: RegisterAgeVerificationProps) => {
  const navigate = useNavigate();
  const { mutate, isPending, isSuccess, error } = useRegisterUser();
  const { errors } = form.formState;
  const { handleSubmit, reset } = form;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const submitRegister = async (formData: RegisterFormTypes) => {
    const { email, name, dateOfBirth } = formData;
    const avatar = pickUserAvatar();
    const recaptchaToken = executeRecaptcha ? await executeRecaptcha("register") : undefined;

    if (isGoogleRegister) {
      externalLogin("google");
      return;
    }

    const nowDate = new Date();
    const thirteenYearsAgo = subYears(nowDate, 13);
    const eighteenYearsAgo = subYears(nowDate, 18);

    if (dateOfBirth > thirteenYearsAgo) {
      return setRegisterStep("ageVerificationFailed");
    }

    if (dateOfBirth > eighteenYearsAgo) {
      return setRegisterStep("ageVerificationParentsApproval");
    }

    mutate(
      { email, name, birth: dateOfBirth, avatar, recaptchaToken },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  };
  const validationError = () => {
    return (
      <div className="h-[44px] w-72 text-center text-xs font-medium leading-5 text-red-600 xl:w-80">
        <div>{error?.response?.data.message || get(errors, "dateOfBirth.message")}</div>
      </div>
    );
  };

  if (isSuccess) {
    return <RegisterSuccess />;
  }

  return (
    <div className="flex w-full flex-col items-start gap-10">
      <div className="flex flex-row items-center gap-2">
        <IconButton icon={<Icons.Arrow />} size="medium" variant="tertiary" onClick={() => navigate(ROUTES.login)} />
        <h2 className="w-full text-start text-2xl font-bold leading-9 tracking-tight lg:text-[28px]">
          Age Verification
        </h2>
      </div>

      <p className="text-xs font-bold leading-[170%] text-primary-black">
        To access AgentX, you must be 18 or the age of majority in your jurisdiction. Users aged 13-18 require parent’s
        approval, while those under 13 are not granted access.
      </p>
      <form onSubmit={handleSubmit(submitRegister)}>
        <div className="flex w-72 justify-center xl:w-80 ">
          <Controller
            control={form.control}
            name="dateOfBirth"
            render={({ field: { value, onChange } }) => (
              <Datepicker
                selected={value}
                onChange={onChange}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTwoColumnMonthYearPicker
                scrollableMonthYearDropdown
                maxDate={subYears(new Date(), 0)}
                className="h-[38px] cursor-pointer rounded-lg border border-neutral-300 text-base text-neutral-700"
                dateFormat="MM/dd/yyyy"
                placeholderText="MM/DD/YYYY"
                calendarClassName="border-none shadow-xl"
              />
            )}
          />
        </div>
        {validationError()}
        <div className="flex w-72 flex-col gap-2 xl:w-80">
          <Button type="submit" size="medium" loading={isPending} className="w-full font-bold">
            Verify
          </Button>
          <Button
            variant="ghost"
            size="medium"
            loading={isPending}
            onClick={() => {
              navigate(ROUTES.login);
            }}
          >
            Go back
          </Button>
        </div>
      </form>
    </div>
  );
};
